<template>
  <div class="checkout">
    <div class="checkout_inner">
      <h2 v-if="!hidePrices" class="checkout_price">
        {{ formatAsCurrency(deltaPrice) }}
      </h2>
      <div class="checkout_descr" @click="onCheckout">
        <h5 class="checkout_descr_copy">{{ summaryButtonLabel }}</h5>
        <div class="checkout_descr_icon" v-html="icons.arrowRight"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatAsCurrency } from '../utils/index.js';

export default {
  name: 'Checkout',
  inject: ['store'],
  props: {
    selectedRoomSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      summaryButtonLabel: this.store.apiData.apartment.pages.summaryScreen.buttonLabel,
      icons: {
        arrowRight: require('!html-loader!@/assets/svgs/arrowRight.svg'),
      },
    };
  },
  computed: {
    deltaPrice() {
      return this.store.userSelectionData.totalPrice - this.store.userSelectionData.basePrice;
    },
    totalPrice() {
      return this.store.userSelectionData.totalPrice;
    },
    hidePrices() {
      return this.store.apiData.project.hidePrices;
    },
  },
  methods: {
    formatAsCurrency,
    onCheckout() {
      this.$router.push({
        name: 'Summary',
        params: { roomSlug: this.selectedRoomSlug },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout {
  position: absolute;
  top: 0;
  right: 20px;
  width: 20%;
  min-width: 400px;
  &_inner {
    height: 80px;
    background: var(--Asphalt);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
  }
  &_price {
    margin: 0;
  }
  &_descr {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex: 0 0 auto;
    cursor: pointer;
    &_copy {
      color: var(--BPDgray2);
      margin: 0;
    }
    &_icon {
      width: 14px;
      margin-left: 10px;
      color: var(--BPDgray2);
    }
  }
}

@include tabletAndPhone {
  .checkout {
    z-index: 4;
    top: 70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    min-width: auto;
    &_inner {
      height: 38px;
      padding: 5px 19px 5px 20px;
      border-radius: 20px;
    }
    &_price {
      margin-right: 10px;
    }
  }
}
</style>
