<template>
  <div v-if="products && products.length > 0" class="slotCheckbox">
    <div class="border dottedBorder"></div>

    <div v-for="(product, index) in products" :key="index" class="slotCheckbox_inner">
      <div class="icons">
        <div class="icon" v-html="icons.checkBox_static"></div>
      </div>

      <div class="descr">
        <p>{{ product.title }}</p>
        <p>{{ product.subtitle }}</p>
      </div>

      <p class="price">
        <span v-if="product.noPriceCalc">—</span>
        <span v-else>{{ formatAsCurrency(product.unitPrice) }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { formatAsCurrency } from '@/utils';
import { store /*, getters*/ } from '@/store/index.js';

export default {
  name: 'SlotCheckboxList',
  props: {
    roomSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      products: null,
      icons: {
        checkBox_static: require('!html-loader!@/assets/svgs/checkBox_static.svg'),
      },
    };
  },
  mounted() {
    // const selection = getters.getSelection(this.roomSlug, this.slotSlug);
    // this.products = this.getProducts().filter(_product => {
    //   return selection.productIds.includes(_product.id);
    // });
    // if (this.products.length < 1) this.$emit('on-hide', this.roomSlug, this.slotSlug);
  },
  methods: {
    formatAsCurrency,

    getProducts() {
      const products = [];
      this.slotItem.productIds.forEach(_productId => {
        products.push(store.apiData.products.find(_product => _product.id == _productId));
      });

      return products;
    },
  },
};
</script>

<style lang="scss" scoped>
.slotCheckbox {
  width: 100%;

  &_inner {
    width: 100%;
    display: flex;
    align-items: center;

    .icons {
      width: 70px;
      height: 70px;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      width: 20px;
      height: 20px;
      float: left;
      margin-top: -6px;
    }

    .descr {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 20px 0 20px;
      flex: 1 1 auto;
      p {
        margin: 0;
        font-weight: 600;
        font-size: 0.875em;
        color: var(--Asphalt);
        &:last-child {
          font-weight: normal;
        }
      }
    }
    .price {
      display: flex;
      flex-direction: column;
      text-align: right;
      flex: 1 0 auto;
      @include NunitoBlack;
      font-size: 1em;
      margin: 0;
      color: var(--BPDgray2);
    }
  }
}

.border {
  margin: 10px auto 10px auto;
}
</style>
