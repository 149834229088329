<template>
  <div class="slotSwitch">
    <p class="debugStyle">{{ slotItem.title }}</p>

    <div v-for="product in getProducts()" :key="product.title">
      <p class="debugStyle">{{ product.slotSlug }}</p>

      <SlotProductList
        v-if="!product.productSlotSlugs"
        :room-slug="room.slug"
        :slot-page-slug="slotPageSlug"
        :slot-slug="product.slotSlug"
        slot-style="default"
        :slot-item="product"
      />

      <div
        v-else-if="product.productSlotSlugs && product.productSlotSlugs.length"
        style="color:blue; font-size:1em;"
      >
        <div
          v-for="productBySlug in getProductsBySlug(product.productSlotSlugs)"
          :key="productBySlug.title"
        >
          <!-- <p style="color:red;">{{ slotItem.children[0].children[2].title }}</p> -->

          <SlotProductList
            :room-slug="room.slug"
            :slot-page-slug="slotPageSlug"
            :slot-slug="productBySlug.slotSlug"
            slot-style="default"
            :slot-item="productBySlug"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { formatAsCurrency, formatAsMeasurementTerm } from '../utils/index.js';
import SlotProductList from '@/components/SlotProductList_comp.vue';
import { store, getters } from '@/store/index.js';

export default {
  name: 'SlotSwitchList',
  components: {
    SlotProductList,
  },
  props: {
    roomSlug: {
      type: String,
      required: true,
    },
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    room() {
      return getters.getRoomBySlug(this.roomSlug);
    },
  },

  methods: {
    formatAsCurrency,
    formatAsMeasurementTerm,

    getProductsBySlug(_productSlugs) {
      const products = [];

      for (let i = 0; i < _productSlugs.length; i++) {
        const productSlug = _productSlugs[i];

        for (let k = 0; k < store.userSelectionData.products[this.roomSlug].length; k++) {
          const product = store.userSelectionData.products[this.roomSlug][k];
          if (product.slotSlug == productSlug) {
            products.push(product);
          }
        }
      }

      return products;
    },

    getProduct(_productId) {
      return store.apiData.products.find(_product => _product.id == _productId);
    },

    getProducts() {
      // Copied from ProductList.getSlotPagePrice() (carlcs 21-12-17)
      const roomProducts = getters.getUserSelectionProductsByRoom(this.roomSlug);
      const unselectedSlotSlugs = roomProducts
        .filter(_roomProduct => {
          return _roomProduct.unselectedSlotSlugs && _roomProduct.unselectedSlotSlugs.length > 0;
        })
        .map(_roomProduct => _roomProduct.unselectedSlotSlugs)
        .flat();
      const slotPageProducts = roomProducts.filter(_roomProduct => {
        return (
          _roomProduct.slotPageSlug === this.slotPageSlug &&
          !unselectedSlotSlugs.includes(_roomProduct.slotSlug)
        );
      });

      return slotPageProducts;
    },
  },
};
</script>

<style lang="scss" scoped>
.slotSwitch {
  width: 100%;
}

.debugStyle {
  font-size: 0.875em;
  font-weight: bold;
  color: red;
  display: none;
}
</style>
