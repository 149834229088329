<template>
  <section class="switch">
    <div class="borderPadding">
      <h4 class="switch_title">
        {{ slotGroups.title }}
      </h4>
      <p class="switch_instructions">
        {{ slotGroups.instructions }}
      </p>

      <ul
        v-for="(slotGroup, index) in slotGroups.children"
        :key="index"
        :class="[
          'switch_list',
          { selected: index == selectedSlotGroupIndex },
          { displayNone: hideNotSelectedGroup(slotGroup) },
        ]"
        @click="onSlotGroupSelected(slotGroup, index)"
      >
        <div class="switch_list_item">
          <div class="radioBtn">
            <div class="radioBtn_inner"></div>
          </div>
          <p>{{ slotGroup.title }}</p>
        </div>
        <img
          v-if="slotGroup.image"
          onload="this.style.opacity=1"
          class="switch_list_item"
          :src="slotGroup.image.url"
        />
      </ul>

      <div v-for="(slotGroup, index) in slotGroups.children" :key="slotGroup.title">
        <Hint v-if="index == selectedSlotGroupIndex && slotGroup.tip" :content="slotGroup.tip" />
      </div>

      <div class="dottedBorder"></div>
    </div>
  </section>
</template>

<script>
import { getters, mutations } from '@/store/index.js';
import {
  isConflictingRoomFlooring,
  getConflictedRoomData,
  getConflictingSelection,
} from '../service/conflictChecker/index.js';

import Hint from '@/components/Hint_comp.vue';

export default {
  name: 'SwitchComp',
  inject: ['store'],
  components: {
    Hint,
  },
  props: {
    slotGroups: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSlotGroup: null,
      selectedSlotGroupIndex: 0,
      activeSlotGroupSlugsByWohnraum: [],
      isFlurAbstellraumRoute: this.$route.params.roomSlug === '228-wohnungsflur-abstellraum',
      startSelection: getters.getSelection(this.$route.params.roomSlug, this.slotGroups.slug),
    };
  },

  mounted() {
    if (this.startSelection) {
      this.slotGroups.children.forEach((_slotGroup, _index) => {
        if (_slotGroup.slug == this.startSelection.slotGroupSlug) {
          this.selectedSlotGroupIndex = _index;
          this.selectedSlotGroup = _slotGroup;
        }
      });
    } else {
      console.log(
        '[Switch_comp] selection is empty. Add it to store: ',
        this.$route.params.roomSlug,
        this.slotGroups.slug
      );
    }

    this.$emit(
      'on-slotGroup-selected',
      this.startSelection,
      this.selectedSlotGroup,
      this.slotGroups.children[this.selectedSlotGroupIndex].slug
    );
  },

  methods: {
    hideNotSelectedGroup(_slotGroup) {
      if (!this.isFlurAbstellraumRoute) return;
      //Flur
      if (
        (this.startSelection.slotGroupSlug === '408-ebenfalls-parkett-228' ||
          this.startSelection.slotGroupSlug === '411-fliesen-228') &&
        _slotGroup.slug === '9272-ebenfalls-fliesen-228'
      ) {
        //hide  9272-ebenfalls-fliesen
        return true;
      } else if (
        this.startSelection.slotGroupSlug === '9272-ebenfalls-fliesen-228' &&
        (_slotGroup.slug === '408-ebenfalls-parkett-228' || _slotGroup.slug === '411-fliesen-228')
      ) {
        //hide 408-ebenfalls-parkett and 411-fliesen
        return true;
      }

      //Abstellraum
      else if (
        (this.startSelection.slotGroupSlug === '423-ebenfalls-parkett-228' ||
          this.startSelection.slotGroupSlug === '425-fliesen-228') &&
        _slotGroup.slug === '9301-ebenfalls-fliesen-228'
      ) {
        //hide 9301-ebenfalls-fliesen
        return true;
      }
      //Abstellraum
      else if (
        this.startSelection.slotGroupSlug == '9301-ebenfalls-fliesen-228' &&
        (_slotGroup.slug === '423-ebenfalls-parkett-228' || _slotGroup.slug === '425-fliesen-228')
      ) {
        //hide 423-ebenfalls-parkett and 425-fliesen
        return true;
      }
    },

    onSlotGroupSelected(_slotGroup, _index) {
      if (isConflictingRoomFlooring(this.$route.params.roomSlug, _slotGroup.slug)) {
        const conflictedRoomData = getConflictedRoomData(this.$route.params.roomSlug);
        this.$emit('on-conflicting-room-flooring', conflictedRoomData, () => {
          this.applyConflictingRoomChanges(_slotGroup, _index, conflictedRoomData);
        });
      } else {
        this.addSelectionToStore(_slotGroup, _index, null);
      }
    },

    addSelectionToStore(_slotGroup, _index) {
      this.selectedSlotGroup = _slotGroup;
      this.selectedSlotGroupIndex = _index;
      const selection = {
        referencedProductGroup: this.selectedSlotGroup.referencedProductGroup
          ? this.selectedSlotGroup.referencedProductGroup
          : null,
        attributes: this.selectedSlotGroup.attributes ? this.selectedSlotGroup.attributes : {},
        slotSlug: this.slotGroups.slug,
        slotGroupSlug: this.slotGroups.children[_index].slug,
      };

      this.$emit(
        'on-slotGroup-selected',
        selection,
        _slotGroup,
        this.slotGroups.children[_index].slug
      );
    },

    applyConflictingRoomChanges(_slotGroup, _index, _conflictedRoomData) {
      const conflictingSelections = getConflictingSelection(_slotGroup.slug);
      conflictingSelections.forEach(_selection => {
        mutations.addSelection(_conflictedRoomData.roomSlug, _selection.slotSlug, _selection);
      });
      this.addSelectionToStore(_slotGroup, _index);
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  &_title {
    margin: 0;
  }
  &_instructions {
    color: var(--BPDgray1);
    margin: 8px auto 16px auto;
  }
  &_list {
    display: flex;
    transition: all 0.25s;
    cursor: pointer;

    &_item {
      background-color: var(--White);
      padding: 10px;
      height: 100px;

      .radioBtn {
        width: 20px;
        height: 20px;
        padding: 7px;
        background-color: var(--BPDgray3);
        border-radius: 50%;
        transition: background-color 0.25s;
        &_inner {
          width: 6px;
          height: 6px;
          background-color: var(--BPDgreen1);
          border-radius: 50%;
          opacity: 0;
          transition: opacity 0.25s;
        }
      }
      p {
        text-transform: uppercase;
        margin: 10px 0 0 0;
        font-size: 0.875em;
        font-weight: 600;
        line-height: 1.5;
        color: var(--Asphalt);
        border-radius: 5px 0 0 5px;
      }

      &:first-child {
        flex-basis: 40%;
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        flex-basis: 60%;
        border-radius: 0 5px 5px 0;
        padding: 0;
      }
    }
    img {
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.25s;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    @include ishoverSupported {
      &:hover {
        box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
        .radioBtn_inner {
          opacity: 1;
        }
      }
    }

    &:nth-child(n-2) {
      margin-top: 10px;
    }
  }
}

.selected {
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  .radioBtn {
    background-color: var(--BPDgreen2);
    &_inner {
      opacity: 1;
    }
  }
}
.displayNone {
  display: none;
}
</style>
