<template>
  <div class="configurator">
    <div class="wrapper">
      <h3 class="configurator_headline">{{ room.title }}</h3>
      <p class="configurator_instructions">{{ room.instructions }}</p>
      <ul class="configurator_list">
        <li
          v-for="child in room.children"
          :key="child.title"
          :class="['configurator_list_item', { 'mod-selected': isSlugSelected(child.slug) }]"
          @click="onListItem(child)"
        >
          <img
            v-if="child.icon && child.icon.url"
            :src="child.icon.url"
            class="configurator_list_item_icon"
          />
          <h5 class="configurator_list_item_copy">{{ child.title }}</h5>
        </li>
        <li class="configurator_list_navItem">
          <RoomMenuBtn class="mobile_roomNav" :selected-room-slug="selectedRoomSlug" />
        </li>
      </ul>

      <RoomMenuBtn class="desktop_roomNav" :selected-room-slug="selectedRoomSlug" />
    </div>

    <transition name="fade" mode="out-in">
      <router-view
        ref="routerView"
        @on-conflicting-products="onConflictingProducts"
        @on-conflicting-room-flooring="onConflictingRoomFlooring"
        @on-pull-up="showMobileConfiFully"
        @on-pull-down="hideMobileConfi"
        @on-reset-scroll-bar="scrollToStart"
        @on-show-dekstopConfiStyle="showDekstopConfiStyle"
      />
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap';
import { getters } from '@/store/index.js';
import { detectMobileViewPort } from '../utils/index.js';
import RoomMenuBtn from '@/components/RoomMenuBtn_comp.vue';

export default {
  name: 'RoomConfigurator',
  components: {
    RoomMenuBtn,
  },
  props: {
    selectedRoomSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInMobileViewPort: false,
      icons: {
        arrowLeft: require('!html-loader!@/assets/svgs/arrowLeft.svg'),
      },
    };
  },
  computed: {
    room() {
      return getters.getRoomBySlug(this.selectedRoomSlug);
    },
  },
  updated() {
    if (this.isInMobileViewPort) {
      this.setSizeOfMobileConfiguratorList();
    }
  },
  mounted() {
    this.isInMobileViewPort = detectMobileViewPort(window.innerWidth);
    if (this.isInMobileViewPort) {
      this.setSizeOfMobileConfiguratorList();
    }
  },
  methods: {
    isSlugSelected(_slug) {
      if (!this.isInMobileViewPort) return false;
      return this.$route.params.slotPageSlug === _slug;
    },

    onWindowResize(_windowInnerWidth) {
      this.isInMobileViewPort = detectMobileViewPort(_windowInnerWidth);
      if (this.isInMobileViewPort) {
        this.hideMobileConfi();
        this.$router.push({
          name: 'Category',
          params: { slotPageSlug: this.room.children[0].slug },
        });
      } else {
        this.showDekstopConfiStyle();
      }
      if (!this.$refs.routerView) return;
      this.$refs.routerView.onWindowResize(_windowInnerWidth);
    },

    getWidthAndMarginLeft(_ele) {
      const compStyles = window.getComputedStyle(_ele);
      const _eleWidth = compStyles.getPropertyValue('width');
      const _eleMarginLeft = compStyles.getPropertyValue('margin-left');
      return parseFloat(_eleWidth) + parseFloat(_eleMarginLeft);
    },

    setSizeOfMobileConfiguratorList() {
      const listEle = document.querySelector('.configurator_list');
      //First make it huge so all items grow to fit their content and we got the right basis item size
      listEle.style.width = '100000px';
      const listItems = document.querySelectorAll('.configurator_list_item');
      const listItemNav = document.querySelector('.configurator_list_navItem');
      let scrollWidth = 0;

      listItems.forEach(_listItem => {
        scrollWidth += this.getWidthAndMarginLeft(_listItem);
      });

      scrollWidth += this.getWidthAndMarginLeft(listItemNav);

      if (window.innerWidth < scrollWidth) {
        scrollWidth -= window.innerWidth - 120;
      } else {
        scrollWidth = 0;
      }
      listEle.style.width = `calc(100% + ${scrollWidth}px`;
    },

    scrollToStart() {
      document.querySelector('.wrapper').scrollLeft = 0;
    },

    resetSelectedItems() {
      document.querySelectorAll('.configurator_list_item').forEach(_item => {
        _item.classList.remove('mod-selected');
      });
    },

    async showMobileConfiFully() {
      await gsap.to('.configurator', { top: '130px', duration: 0.5, ease: 'sine.out' });
    },

    hideMobileConfi() {
      gsap.to('.configurator', {
        top: `${window.innerHeight - 250}px`,
        delay: 0.05, //needed
        duration: 0.5,
        ease: 'sine.out',
      });
    },

    showDekstopConfiStyle() {
      gsap.to('.configurator', { top: '80px', duration: 0.5 });
    },

    onListItem(_item) {
      this.$router.push({
        name: 'Category',
        params: { slotPageSlug: _item.slug },
      });
    },

    onConflictingProducts(_conflictingProducts, _addSelectedProductCB, _addProductCB) {
      this.$emit(
        'on-conflicting-products',
        _conflictingProducts,
        _addSelectedProductCB,
        _addProductCB
      );
    },

    onConflictingRoomFlooring(_conflictingRoomData, _confirmCB) {
      this.$emit('on-conflicting-room-flooring', _conflictingRoomData, _confirmCB);
    },
  },
};
</script>

<style lang="scss" scoped>
.configurator {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 20%;
  min-width: 400px;
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0 0 5px;
  // @include hideScrollBar();

  .desktop_roomNav {
    display: flex;
  }

  .mobile_roomNav {
    display: none;
    width: auto;
  }

  .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 0 5px;
    padding: 0 30px 30px 30px;
    background-image: linear-gradient(
      to bottom,
      #ffffff,
      rgba(255, 255, 255, 0.6) 70%,
      rgba(255, 255, 255, 0.1)
    );
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 5px 5px;
    height: 100%;
    // @include hideScrollBar();
  }

  &_headline,
  &_instructions {
    margin-bottom: 0;
  }

  &_list {
    margin: 20px 0 40px 0;
    width: 100%;
    &_item {
      height: 64px;
      background: var(--White);
      padding: 14px 10px 14px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border: 2px solid transparent;
      transition: all 0.25s;
      box-shadow: 0 2px 20px 0 rgba(84, 84, 84, 0.25);
      cursor: pointer;
      &_icon {
        width: 24px;
        color: var(--BPDgreen1);
        margin-right: 10px;
        object-fit: none;
      }
      &_copy {
        margin: 3px 0 0 0;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      @include ishoverSupported {
        &:hover {
          box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
          transform: translateX(10px);
        }
      }
    }

    &_navItem {
      margin-left: 15px;
    }
  }
}

.mod-selected {
  border: 2px solid var(--BPDgray3);
  pointer-events: none;
}

@include tabletAndPhone() {
  .configurator {
    width: 100%;
    height: auto;
    left: 0;
    top: calc(100% - 250px); //closed
    bottom: 0;
    min-width: unset;
    overflow: hidden;

    .desktop_roomNav {
      display: none;
    }

    .mobile_roomNav {
      display: flex;
    }

    &_headline,
    &_instructions {
      display: none;
    }

    &_list {
      //background: red;
      display: flex;
      margin: 0;
      width: 1300px; //calc in js by totallength of items plus margin plus fuck u
      &_item {
        display: inline-flex;
        margin: 0 0 0 15px;
        height: 48px;
      }
    }

    .wrapper {
      width: 100%;
      height: auto;
      background: none;
      border-radius: unset;
      padding: unset;
      overflow-x: auto;
      overflow-y: hidden;
      position: fixed;
      z-index: 4;
    }
  }
}
</style>
