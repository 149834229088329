<template>
  <ul class="projectInfo">
    <li v-if="includeFloorplan" class="projectInfo_floorplan">
      <div class="projectInfo_floorplan_icon" @click="close" v-html="icons.cross"></div>

      <div
        v-if="apartmentProperty.floorplans.length"
        class="projectInfo_floorplan_img"
        :style="{ backgroundImage: `url(${apartmentProperty.floorplans[0].url})` }"
      ></div>
    </li>
    <li class="projectInfo_general">
      <div class="projectInfo_general_title">
        <h6>{{ project.title }}</h6>
        <h6>{{ project.subtitle }}</h6>
      </div>
      <div class="seperator"></div>
      <div class="projectInfo_general_descr">
        <h6>{{ apartmentProperty.title }}</h6>
        <h6>{{ apartmentProperty.subtitle }}</h6>
      </div>
    </li>
    <li class="seperator"></li>

    <li class="projectInfo_apartment">
      <div class="projectInfo_apartment_entry">
        <div class="icon" v-html="icons.room"></div>
        <div>
          <h6>{{ apartmentProperty.floorArea }}m²</h6>
          <p>Wohnfläche</p>
        </div>
      </div>

      <div class="projectInfo_apartment_entry">
        <div class="icon" v-html="icons.level"></div>
        <div>
          <h6>{{ apartmentProperty.level }}</h6>
          <p>Stockwerk</p>
        </div>
      </div>

      <div class="projectInfo_apartment_entry">
        <div class="icon" v-html="icons.bed"></div>
        <div>
          <h6>{{ apartmentProperty.roomCount.bedroom }}</h6>
          <p>Schlafzimmer</p>
        </div>
      </div>

      <div class="projectInfo_apartment_entry">
        <div class="icon" v-html="icons.towel"></div>
        <div>
          <h6>{{ apartmentProperty.roomCount.bathroom }}</h6>
          <p>Badezimmer</p>
        </div>
      </div>
    </li>
    <li v-if="includeFloorplan" class="projectInfo_triangle"></li>
  </ul>
</template>

<script>
import { mutations } from '@/store';

export default {
  name: 'ProjectInfo',
  inject: ['store'],
  props: {
    includeFloorplan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apartmentProperty: this.store.apiData.apartment.property,
      project: this.store.apiData.project,
      icons: {
        bed: require('!html-loader!@/assets/svgs/bed.svg'),
        level: require('!html-loader!@/assets/svgs/level.svg'),
        room: require('!html-loader!@/assets/svgs/room.svg'),
        towel: require('!html-loader!@/assets/svgs/towel.svg'),
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
      },
    };
  },
  methods: {
    close() {
      mutations.setProjectInfoIsActive(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.projectInfo {
  position: relative;
  top: 0;
  right: 0;
  width: 600px;
  background: var(--White);
  pointer-events: auto;
  &_floorplan {
    &_img {
      width: 100%;
      height: 290px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      box-shadow: inset 0px -11px 11px -11px rgba(0, 0, 0, 0.1);
    }
    &_icon {
      width: 20px;
      position: relative;
      top: 15px;
      right: 15px;
      color: var(--BPDgray2);
      transition: color 0.25s;
      pointer-events: auto;
      margin-left: auto;

      cursor: pointer;

      @include ishoverSupported {
        &:hover {
          color: var(--Charcoal);
        }
      }
    }
  }
  &_general {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0 30px;
    &_title {
      h6 {
        color: var(--BPDgray2);
        margin: 0;
      }
    }
    .seperator {
      display: none;
      width: 100% !important;
    }
    &_descr {
      text-align: right;
      h6 {
        color: var(--BPDgray2);
        margin: 0;
      }
    }
  }
  .seperator {
    width: calc(100% - 60px);
    border: none;
    border-top: 1px dotted var(--BPDgray3);
    height: 0;
    margin: 12px auto 16px auto;
  }
  &_apartment {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 20px 30px;
    &_entry {
      display: flex;
      div {
        h6 {
          font-size: 1.125em;
          margin: 0;
          color: var(--BPDgray2);
        }
        p {
          margin: 0;
          font-size: 0.875em;
          color: var(--BPDgray2);
        }
      }
      .icon {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  &_triangle {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid var(--White);
    position: absolute;
    bottom: -12px;
    left: 8px;
  }
}

@include phone() {
  .projectInfo {
    &_general {
      flex-direction: column;
      align-items: baseline;
      .seperator {
        display: block;
      }
      &_descr {
        text-align: left;
      }
    }
    &_apartment {
      flex-wrap: wrap;
      &_entry {
        flex: 1 0 50%;
        &:nth-child(2n + 2) {
          //background: red;
          justify-content: flex-end;
          //yo yo for now
          p {
            min-width: 80px;
          }
        }
      }
    }
  }
}

@include tabletAndPhone {
  .projectInfo_triangle {
    display: none;
  }
}
</style>
