<template>
  <ul class="packages">
    <div class="borderPadding">
      <li v-for="item in packages" :key="item.title" class="packages_item">
        <h4 class="packages_item_title">{{ item.title }}</h4>
        <p class="packages_item_subtitle">{{ item.subtitle }}</p>
        <div class="packages_item_frame landscape">
          <div
            class="packages_item_frame_image"
            :style="{ backgroundImage: 'url(' + item.image.url + ')' }"
          />
        </div>

        <ul class="featuresList">
          <li v-for="feature in item.featuresList" :key="feature.title" class="featuresList_item">
            <h5 class="featuresList_item_title">{{ feature.title }}</h5>
            <p class="featuresList_item_descr">{{ feature.description }}</p>
          </li>
        </ul>
        <div class="dottedBorder"></div>
        <Checkbox
          :slot-page-slug="slotPageSlug"
          :slot-item="slotItem"
          :slot-index="slotIndex"
          :slot-slug="slotSlug"
          :products="[item]"
        />
      </li>
    </div>
  </ul>
</template>

<script>
import Checkbox from '@/components/Checkbox_comp.vue';

export default {
  name: 'PackagesComp',
  components: {
    Checkbox,
  },

  props: {
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    slotIndex: {
      type: Number,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
    packages: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.packages {
  .borderPadding {
    padding: 30px 15px 0px 15px;
  }
  &_item {
    padding: 20px 10px 20px 10px;
    margin-bottom: 20px;
    background-color: var(--White);
    &_title {
      margin: 0;
      color: var(--Asphalt);
    }
    &_subtitle {
      color: var(--BPDgray1);
      margin: 0 auto 20px auto;
      font-weight: normal;
      font-style: normal;
      letter-spacing: normal;
    }

    &_frame {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
      margin-bottom: 30px;
      &.landscape {
        padding-top: 56.25%;
      }

      &_image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--White);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }

    .featuresList {
      &_item {
        margin-bottom: 10px;
        &_title {
          font-size: 0.875em;
          color: var(--Asphalt);
          margin: 0;
        }
        &_descr {
          font-size: 0.875em;
          color: var(--Asphalt);
          font-weight: normal;
          margin: 0;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
