<template>
  <section class="productsSwitch">
    <SwitchComp
      :slot-groups="slotGroups"
      @on-slotGroup-selected="onSlotGroupSelected_a"
      @on-conflicting-room-flooring="onConflictingRoomFlooring"
    />

    <div v-if="switchIsMounted_a">
      <div v-for="(slot_a, index_a) in selectedSlotGroup_a.children" :key="slot_a.id">
        <Products
          v-if="slot_a.style === 'default'"
          :slot-page="selectedSlotGroup_a"
          :slot-page-slug="slotPageSlug"
          :slot-item="slot_a"
          :slot-index="index_a"
          :slot-slug="slot_a.slug"
          :products="getSlotProducts(slot_a)"
          :key="componentKey"
          @on-conflicting-products="onConflictingProducts"
        />

        <ProductsVariable
          v-if="slot_a.style === 'variable'"
          :slot-page="selectedSlotGroup_a"
          :slot-page-slug="slotPageSlug"
          :slot-item="slot_a"
          :slot-index="index_a"
          :slot-slug="slot_a.slug"
          :products="getSlotProducts(slot_a)"
          :key="componentKey"
          @on-conflicting-products="onConflictingProducts"
        />

        <div v-if="!slot_a.style">
          <SwitchComp
            :slot-groups="slot_a"
            @on-slotGroup-selected="onSlotGroupSelected_b"
            @on-conflicting-room-flooring="onConflictingRoomFlooring"
          />

          <div v-if="switchIsMounted_b">
            <div v-for="(slot_b, index_b) in selectedSlotGroup_b.children" :key="slot_b.id">
              <Products
                v-if="slot_b.style === 'default'"
                :slot-page="selectedSlotGroup_b"
                :slot-page-slug="slotPageSlug"
                :slot-item="slot_b"
                :slot-index="index_b"
                :slot-slug="slot_b.slug"
                :products="getSlotProducts(slot_b)"
                :key="componentKey"
                @on-conflicting-products="onConflictingProducts"
              />

              <ProductsVariable
                v-if="slot_b.style === 'variable'"
                :slot-page="selectedSlotGroup_b"
                :slot-page-slug="slotPageSlug"
                :slot-item="slot_b"
                :slot-index="index_b"
                :slot-slug="slot_b.slug"
                :products="getSlotProducts(slot_b)"
                :key="componentKey"
                @on-conflicting-products="onConflictingProducts"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Products from '@/components/Products_comp.vue';
import ProductsVariable from '@/components/ProductsVariable_comp.vue';
import SwitchComp from '@/components/Switch_comp.vue';
import { store, mutations, getters } from '@/store/index.js';

export default {
  name: 'ProductsSwitchComp',
  components: {
    Products,
    ProductsVariable,
    SwitchComp,
  },
  props: {
    slotPage: {
      type: Object,
      default() {
        return { children: [] };
      },
    },
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotGroups: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      switchIsMounted_a: false,
      selectedSlotGroup_a: null,
      switchIsMounted_b: false,
      selectedSlotGroup_b: null,
      componentKey: 0,
    };
  },
  methods: {
    getSlotProducts(_slot) {
      const products = [];

      _slot.productIds.forEach(_id => {
        const product = store.apiData.products.find(_product => _product.id == _id); // change to === as soon as backend sends all strings
        if (product !== undefined) products.push(product);
      });

      return products;
    },

    addToStore(_selection, _slotGroup, _slotGroupSlug) {
      const room = getters.getRoomBySlug(this.$route.params.roomSlug);

      const roomSlotPage = room.children.find(
        _roomSlotPage => _roomSlotPage.slug === this.slotPageSlug
      );

      const slotGroups = roomSlotPage.children.find(
        _slotGroup => _slotGroup.slug === _slotGroup.slug
      );

      // required by RenderView
      // extend function to find selected + unselected products
      const arrayOfSlugsToAdd = [];
      const arrayOfUnselectedSlugs = [];
      slotGroups.children.forEach(slotGroup => {
        if (slotGroup.slug == _slotGroupSlug) {
          slotGroup.children.forEach(_slot => {
            arrayOfSlugsToAdd.push(_slot.slug);
          });
        } else {
          slotGroup.children.forEach(_slot => {
            arrayOfUnselectedSlugs.push(_slot.slug);
            // this'll only catch one additional switch level
            if (_slot.type === 'slotGroups' && _slot.children !== undefined) {
              _slot.children.forEach(subSlotGroup => {
                if (subSlotGroup.type == 'slotGroup' && subSlotGroup.children !== undefined) {
                  subSlotGroup.children.forEach(subSlot => {
                    arrayOfUnselectedSlugs.push(subSlot.slug);
                  });
                }
              });
            }
          });
        }
      });
      _selection.productSlotSlugs = arrayOfSlugsToAdd;
      _selection.unselectedSlotSlugs = arrayOfUnselectedSlugs;

      console.log(
        'switch',
        _selection.slug,
        _slotGroupSlug,
        arrayOfSlugsToAdd,
        arrayOfUnselectedSlugs
      );

      mutations.addSelection(this.$route.params.roomSlug, this.slotGroups.slug, _selection);

      this.forceUpdate();
    },

    onSlotGroupSelected_a(_selection, _slotGroup, _slotGroupSlug) {
      this.addToStore(_selection, _slotGroup, _slotGroupSlug);

      this.switchIsMounted_a = true;
      this.selectedSlotGroup_a = _slotGroup;
    },

    onSlotGroupSelected_b(_selection, _slotGroup, _slotGroupSlug) {
      const room = getters.getRoomBySlug(this.$route.params.roomSlug);

      const roomSlotPage = room.children.find(
        _roomSlotPage => _roomSlotPage.slug === this.slotPageSlug
      );

      const slotGroups = roomSlotPage.children.find(
        _slotGroup => _slotGroup.slug === _slotGroup.slug
      );

      const slotGroupsChildren = slotGroups.children.map(_slotGroupChild => {
        return _slotGroupChild;
      });

      const slotGroup = slotGroupsChildren.filter(_element => {
        return _element.children.find(_elementChild => _elementChild.slug === _selection.slotSlug);
      });

      const switchGroup = slotGroup[0].children.find(
        _element => _element.slug === _selection.slotSlug
      );

      // required by RenderView
      // extend function to find selected + unselected products
      const arrayOfSlugsToAdd = [];
      const arrayOfUnselectedSlugs = [];
      switchGroup.children.forEach(slotGroup => {
        if (slotGroup.slug == _slotGroupSlug) {
          slotGroup.children.forEach(_slot => {
            arrayOfSlugsToAdd.push(_slot.slug);
          });
        } else {
          slotGroup.children.forEach(_slot => {
            arrayOfUnselectedSlugs.push(_slot.slug);
            // this'll only catch one additional switch level
            if (_slot.type === 'slotGroups' && _slot.children !== undefined) {
              _slot.children.forEach(subSlotGroup => {
                if (subSlotGroup.type == 'slotGroup' && subSlotGroup.children !== undefined) {
                  subSlotGroup.children.forEach(subSlot => {
                    arrayOfUnselectedSlugs.push(subSlot.slug);
                  });
                }
              });
            }
          });
        }
      });
      _selection.productSlotSlugs = arrayOfSlugsToAdd;
      _selection.unselectedSlotSlugs = arrayOfUnselectedSlugs;

      mutations.addSelection(this.$route.params.roomSlug, _selection.slotSlug, _selection);

      this.switchIsMounted_b = true;
      this.selectedSlotGroup_b = _slotGroup;
    },

    onConflictingRoomFlooring(_conflictingRoomData, _confirmCB) {
      this.$emit('on-conflicting-room-flooring', _conflictingRoomData, _confirmCB);
    },

    onConflictingProducts(_products, _addSelectedProductCB, _addProductCB) {
      this.$emit('on-conflicting-products', _products, _addSelectedProductCB, _addProductCB);
    },
    forceUpdate() {
      this.componentKey++;
    }
  },
};
</script>
