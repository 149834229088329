<template>
  <li>
    <label :class="[node.type, unselected.includes(node.slug) ? 'unselected' : '']">
      {{ node.slug }}
      <span v-if="node.unitOfMeasure == 'sqm' && node.quantity !== undefined" class="quantity"
        >[Q:{{ node.quantity.toFixed(2) }} m²]
      </span>
      <span v-else-if="node.quantity !== undefined" class="quantity">[Q:{{ node.quantity }}] </span>
      <span class="selection">{{ selection(node.slug) }}</span>
      <span v-if="node.referencedProductGroup" class="reference">{{
        node.referencedProductGroup
      }}</span>
      <span v-if="node.referencedSlot" class="reference">{{ node.referencedSlot }}</span>
      <span v-if="node.attributes" class="attributes">
        <span v-if="node.attributes.tilingArea !== undefined"
          >[A:{{ node.attributes.tilingArea }}]
        </span>
      </span>
    </label>
    <ul v-if="node.children !== undefined" class="debug_items">
      <debug-tree
        v-for="child in node.children"
        :key="child.slug"
        :node="child"
        :room-slug="roomSlug"
        :unselected="unselected.concat(getUnselected(node.slug))"
      ></debug-tree>
    </ul>
  </li>
</template>
<script>
export default {
  name: 'DebugTree',
  inject: ['store'],
  props: {
    node: {
      type: Object,
      required: true,
    },
    roomSlug: {
      type: String,
      required: true,
    },
    unselected: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    'store.userSelectionData.changed'() {
      this.$forceUpdate();
    },
  },
  methods: {
    selection(slug) {
      const room = this.store.userSelectionData.products[this.roomSlug];
      // console.log(this.roomSlug, slug, room);
      const selection = room.find(_selection => _selection.slotSlug === slug);

      if (selection !== undefined) {
        var output = '';

        if (selection.productIds !== undefined && selection.productIds !== null) {
          if (selection.quantity !== undefined) {
            output += '[Q:' + selection.quantity.toFixed(2) + '] ';
          }
          if (selection.area !== undefined) {
            output += '[A:' + selection.area.toFixed(2) + '] ';
          }

          output += '[P:' + selection.productIds + '] ';

          const productId = selection.productIds[selection.productIds.length - 1];
          const product = this.store.apiData.products.find(_product => _product.id === productId);

          if (product !== undefined) {
            output += '[D€:' + product.unitPriceDelta + ' F€' + product.unitPriceFull + '] ';

            if (product.attributes !== undefined && product.attributes.tilingHeight !== undefined) {
              output += '[H:' + product.attributes.tilingHeight + 'm] ';
            }
          }
        }

        if (selection.totalPrice > 0)
          output +=
            '[T€:' +
            selection.totalPrice.toFixed(2) +
            (selection.basePrice > 0 ? ' (B€:' + selection.basePrice.toFixed(2) + ')' : '') +
            '] ';

        if (
          selection.referencedProductGroup !== undefined &&
          selection.referencedProductGroup !== null
        )
          output += '[R:' + selection.referencedProductGroup + '] ';

        if (selection.productSlotSlugs) output += '[S:' + selection.productSlotSlugs + '] ';
        if (selection.unselectedSlotSlugs) output += '[U:' + selection.unselectedSlotSlugs + '] ';

        return output;
      }
    },
    getUnselected(slug) {
      const room = this.store.userSelectionData.products[this.roomSlug];
      const selection = room.find(_selection => _selection.slotSlug === slug);

      if (selection !== undefined) return selection.unselectedSlotSlugs;
      else return [];
    },
  },
};
</script>
<style lang="scss">
.unselected {
  text-decoration: line-through;
}

.selection {
  color: #007700;
  font-weight: bold;
}

.reference {
  color: #00aaff;
  font-weight: bold;
}

.quantity {
  color: #888888;
  font-weight: bold;
}

.attributes {
  color: #0000ff;
  font-weight: bold;
}
</style>
