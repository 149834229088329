export const getEnvironment = () => {
  if (process.env.VUE_APP_ENVIRONMENT !== undefined) {
    return process.env.VUE_APP_ENVIRONMENT;
  }
  const isDev = ['localhost', 'dev.konfigurator.bpd-de.de'].includes(window.location.hostname);
  return isDev ? 'dev' : 'production';
};

export const getApiBaseUrl = () => {
  if (process.env.VUE_APP_API_URL !== undefined) {
    return process.env.VUE_APP_API_URL;
  }

  if (window.location.hostname === 'dev.konfigurator.bpd-de.de') {
    return 'https://dev.cms.konfigurator.bpd-de.de';
  }

  return 'https://cms.konfigurator.bpd-de.de';
};

export const getEnableConfigurationSave = () => {
  return process.env.VUE_APP_ENABLE_CONFIGURATION_SAVE !== 'false';
};
