<template>
  <div class="pattern">
    <div class="borderPadding">
      <div class="dottedBorder"></div>

      <p class="pattern_headline">Wählen Sie ein Verlegemuster<br />(ohne Visualisierung)</p>
      <ul class="pattern_list">
        <li
          v-for="(pattern, index) in getPatterns()"
          :key="index"
          :class="['pattern_list_ele', { selected: selectedPatternIndex == index }]"
          @click="onPattern(index, pattern)"
        >
          <div class="radioBtn">
            <div class="radioBtn_cirlce"></div>
            <div class="radioBtn_cirlce_inner"></div>
          </div>
          <div class="descr">
            <p>{{ pattern.title }}</p>
            <p>{{ pattern.message }}</p>
          </div>
          <div class="icon">
            <img onload="this.style.opacity=1" class="icon_object" :src="pattern.image.url" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { store, mutations, getters } from '@/store/index.js';
import { getPriceFromProduct } from '../service/priceCalculator/index.js';

export default {
  name: 'ProductPatternsComp',
  props: {
    selectedProduct: {
      type: Object,
      required: true,
    },
    selectedVariant: {
      type: Object,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedPatternIndex: 0,
      icons: {
        checkCircle: require('!html-loader!@/assets/svgs/checkCircle.svg'),
      },
    };
  },
  watch: {
    selectedProduct() {
      this.highlightDefaultPattern();
    },

    selectedVariant() {
      this.highlightDefaultPattern();
    },
  },
  mounted() {
    this.highlightSelectedPattern();
  },
  methods: {
    highlightDefaultPattern() {
      this.selectedPatternIndex = 0;
    },

    highlightSelectedPattern() {
      const patterns = this.getPatterns();
      const selection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);

      //if we got a pattern id in the selection use that one, else check if patterns exist at all in that variant
      const selectedPatternId = selection.patternIds
        ? selection.patternIds[0]
        : patterns.length
        ? patterns[0].id
        : 0;

      store.apiData.products.forEach(_element => {
        //Found variant by selected variant Id
        if (_element.id == this.selectedVariant.id) {
          //Product has patterns
          if (_element.patternIds.length) {
            _element.patternIds.forEach((_patternId, _index) => {
              if (_patternId == selectedPatternId) {
                this.selectedPatternIndex = _index;
              }
            });
          }
        }
      });
    },

    getPatterns() {
      const array = [];
      this.selectedVariant.patternIds.forEach(_patternId => {
        store.apiData.products.find(_product => {
          if (_product.id == _patternId) {
            array.push(_product);
          }
        });
      });
      return array;
    },

    onPattern(_index, _pattern) {
      const currentSelection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);

      const newSelection = {
        slotSlug: currentSelection.slotSlug,
        productIds: currentSelection.productIds,
        patternIds: [_pattern.id],
        //totalPrice: _pattern.unitPrice,
        // totalPrice: this.selectedVariant.unitPrice * this.slotItem.quantity,
        //          getPriceFromVariant
        totalPrice: getPriceFromProduct(this.selectedVariant, this.slotItem),
      };
      console.log('[price]', _pattern);

      mutations.addSelection(this.$route.params.roomSlug, this.slotSlug, newSelection);
      this.selectedPatternIndex = _index;
      this.$emit('on-pattern-selected', _pattern);
    },
  },
};
</script>

<style lang="scss" scoped>
.pattern {
  .borderPadding {
    padding-top: 0;
  }
  &_headline {
    font-weight: normal;
    color: var(--BPDgray1);
    margin: 14px 0 0 0;
  }
  &_list {
    margin-top: 14px;

    &_ele {
      border-radius: 5px;
      background-color: var(--White);
      display: flex;
      height: 60px;
      margin-bottom: 1px;
      border-radius: 5px 5px 0 0;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
        border-radius: 0 0 5px 5px;
      }
    }

    .radioBtn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      pointer-events: none;
      &_cirlce {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: var(--BPDgray3);
        border-radius: 50%;
        &_inner {
          width: 6px;
          height: 6px;
          background-color: var(--BPDgreen1);
          border-radius: 50%;
          z-index: 1;
          display: none;
        }
      }
    }
    .descr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px 0 0px;
      flex: 1 1 auto;
      min-width: 140px; //TODO: For now because text break anima on switching text
      pointer-events: none;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 0.875em;
        color: var(--Asphalt);
        &:last-child {
          font-weight: normal;
        }
      }
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 0 auto;
      pointer-events: none;

      &_object {
        width: auto;
        height: 34px;
        opacity: 0;
        transition: 0.25s;
      }
    }
  }
}

.selected {
  .radioBtn_cirlce {
    background-color: var(--BPDgreen2);
    &_inner {
      display: block;
    }
  }
}
</style>
