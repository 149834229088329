<template>
  <div class="dump">
    <textarea v-model="csvDump"></textarea>
  </div>
</template>

<script>
export default {
  name: 'DumpView',
  inject: ['store'],
  computed: {
    csvDump() {
      const fileMap = this.store.renderView.fileMap;
      let csv = 'Render ID\tType\tManufacturer\tProduct\tSize\tManufacturer ID\tFiles\n';

      for (const [productKey, productValue] of Object.entries(fileMap)) {
        // console.log(productKey);

        csv += `${productKey}\t`;

        if (productValue === true) {
          csv += 'not rendered\t';
        } else {
          let info = '\t';
          let layers = '';

          for (const [slotKey, slotValue] of Object.entries(productValue)) {
            switch (slotKey) {
              case 'info':
                // console.log(slotValue.type, slotValue.manufacturer, slotValue.name, slotValue.id);
                // info = `${slotValue.type}\t${slotValue.manufacturer}\t${slotValue.name}\t${slotValue.size}\t${slotValue.id}\t`;

                info = '';
                info += slotValue.type !== undefined ? slotValue.type + '\t' : '\t';
                info += slotValue.manufacturer !== undefined ? slotValue.manufacturer + '\t' : '\t';
                info += slotValue.name !== undefined ? slotValue.name + '\t' : '\t';
                info += slotValue.size !== undefined ? slotValue.size + '\t' : '\t';
                info += slotValue.id !== undefined ? slotValue.id + '\t' : '\t';
                break;

              default:
                // console.log(slotValue);

                if (slotValue === null) {
                  layers += '\t';
                } else {
                  layers += slotKey + ': ' + slotValue.substring(slotValue.lastIndexOf('/') + 1) + '\t';
                }
                break;
            }
          }

          csv += info + layers;
        }

        csv += '\n';
      }

      return csv;
    },
  },
};
</script>

<style lang="scss">
.dump {
  position: absolute;
  top: 114px;
  left: 0;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: auto;
  width: 50%;
  height: 50%;

  textarea {
      width: 100%;
      height: 100%;
      font-family: monospace;
  }
}
</style>
