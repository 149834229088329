<template>
  <div class="planner">
    <CookieBanner v-if="cookieConsent === null" />
    <Save ref="save" />

    <ConflictingRoomFlooring ref="conflictingRoomFlooring" />

    <RenderView ref="renderView" :selected-room-slug="roomSlug" :selected-page-slug="pageSlug" />

    <RoomMenu ref="roomMenu" :selected-room-slug="roomSlug" />

    <div v-if="isDev">
      <ul class="debug_menu">
        <li @click="showDebugView = !showDebugView">Debug Structure</li>
        <li @click="showLayerView = !showLayerView">Debug Render Layers</li>
        <li @click="showDumpView = !showDumpView">Dump RenderLayer Data</li>
      </ul>
      <DebugView v-if="showDebugView" />
      <LayerView v-if="showLayerView" />
      <DumpView v-if="showDumpView" />
    </div>

    <Checkout ref="checkout" :selected-room-slug="roomSlug" />
    <RoomConfigurator
      ref="roomConfigurator"
      :selected-room-slug="roomSlug"
      @on-conflicting-products="onConflictingProducts"
      @on-conflicting-room-flooring="onConflictingRoomFlooring"
    />

    <div class="info">
      <div v-if="showOverlay" class="info_overlay"></div>
      <ProjectInfoModal />
      <div
        :class="['info_item project', { projectInfoIsActive: projectInfoIsActive }]"
        @click="onProjectInfo"
      >
        <div class="icon" v-html="icons.wohnflaeche"></div>
        <h6 class="copy">Wohnungsdetails</h6>
      </div>
      <ProjectLegalModal />
      <div
        :class="['info_item legal', { projectLegalIsActive: projectLegalIsActive }]"
        @click="onProjectLegal"
      >
        <div class="icon" v-html="icons.info"></div>
        <h6 class="copy">Musterwohnung</h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mutations } from '@/store/index.js';
import { saveConfiguration, apiErrorReport } from '@/service/apiService/index.js';
import ConflictingRoomFlooring from '@/components/ConflictingRoomFlooring_comp.vue';
import Save from '@/components/Save_comp.vue';

import RoomMenu from '@/components/RoomMenu_comp.vue';

import Checkout from '@/components/Checkout_comp.vue';
import RoomConfigurator from '@/components/RoomConfigurator_comp.vue';
import ProjectInfoModal from '@/components/ProjectInfoModal_comp';
import ProjectLegalModal from '@/components/ProjectLegalModal_comp';
import CookieBanner from '@/components/CookieBanner_comp.vue';
import RenderView from '@/components/RenderView.vue';
import DebugView from '@/components/DebugView.vue';
import LayerView from '@/components/LayerView.vue';
import DumpView from '@/components/DumpView.vue';

export default {
  name: 'PlannerView',
  inject: ['store'],
  components: {
    ConflictingRoomFlooring,
    Save,
    RoomMenu,
    Checkout,
    RoomConfigurator,
    ProjectInfoModal,
    ProjectLegalModal,
    CookieBanner,
    RenderView,
    DebugView,
    LayerView,
    DumpView,
  },
  props: {
    roomSlug: {
      type: String,
      required: true,
      default: '72',
    },
  },
  data() {
    return {
      isDev: this.store.config.environment === 'dev',
      apartment: null,
      layers: [],
      icons: {
        wohnflaeche: require('!html-loader!@/assets/svgs/wohnflaeche.svg'),
        info: require('!html-loader!@/assets/svgs/info.svg'),
      },
      conflictingProductsCompIsActive: false,
      conflictingProductsData: null,
      confirmCB: null,
      conflictingRoom: null,
      saveConfigurationTimeout: null,
      cookieConsent: this.$cookies.get('cookieConsent'),
      showDebugView: false,
      showLayerView: false,
      showDumpView: false,
    };
  },
  computed: {
    pageSlug() {
      return this.$route.params.slotPageSlug;
    },
    projectLegalIsActive() {
      return this.store.ui.projectLegalIsActive;
    },
    projectInfoIsActive() {
      return this.store.ui.projectInfoIsActive;
    },
    showOverlay() {
      return this.projectInfoIsActive || this.projectLegalIsActive;
    },
  },
  watch: {
    async 'store.userSelectionData.changed'() {
      if (
        !this.store.config.enableConfigurationSave ||
        this.store.apiData.apartment.configuration.isSoftConfigurator
      ) {
        return;
      }
      this.saveConfiguration();
    },
  },
  mounted() {
    this.$refs.checkout.$el.addEventListener('pointerdown', this.onpointerdown);
    this.$refs.renderView.$el.addEventListener('pointerdown', this.onpointerdown);
    this.$refs.roomMenu.$el.addEventListener('pointerdown', this.onpointerdown);
    this.$refs.roomConfigurator.$el.addEventListener('pointerdown', this.onpointerdown);
    window.addEventListener('resize', this.onWindowResize);
    //trigger once to initially update renderView and RoomConfigurator
    this.onWindowResize();
    console.log('cookieConsent', this.cookieConsent);
  },
  beforeDestroy() {
    this.$refs.checkout.$el.removeEventListener('pointerdown', this.onpointerdown);
    this.$refs.renderView.$el.removeEventListener('pointerdown', this.onpointerdown);
    this.$refs.roomMenu.$el.removeEventListener('pointerdown', this.onpointerdown);
    this.$refs.roomConfigurator.$el.removeEventListener('pointerdown', this.onpointerdown);
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    onWindowResize() {
      this.$refs.renderView.onWindowResize();
      this.$refs.roomConfigurator.onWindowResize(window.innerWidth);
    },

    onConflictingRoomFlooring(_conflictingRoomData, _confirmCB) {
      this.$refs.conflictingRoomFlooring.open(_conflictingRoomData, _confirmCB);
    },

    onConflictingProducts(_conflictingProducts, _addSelectedProductCB, _addProductCB) {
      this.conflictingProductsData = {
        conflictingProducts: _conflictingProducts,
        addSelectedProductCB: _addSelectedProductCB,
        addProductCB: _addProductCB,
      };
      this.conflictingProductsCompIsActive = true;
    },

    onpointerdown() {
      mutations.setProjectInfoIsActive(false);
      mutations.setProjectLegalIsActive(false);
    },

    onProjectInfo() {
      mutations.setProjectInfoIsActive(!this.store.ui.projectInfoIsActive);
      mutations.setProjectLegalIsActive(false);
    },

    onProjectLegal() {
      mutations.setProjectLegalIsActive(!this.store.ui.projectLegalIsActive);
      mutations.setProjectInfoIsActive(false);
    },

    saveConfiguration() {
      if (this.saveConfigurationTimeout) {
        clearTimeout(this.saveConfigurationTimeout);
      }
      this.saveConfigurationTimeout = setTimeout(async () => {
        await this.$refs.save.onStartSaving();
        await saveConfiguration(this.$route.params.bpdId, this.store.userSelectionData)
          .then(() => {
            this.$refs.save.onSavingFinished();
          })
          .catch(_error => {
            this.$refs.save.onSavingFinished();
            apiErrorReport(_error);
          });
      }, 2500);
    },
  },
};
</script>

<style lang="scss" scoped>
.planner {
  position: fixed;
  width: 100%;
  height: 100%;
}

.info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
  z-index: 5;

  &_item {
    position: relative;
    height: 38px;
    bottom: 40px;
    background: var(--White);
    border-radius: 5px;
    box-shadow: inset 0px 0px 0px 2px none;
    transition: all 0.25s;
    pointer-events: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    cursor: pointer;

    .icon {
      position: relative;
      width: 28px;
      left: 6px;
      color: var(--Charcoal);
      flex: 0 0 auto;
    }

    .copy {
      margin-left: 12px;
      width: 0px;
      transition: all 0.25s;
      color: var(--BPDgray2);
    }
  }

  .project {
    left: 30px;
    @include ishoverSupported {
      &:hover {
        box-shadow: 0 2px 20px 0 rgba(84, 84, 84, 0.5);
        .icon {
          color: var(--White);
        }
        .copy {
          width: 146px;
        }
      }
    }
  }

  .legal {
    margin-left: 50px;

    @include ishoverSupported {
      &:hover {
        box-shadow: 0 2px 20px 0 rgba(84, 84, 84, 0.5);
        width: min-content;
        .icon {
          color: var(--White);
        }
        .copy {
          width: 138px;
        }
      }
    }
  }

  .projectInfo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: 100px;
    left: 32px;
    border-radius: 5px;
  }

  .projectLegal {
    position: absolute;
    top: auto;
    right: auto;
    bottom: 100px;
    left: 32px;
    border-radius: 5px;
    z-index: 1;
  }
}

.projectInfoIsActive {
  .copy {
    width: 146px;
  }
}

.projectLegalIsActive {
  .copy {
    width: 138px;
  }
}

.debug_menu {
  position: absolute;
  top: 80px;
  left: 0;
  padding: 4px;

  li {
    display: inline;
    padding: 0 8px;
    margin: 0 4px;
    background: #FFF;
  }

  li:hover {
    background: #EEE;
  }
}

@include tabletAndPhone {
  .info_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(84, 84, 84, 0.6);
  }

  .info_item {
    &.project,
    &.legal {
      display: none;
    }
  }
  .info .projectLegal,
  .info .projectInfo {
    z-index: 6;
    margin: 0 auto;
    top: 50%;
    left: 0;
    right: 0;
    display: table;
    transform: translateY(-50%);
    position: absolute;
  }
}
@include phone {
  .info .projectLegal,
  .info .projectInfo {
    width: 90%;
  }
}
</style>
