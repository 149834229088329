<template>
  <div v-if="room.id" id="renderView" class="renderView">
    <canvas
      id="backgroundView"
      class="renderView_canvas"
      :style="{
        left: x + 'px',
        top: y + 'px',
        width: backgroundWidth + 'px',
        height: backgroundHeight + 'px',
      }"
    ></canvas>
    <canvas
      id="foregroundView"
      class="renderView_canvas hidden"
      :style="{
        left: x + 'px',
        top: y + 'px',
        width: renderWidth + 'px',
        height: renderHeight + 'px',
      }"
    ></canvas>
    <div
      id="renderView_hotspots"
      class="renderView_hotspots"
      :style="{
        left: x + 'px',
        top: y + 'px',
        width: renderWidth + 'px',
        height: renderHeight + 'px',
      }"
    >
      <a
        v-for="hotspot in hotspots"
        :key="hotspot.label"
        :style="{
          left: hotspot.x + '%',
          top: hotspot.y + '%',
        }"
        :title="hotspot.label"
        class="renderView_hotspot"
        @click="onHotspot(hotspot.slug)"
        v-html="icons.hotspot"
      >
      </a>
    </div>
    <div
      :class="{ renderView_progress: true, transition: loadingProgress > 0 }"
      :style="{ width: loadingProgress + '%' }"
    ></div>
  </div>
</template>

<script>
import { getters } from '@/store/index.js';

export default {
  name: 'RenderView',
  inject: ['store'],
  props: {
    selectedRoomSlug: {
      type: String,
      required: true,
    },
    selectedPageSlug: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data: function() {
    return {
      init: true,
      aspect: 1,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      xMin: 0,
      yMin: 0,
      xMax: 0,
      yMax: 0,
      xPercentage: 50,
      yPercentage: 50,
      canvasWidth: 0,
      canvasHeight: 0,
      viewportWidth: 0,
      viewportHeight: 0,
      hotspotLayer: undefined,
      renderView: undefined,
      renderWidth: 1920,
      renderHeight: 1080,
      renderCanvas: undefined,
      renderContext: undefined,
      backgroundWidth: 1920,
      backgroundHeight: 1080,
      backgroundCanvas: undefined,
      backgroundContext: undefined,
      loadingProgress: 0.0,
      isDragging: false,
      pointerIsDown: false,
      layers: {},
      images: {},
      hotspots: [],
      icons: {
        hotspot: require('!html-loader!@/assets/svgs/hotspot.svg'),
      },
      // slotLookup: {},
      // roomLookup: {},
    };
  },
  computed: {
    room() {
      return this.store.apiData.rooms.find(_room => _room.slug === this.selectedRoomSlug);
    },
    page() {
      return this.selectedPageSlug === undefined
        ? undefined
        : this.store.apiData.rooms
            .find(_room => _room.slug === this.selectedRoomSlug)
            .children.find(_page => _page.slug == this.selectedPageSlug);
    },
    roomVariant() {
      // todo: get this information from user selection
      // because this will currently not take into account "Dusche statt Badewanne"
      const room = this.room;
      const keys = {
        '54': 'W', // Washbasin
        '60': 'T', // Toilet
        '374': 'B', // Bathtub
        '392': 'S', // Shower
      };
      const items = [];
      room.children.forEach(group => {
        group.children.forEach(slot => {
          if (keys[slot.id] !== undefined) {
            items.push(keys[slot.id]);
          }
        });
      });

      switch (items.sort().join('')) {
        case 'BSTW':
        case 'BSW':
          return '_L'; // large bathroom
        case 'BTW':
        case 'BW':
          return '_MB'; // medium bathroom with bathtub
        case 'STW':
        case 'SW':
          return '_MS'; // medium bathroom with shower
        case 'TW':
        case 'W':
          return '_S'; // small bathroom
        default:
          return '';
      }
    },
    key() {
      return this.$route.path;
    },
  },
  watch: {
    'store.userSelectionData.changed'() {
      this.layers = this.getLayers();
      this.loadLayers(this.layers);
    },
    '$route.params': function(to, from) {
      const _self = this;

      this.layers = this.getLayers();
      this.loadLayers(this.layers);

      if (from.roomSlug != to.roomSlug) {
        this.hotspotLayer.classList.add('hidden');
        setTimeout(function() {
          _self.updateHotspots(to.roomSlug, to.slotPageSlug);
        }, 500);
      }
    },
  },

  beforeDestroy() {
    this.removeEventlisteners();
  },
  mounted() {
    this.init = true;
    this.webpSupport = this.store.featureDetection.isWebpSupported;
    this.renderView = document.getElementById('renderView');
    this.backgroundCanvas = document.getElementById('backgroundView');
    this.backgroundContext = this.backgroundCanvas.getContext('2d');
    this.renderCanvas = document.getElementById('foregroundView');
    this.renderContext = this.renderCanvas.getContext('2d');

    this.workCanvas = document.createElement('canvas');
    this.workCanvas.width = this.renderCanvas.width;
    this.workCanvas.height = this.renderCanvas.height;
    this.workContext = this.workCanvas.getContext('2d');

    this.hotspotLayer = document.getElementById('renderView_hotspots');

    // this.createLookups();
    this.layers = this.getLayers();
    this.positions = this.getPositions(this.store.apiData.rooms);
    this.loadLayers(this.layers);
    this.updateHotspots(this.selectedRoomSlug);
    this.addEventListeners();

    console.log('noPriceCalc', this.store.apiData.project.noPriceCalc);
    console.log('hidePrices', this.store.apiData.project.hidePrices);
  },
  methods: {
    addEventListeners() {
      const renderViewEle = document.getElementById('renderView_hotspots');
      renderViewEle.addEventListener('pointerdown', this.pointerDownHandler);
      renderViewEle.addEventListener('pointerup', this.pointerupHandler);
      renderViewEle.addEventListener('pointermove', this.pointerMoveHandler);
      renderViewEle.addEventListener('pointerout', this.pointerOutHandler);
    },

    removeEventlisteners() {
      const renderViewEle = document.getElementById('renderView_hotspots');
      renderViewEle.removeEventListener('pointerdown', this.pointerDownHandler);
      renderViewEle.removeEventListener('pointerup', this.pointerupHandler);
      renderViewEle.removeEventListener('pointermove', this.pointerMoveHandler);
      renderViewEle.removeEventListener('pointerout', this.pointerOutHandler);
    },

    pointerDownHandler(_event) {
      _event.preventDefault();
      this.isDragging = false;
      this.pointerIsDown = true;
      this.dragX = _event.clientX;
      this.dragY = _event.clientY;
    },

    pointerupHandler(_event) {
      _event.preventDefault();
      this.isDragging = false;
      this.pointerIsDown = false;
      this.dragX = 0;
      this.dragY = 0;
    },

    pointerMoveHandler(_event) {
      _event.preventDefault();
      if (this.pointerIsDown) {
        this.isDragging = true;

        this.dragDeltaX = this.dragX - _event.clientX;
        this.dragDeltaY = this.dragY - _event.clientY;
        this.dragX = _event.clientX;
        this.dragY = _event.clientY;

        let x = this.x - this.dragDeltaX;
        let y = this.y - this.dragDeltaY;

        if (x < this.xMin) x = this.xMin;
        else if (x > this.xMax) x = this.xMax;
        if (y < this.yMin) y = this.yMin;
        else if (y > this.yMax) y = this.yMax;

        this.x = x;
        this.y = y;
      }
    },

    pointerOutHandler(_event) {
      _event.preventDefault();
      this.isDragging = false;
      this.pointerIsDown = false;
      //this.dragX = 0;
      //this.dragY = 0;
    },

    //Gots called by general window resize event in Planner-view.vue
    onWindowResize() {
      this.calculatePosition(true);
    },

    processSelections(_selections) {
      const selectedSlots = {};
      // let selectedSlotSlugs = [];
      let unselectedSlotSlugs = getters.getUserSelectionSwitchUnselected();

      // get slot slugs influenced by switch fields
      // _selections.forEach(product => {
      //   if (product.productSlotSlugs !== undefined) {
      //     selectedSlotSlugs = selectedSlotSlugs.concat(product.productSlotSlugs);
      //   }
      //   if (product.unselectedSlotSlugs !== undefined) {
      //     unselectedSlotSlugs = unselectedSlotSlugs.concat(product.unselectedSlotSlugs);
      //   }
      // });

      // console.log('[getLayers] selectedSlotSlugs', selectedSlotSlugs);
      // console.log('[getLayers] unselectedSlotSlugs', unselectedSlotSlugs);

      _selections.forEach(selection => {
        const slotSlug = selection.slotSlug;
        const slotId = slotSlug.replace(/(^\d+)(.+$)/i, '$1');
        const roomLookup = this.store.lookUps.roomSlugBySlotSlug;
        const slotLookup = this.store.lookUps.slotBySlotSlug;

        if (selection.referencedProductGroup !== undefined) {
          // referenced selection
          // console.log(
          //   '[getLayers] reference',
          //   selection.slotSlug,
          //   '>',
          //   selection.referencedProductGroup
          // );

          if (
            slotLookup[selection.referencedProductGroup] !== undefined &&
            slotLookup[selection.referencedProductGroup].children !== undefined
          ) {
            const slots = slotLookup[selection.referencedProductGroup].children;

            slots.forEach(slot => {
              const roomKey = roomLookup[slot.slug];
              const referencedRoom = this.store.userSelectionData.products[roomKey];
              const referencedSelection = referencedRoom.find(
                _selection => _selection.slotSlug === slot.slug
              );
              if (
                referencedSelection.productIds !== undefined &&
                referencedSelection.productIds.length > 0
              ) {
                const referencedSlotId = referencedSelection.slotSlug.replace(/(^\d+)(.+$)/i, '$1');
                // console.log(
                //   '[getLayers] actual reference',
                //   selection.slotSlug,
                //   roomKey,
                //   slot.slug,
                //   referencedSlotId
                // );
                // console.log(referencedSelection);

                selectedSlots[slotId + '_' + referencedSlotId] = referencedSelection;
              }
            });
          }
        } else if (unselectedSlotSlugs.includes(selection.slotSlug)) {
          // omit, if slot is disabled by switch field
          // noop
        } else if (selection.productIds !== undefined && selection.productIds.length > 0) {
          // selection has product ids set
          selectedSlots[slotId] = selection;
        }
      });

      return selectedSlots;
    },
    getLayers() {
      const path = this.store.config.renderingsPath;
      const layerSortMap = this.store.renderView.layerSortMap;
      const fileMap = this.store.renderView.fileMap;
      const cascades = this.store.renderView.cascades;
      const references = this.store.renderView.references;

      const roomKey = this.selectedRoomSlug;
      const products = {};
      let layers = {};
      const suffixes = {};
      const roomTypeId = roomKey.replace(/(^\d+)(.+$)/i, '$1');
      const roomVariant = this.roomVariant;

      // inject standard rendering layers
      products[roomTypeId + '_BG' + roomVariant] = 'Background';
      products[roomTypeId + '_FG' + roomVariant] = 'Foreground';

      if (this.room.image !== undefined && this.room.image !== null) {
        layers['A_background'] = this.room.image.url;
      }

      const selections = this.processSelections(this.store.userSelectionData.products[roomKey]);
      console.log('selections', selections);

      Object.keys(selections).forEach(slotId => {
        const selection = selections[slotId];
        const productId = selection.productIds[selection.productIds.length - 1];
        const tempProduct = this.store.apiData.products.find(product => product.id === productId);
        const renderingId = tempProduct !== undefined ? tempProduct.renderingId : undefined;

        if (cascades[renderingId] !== undefined) {
          const targetSlotIds = cascades[renderingId];
          // console.log('[cascades]', renderingId, targetSlotIds);

          Object.keys(targetSlotIds).forEach(targetSlotId => {
            if (selections[targetSlotId] !== undefined) {
              if (targetSlotIds[targetSlotId] === null) {
                // remove layer if target is null
                delete selections[targetSlotId];
              } else {
                // otherwise replace file name
                selections[targetSlotId] = targetSlotIds[targetSlotId];
              }
            }
          });
        }
      });

      // get all selected products
      Object.keys(selections).forEach(slotId => {
        const selection = selections[slotId];
        const productId = selection.productIds[selection.productIds.length - 1];
        const tempProduct = this.store.apiData.products.find(product => product.id === productId);
        const renderingId = tempProduct !== undefined ? tempProduct.renderingId : undefined;

        if (renderingId !== undefined) {
          products[slotId] = renderingId;
        }
        console.log('[getLayers] ProductID ' + slotId + ':' + renderingId + ' ' + productId);
      });

      // get referenced products from custom slots
      const globalSelections = this.processSelections(
        this.store.userSelectionData.products['72-wohnraeume']
      );

      Object.keys(references).forEach(slotSlug => {
        const _roomTypeId = slotSlug.replace(/(^\d+)(.+$)/i, '$1');
        if (_roomTypeId !== roomTypeId) {
          return;
        }

        references[slotSlug].forEach(refSlotId => {
          const selection = globalSelections[refSlotId];
          if (selection) {
            const productId = selection.productIds[selection.productIds.length - 1];
            const tempProduct = this.store.apiData.products.find(
              product => product.id === productId
            );
            const renderingId = tempProduct !== undefined ? tempProduct.renderingId : undefined;

            if (renderingId !== undefined) {
              products[slotSlug] = renderingId;
            }
            console.log(
              '[getLayers] ProductID Ref ' + refSlotId + ':' + renderingId + ' ' + productId
            );
          }
        });
      });

      // get all influences
      Object.keys(products).forEach(slotId => {
        const renderingId = products[slotId];

        if (fileMap[renderingId] !== undefined) {
          if (fileMap[renderingId] !== true) {
            if (fileMap[renderingId]['influences'] !== undefined) {
              console.log('cascades', fileMap[renderingId]['cascades']);
              fileMap[renderingId]['influences'].forEach(influence => {
                // warning: currently only supports a single influence at a time - additional influences are overwritten
                suffixes[influence['renderId']] = influence['suffix'];
              });
            }
          }
        }
      });

      // get all render layers
      Object.keys(products).forEach(slotId => {
        const renderingId = products[slotId];

        if (fileMap[renderingId] !== undefined) {
          if (fileMap[renderingId] !== true) {
            let suffix = suffixes[renderingId] !== undefined ? suffixes[renderingId] : '';

            let fileName =
              fileMap[renderingId][slotId] !== undefined
                ? fileMap[renderingId][slotId + suffix]
                : fileMap[renderingId][slotId + roomVariant + suffix];

            console.log('[getLayers] FileName ' + slotId + ':' + renderingId + ' ' + fileName);

            const sortKey =
              layerSortMap[slotId] !== undefined
                ? layerSortMap[slotId] + '_' + slotId
                : 'W_' + slotId;

            if (fileName !== undefined && fileName !== null) {
              layers[sortKey] = path + fileName + (this.webpSupport ? '.webp' : '.png');
            } else if (fileName !== null) {
              console.warn(
                '[getLayers] Layer not found ' + slotId + roomVariant + ':' + renderingId
              );
            }
          }
        } else {
          console.warn('[getLayers] Product not found ' + slotId + ':' + renderingId);
        }
      });

      // let output = '';

      // Object.keys(layers)
      //   .sort()
      //   .forEach(key => {
      //     output += key + ': ' + layers[key] + '\n';
      //   });

      // console.log('[getLayers] Layers\n', output);

      return layers;
    },
    loadLayers(layers) {
      const _self = this;
      var counter = Object.keys(layers).length;
      var total = counter;

      this.loadingProgress = Math.ceil(((total - counter) / total) * 100);

      _self.images = {};

      Object.keys(layers).forEach(key => {
        const img = new Image();

        img.onload = function() {
          _self.images[key] = this;
          counter--;

          _self.loadingProgress = Math.ceil(((total - counter) / total) * 100);

          if (counter == 0) {
            _self.renderLayers();
            _self.calculatePosition();
            setTimeout(function() {
              _self.loadingProgress = 0;
            }, 500);
          }
        };

        img.onerror = function() {
          counter--;

          console.error('unable to load image ' + this.src);
          _self.loadingProgress = Math.ceil(((total - counter) / total) * 100);

          if (counter == 0) {
            _self.renderLayers();
            _self.calculatePosition();
            setTimeout(function() {
              _self.loadingProgress = 0;
            }, 500);
          }
        };

        img.src = layers[key];
      });
    },
    renderLayers() {
      const _self = this;
      var images = {};
      var width = -1;
      var height = -1;

      Object.keys(this.images)
        .sort()
        .forEach(function(i) {
          // console.log(_self.images[i].src, _self.images[i].width, _self.images[i].height);

          width = _self.images[i].width;
          height = _self.images[i].height;

          images[i] = {
            image: _self.images[i],
            color: '#FFFFFF',
          };
        });

      if (width != -1 && height != -1) {
        this.canvasWidth = width;
        this.canvasHeight = height;
        this.aspect = this.canvasWidth / this.canvasHeight;

        this.renderCanvas.width = this.canvasWidth;
        this.renderCanvas.height = this.canvasHeight;
      }

      Object.keys(images).forEach(key => {
        if (images[key].color == '#FFFFFF') {
          this.renderContext.globalCompositeOperation = 'source-over';
          this.renderContext.drawImage(images[key].image, 0, 0);
        } else {
          this.workContext.globalCompositeOperation = 'source-over';
          this.workContext.fillStyle = images[key].color;
          this.workContext.fillRect(0, 0, this.workCanvas.width / 2, this.workCanvas.height);

          this.workContext.globalCompositeOperation = 'destination-in';
          this.workContext.drawImage(images[key].image, 0, 0);

          this.workContext.globalCompositeOperation = 'multiply';
          this.workContext.drawImage(images[key].image, 0, 0);

          this.renderContext.globalCompositeOperation = 'source-over';
          this.renderContext.drawImage(this.workCanvas, 0, 0);
        }
      });

      if (!this.init) {
        this.renderView.classList.add('transition');
      }

      this.renderCanvas.classList.remove('hidden');

      setTimeout(function() {
        _self.backgroundWidth = _self.renderWidth;
        _self.backgroundHeight = _self.renderHeight;
        _self.backgroundCanvas.width = _self.canvasWidth;
        _self.backgroundCanvas.height = _self.canvasHeight;
        _self.backgroundContext.drawImage(_self.renderCanvas, 0, 0);
        _self.renderCanvas.classList.add('hidden');
        _self.hotspotLayer.classList.remove('hidden');
      }, 500);
    },
    getPositions(items, parentPosition) {
      var positions = {};

      items.forEach(item => {
        var position = parentPosition;

        if (item.position !== undefined && item.position !== null) {
          positions[item.slug] = item.position;
          position = item.position;
        } else {
          positions[item.slug] = position;
        }

        if (item.children !== undefined && item.children !== null) {
          const subPositions = this.getPositions(item.children, position);
          positions = Object.assign(positions, subPositions);
        }
      });

      return positions;
    },

    calculatePosition(immediate) {
      const _self = this;
      const page = this.page;

      let percent = this.getHotspotsCenter();
      this.xPercentage = percent.x;
      this.yPercentage = percent.y;

      let xPercentage = this.xPercentage;
      let yPercentage = this.yPercentage;
      let x;
      let y;

      if (page !== undefined && page.position !== null) {
        xPercentage = page.position.x;
        yPercentage = page.position.y;
        // console.log('[panning] position from page', xPercentage, yPercentage);
      } else {
        // console.log('[panning] position default', xPercentage, yPercentage);
      }

      this.viewportWidth = this.renderView.clientWidth;
      this.viewportHeight = this.renderView.clientHeight;

      // calculate canvas scale
      if (this.viewportHeight * this.aspect > this.viewportWidth) {
        this.renderWidth = this.viewportHeight * this.aspect;
        this.renderHeight = this.viewportHeight;
      } else {
        this.renderWidth = this.viewportWidth;
        this.renderHeight = this.viewportWidth * (1 / this.aspect);
      }

      if (immediate) {
        // console.log('immediate', immediate);
        _self.backgroundWidth = _self.renderWidth;
        _self.backgroundHeight = _self.renderHeight;
      }

      // calculate canvas bounds
      const xCenter = this.viewportWidth / 2;
      const yCenter = this.viewportHeight / 2;
      const xExtent = (this.renderWidth - this.viewportWidth) / 2;
      const yExtent = (this.renderHeight - this.viewportHeight) / 2;

      this.xMin = xCenter - xExtent;
      this.xMax = xCenter + xExtent;
      this.yMin = yCenter - yExtent;
      this.yMax = yCenter + yExtent;

      // x = this.lerp(this.xMin, this.xMax - 200, 1.0 - (((xPercentage - 50) / 50) * 1.6 + 0.5));
      // y = this.lerp(this.yMin, this.yMax, 1.0 - (((yPercentage - 50) / 50) * 1.2 + 0.5));
      x =
        this.lerp(this.xMin, this.xMax, 0.5) -
        (this.renderWidth - 400) * (xPercentage / 100.0 - 0.5);
      y = this.lerp(this.yMin, this.yMax, 0.5) - this.renderHeight * (yPercentage / 100.0 - 0.5);

      if (x < this.xMin) x = this.xMin;
      else if (x > this.xMax) x = this.xMax;
      if (y < this.yMin) y = this.yMin;
      else if (y > this.yMax) y = this.yMax;

      // if (!this.init) {
      //   this.renderView.classList.add('transition');
      //   console.log('start transition');
      // }

      // console.log('[panning] before', this.x, this.y);
      // console.log('[panning] after', x, y);

      this.x = x;
      this.y = y;

      this.xPercentage = xPercentage;
      this.yPercentage = yPercentage;

      // console.log('hotspot', x, y, xCenter - this.x, yCenter - this.y);

      setTimeout(function() {
        _self.renderView.classList.remove('transition');
        _self.init = false;
      }, 1000);
    },

    lerp(start, end, progress) {
      if (progress < 0) progress = 0;
      else if (progress > 1) progress = 1;
      return start * (1 - progress) + end * progress;
    },

    getHotspotsCenter() {
      const room = this.room;
      var counter = 0;
      var x = 0;
      var y = 0;

      Object.keys(room.children).forEach(function(p) {
        const position = room.children[p].position;
        if (position !== undefined && position !== null) {
          x += position.x;
          y += position.y;
          counter++;
        }
      });

      if (counter > 0) {
        x = x / counter;
        y = y / counter;
      } else {
        x = 50;
        y = 50;
      }

      return { x: x, y: y };
    },

    updateHotspots() {
      const room = this.room;
      var _self = this;

      _self.hotspots = [];

      Object.keys(room.children).forEach(function(p) {
        const position = room.children[p].position;
        if (position !== undefined && position !== null) {
          _self.hotspots.push({
            x: position.x,
            y: position.y,
            slug: room.children[p].slug,
            label: room.children[p].title,
          });
        }
      });
    },
    onHotspot(_item) {
      this.$router
        .push({
          name: 'Category',
          params: { slotPageSlug: _item },
        })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  opacity: 0;
}

.renderView {
  position: absolute;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.renderView_canvas {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.renderView_hotspots {
  position: absolute;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s;
}

.renderView_hotspot {
  position: absolute;
  width: 45px;
  height: 45px;
  transform: translate(-50%, -50%);
  transform-origin: 112px 112px; //width/height*2.5)
  transition: transform 0.25s;
  cursor: pointer;
  @include ishoverSupported {
    &:hover {
      transform: scale(1.25);
    }
  }
}

.transition .renderView_canvas,
.transition .renderView_hotspots {
  transition: top 1s, left 1s, opacity 0.5s;
}
.renderView_progress {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 0%;
  height: 2px;
  background: var(--BPDgreen1);
}

.renderView_progress.transition {
  transition: width 0.5s;
}

@include tabletAndPhone() {
  .renderView {
    height: calc(100% - 185px); //including border radius
  }
}
</style>
