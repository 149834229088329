<template>
  <div :class="['hint', isWarning ? 'mod-warningStyle' : '']">
    <div v-if="isWarning" class="hint_warningIcon" v-html="icons.warning"></div>
    <p class="hint_text">
      {{ content.text }}
    </p>
    <div v-if="!isWarning" class="hint_triangle"></div>
  </div>
</template>

<script>
export default {
  name: 'Hint',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        warning: require('!html-loader!@/assets/svgs/warning.svg'),
      },
    };
  },
  computed: {
    isWarning() {
      return this.content.style === 'warning';
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  position: relative;
  width: 100%;
  max-width: 340px;
  padding: 20px 20px 19px;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(84, 84, 84, 0.3);
  background-color: var(--Asphalt);
  margin: 40px auto 0 auto;
  &_warningIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
    color: var(--BPDgreen1);
  }
  &_text {
    font-size: 0.875em;
    color: var(--White);
  }
  &_triangle {
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 18px solid var(--Asphalt);
    position: absolute;
    left: -22px;
    top: 50%;
    transform: rotateZ(90deg) translateY(-50%);
    transform-origin: top center;
  }
}
.mod-warningStyle {
  &.hint {
    border: solid 2px var(--BPDgreen2);
    background-color: var(--BPDgreen3);
  }
  .hint_text {
    color: var(--Asphalt);
  }
}
</style>
