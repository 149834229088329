import { mutations, getters } from '@/store/index.js';

export function getPriceFromSlot(_slotItem) {
  const selection = getters.getSelectionBySlotSlug(_slotItem.slug);
  let productIds = selection.productIds;

  // get price from referenced slot
  if (_slotItem.referencedSlot !== undefined) {
    const referencedSlot = getters.getSelectionBySlotSlug(_slotItem.referencedSlot);
    productIds = referencedSlot.productIds;
  }

  if (productIds !== undefined && productIds.length > 0) {
    if (['checkboxes', 'packages'].includes(_slotItem.style)) {
      return productIds.reduce((sum, productId) => {
        const product = getters.getProductById(productId);
        return sum + getPriceFromProduct(product, _slotItem);
      }, 0);
    }

    const productId = productIds[productIds.length - 1];
    const product = getters.getProductById(productId);

    return getPriceFromProduct(product, _slotItem);
  } else {
    return 0;
  }
}

export function getPriceFromProduct(_product, _slotItem) {
  const selection = getters.getSelectionBySlotSlug(_slotItem.slug);
  const quantity = selection.quantity;

  const price = getFullPriceFromProduct(_product);

  const totalPrice = price * quantity;

  // console.log(
  //   '[price][product]',
  //   _slotItem.slug,
  //   _product.catalogId,
  //   '(' + _product.unitPriceDelta + ':' + _product.unitPriceFull + ')',
  //   price + ' * ' + quantity + ' = ' + totalPrice
  // );

  return totalPrice;

  // add this again, if pattern products have a price again!!!
  //
  // if (selection.patternIds !== undefined && selection.patternIds.length > 0) {
  //   const pattern = getters.getProductById(selection.patternIds[0]);
  //   const additionalPrice = getFullPriceFromProduct(pattern);

  //   const totalPrice = (price + additionalPrice) * quantity;

  //   return totalPrice;
  // } else {
  //   const totalPrice = price * quantity;
  //   console.log('[price]', _slotItem.slug, price, '*', quantity, '=', totalPrice);

  //   return totalPrice;
  // }
}

export function getPriceFromSelection(_selection) {
  let productIds = _selection.productIds;

  // get price from referenced slot
  if (_selection.referencedSlot !== undefined) {
    const referencedSlot = getters.getSelectionBySlotSlug(_selection.referencedSlot);
    productIds = referencedSlot.productIds;
  }

  if (productIds !== undefined && productIds.length > 0) {
    const productId = productIds[productIds.length - 1];
    const product = getters.getProductById(productId);
    const quantity = _selection.quantity;
    const price = getFullPriceFromProduct(product);
    const totalPrice = price * quantity;

    // console.log(
    //   '[price][selection]',
    //   _selection.slotSlug,
    //   product.catalogId,
    //   '(' + product.unitPriceDelta + ':' + product.unitPriceFull + ')',
    //   price + ' * ' + quantity + ' = ' + totalPrice
    // );

    return totalPrice;
  } else {
    return 0;
  }
}

export function calculateAllProducts() {
  // console.log('[calculateAllProducts]', 'start');

  const selections = getters.getUserSelectionProductsInOneArray();
  // console.log('[calcAll]', selections);
  selections.forEach(selection => {
    // if (selection.productIds !== undefined && selection.productIds.length > 0) {
    // const product = getters.getProductById(selection.productIds[selection.productIds.length - 1]);
    const slot = getters.getSlotBySlotSlug(selection.slotSlug);

    // console.log('[calcAll]', selection.slotSlug, selection.productIds);
    const totalPrice = getPriceFromSlot(slot);
    selection.totalPrice = totalPrice;

    if (selection.productIds !== undefined && selection.productIds.length > 0) {
      const productId = selection.productIds[selection.productIds.length - 1];
      const product = getters.getProductById(productId);
      // const attributes = store.lookUps.slotBySlotSlug[selection.slotSlug].attributes;
      // console.log('[calcAll]', slot.attributes, product.attributes);

      if (slot.attributes.tilingArea !== undefined) {
        const currentArea = slot.attributes.tilingArea * (product.attributes.tilingHeight / 2.5);
        selection.area = currentArea;
      }
    }
  });
  // console.log('[calculateAllProducts]', 'finish');
}

// This maps slots to their corresponding standard selection slot.
// It‘s used to copy the base prices. An empty array sets the base price to 0.
const baseSelectionMap = {
  '259-bodenfliesen-72': ['11685-parkett-72'], // Küche
  '414-bodenfliesen-228': ['11684-parkett-228'], // Flur
  '427-bodenfliesen-228': ['11683-parkett-228'], // Abstellraum
  '248-bodenfliesen-72': ['78-parkett-72', '11685-parkett-72'], // Wohnraum + Küche (ebenfalls Fliesen)
  '11691-bodenfliesen-228': ['11684-parkett-228'], // Flur (ebenfalls Fliesen)
  '11690-bodenfliesen-228': ['11683-parkett-228'], // Abstellraum (ebenfalls Fliesen)
  '15485-fugenfarben-72': [], // Küche
  '15484-fugenfarben-72': [], // Wohnraum + Küche (ebenfalls Fliesen)
  '15498-fugenfarben-228': [], // Flur (ebenfalls Fliesen)
  '15497-fugenfarben-228': [], // Flur
  '15495-fugenfarben-228': [], // Abstellraum (ebenfalls Fliesen)
  '15500-fugenfarben-228': [], // Abstellraum
};

function getBaseSelectionMap() {
  const selectionMap = baseSelectionMap;

  function getGroupName(slug, start = 2) {
    const parts = slug.split('-').slice(start);
    return parts.join('-');
  }

  getters
    .getUserSelectionProductsInOneArray()
    .filter(selection => selection.slotSlug.indexOf('8095-armaturenserie') === 0)
    .forEach(selection => {
      selection.unselectedSlotSlugs.forEach(unselectedSlot => {
        const groupName = getGroupName(unselectedSlot);
        const slot = selection.productSlotSlugs.find(slot => getGroupName(slot) === groupName);

        if (slot) {
          selectionMap[unselectedSlot] = [slot];
        } else {
          /* eslint-disable no-console */
          console.error(`Can’t find corresponding standard selection slot for ${unselectedSlot}`);
        }
      });
    });

  return selectionMap;
}

export function transferAllPricesToBase() {
  // some prices will cascade, so base prices have to be set after all prices have been set.
  const selections = getters.getUserSelectionProductsInOneArray();
  const baseSelectionMap = getBaseSelectionMap();

  selections.forEach(selection => {
    const baseSelectionSlotSlugs = baseSelectionMap[selection.slotSlug];

    if (baseSelectionSlotSlugs) {
      selection.basePrice = 0;
      baseSelectionSlotSlugs.forEach(slotSlug => {
        const baseSelection = selections.find(_sel => _sel.slotSlug === slotSlug);
        if (baseSelection) {
          selection.basePrice += baseSelection.totalPrice;
        } else {
          /* eslint-disable no-console */
          console.error(`Can’t find standard selection for ${slotSlug}`);
        }
      });
    } else {
      selection.basePrice = selection.totalPrice;
    }
  });

  mutations.setBasePrice(getters.getFullPrice());
}

export function calcStoreTotalPrice() {
  let output = '';
  let totalPrice = 0;
  const selections = getters.getUserSelectionProductsInOneArray();
  const unselectedSlugs = getters.getUserSelectionSwitchUnselected();

  selections.forEach(selection => {
    // filter products that are disabled due to switch fields
    output += String(Math.round(totalPrice)).padStart(6, ' ');
    if (!unselectedSlugs.includes(selection.slotSlug)) {
      output +=
        String(Math.round(selection.basePrice)).padStart(6, ' ') +
        ':A | ' +
        String(Math.round(selection.totalPrice)).padStart(6, ' ') +
        ':Σ | ' +
        String(Math.round(selection.totalPrice - selection.basePrice)).padStart(6, ' ') +
        ':Δ € | ' +
        selection.slotSlug +
        ' ' +
        selection.productIds +
        '\n';
      totalPrice += selection.totalPrice ? selection.totalPrice : 0;
    } else {
      output +=
        String(Math.round(selection.basePrice)).padStart(6, '-') +
        ':A | ' +
        String(Math.round(selection.totalPrice)).padStart(6, '-') +
        ':Σ | ' +
        String(Math.round(selection.totalPrice - selection.basePrice)).padStart(6, '-') +
        ':Δ € | ' +
        selection.slotSlug +
        '---\n';
    }
  });
  mutations.setTotalPrice(totalPrice);
  // if (setAsBasePrice) mutations.setBasePrice(totalPrice);

  output +=
    '\n' +
    String(Math.round(getters.getBasePrice())).padStart(6, ' ') +
    ':A | ' +
    String(Math.round(totalPrice)).padStart(6, ' ') +
    ':Σ | ' +
    String(Math.round(getters.getDeltaPrice())).padStart(6, ' ') +
    ':Δ € total';

  console.log('[price][total]', unselectedSlugs);
  console.log('[price][total]', output);
}

const productsWithoutPrice = [
  '4001_B', // tiling patterns floor
  '4002_B',
  '4003_B',
  '4001_W', // tiling patterns wall
  '4002_W',
  '4003_W',
  '4016_PKA', // tiling heights shower
  '4016_PKA_1',
  '4016_PKA_2',
  '4016_PKA_3',
  '4025_PKA', // tiling heights bathtub
  '4025_PKA_1',
  '4025_PKA_2',
  '4025_PKA_3',
  '4026_PKA', // tiling heights wash basin
  '4026_PKA_1',
  '4026_PKA_2',
  '4026_PKA_3',
  '4027_PKA', // tiling heights other
  '4027_PKA_1',
  '4027_PKA_2',
  '4027_PKA_3',
];

// workaround for wrong unitPriceFull values :´(
export function getFullPriceFromProduct(_product) {
  // TODO: move validation/override of prices to backend
  if (productsWithoutPrice.includes(_product.catalogId)) {
    return 0.0;
  }

  if (_product.unitPriceFull > 0) {
    return _product.unitPriceFull;
  }

  return _product.unitPriceDelta;
}
