import axios from 'axios';
import { getApiBaseUrl } from '@/utils/config';

const axiosInstance = axios.create({ baseURL: getApiBaseUrl() });

const endpoints = {
  apartment: 'v1/application',
  structure: 'v1/structure',
  products: 'v1/products',
  configuration: 'v1/configuration',
  save: 'v1/configuration/save',
  pdf: 'v1/configuration/pdf',
};

const fetchProjectData = async () => {
  return await axiosInstance.get(`${endpoints.apartment}`);
};

const fetchStructureData = async () => {
  return await axiosInstance.get(`${endpoints.structure}`);
};

const fetchProductData = async () => {
  return await axiosInstance.get(`${endpoints.products}`);
};

export function fetchData(_routerParamBpdId) {
  axiosInstance.defaults.headers.common['X-API-Token'] = _routerParamBpdId;
  return Promise.all([fetchProjectData(), fetchStructureData(), fetchProductData()]);
}

export async function fetchConfiguration(_routerParamBpdId) {
  axiosInstance.defaults.headers.common['X-API-Token'] = _routerParamBpdId;
  return await axiosInstance.get(`${endpoints.configuration}`);
}

export async function saveConfiguration(_routerParamBpdId, state, completed = false) {
  axiosInstance.defaults.headers.common['X-API-Token'] = _routerParamBpdId;
  return await axiosInstance.post(`${endpoints.save}`, { state, completed });
}

export async function generateConfigurationPdf(_routerParamBpdId, state, completed = false) {
  axiosInstance.defaults.headers.common['X-API-Token'] = _routerParamBpdId;
  return await axiosInstance.post(`${endpoints.pdf}`, { state, completed });
}

export async function downloadFileFromUrl(_url) {
  await axios.get(_url, { responseType: 'blob' }).then(_response => {
    const filename = _url.split('/').pop();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([_response.data]));
    link.setAttribute('download', filename);
    link.click();
  });
}

export const apiErrorReport = _error => {
  if (_error.response) {
    // Request made and server responded
    console.log('[apiService] errorReport() response.data: ', _error.response.data);
    console.log('[apiService] errorReport() response.status: ', _error.response.status);
    console.log('[apiService] errorReport() response.headers:', _error.response.headers);
    // if (_error.response.status === 404 || _error.response.status === 400) {
    //   this.dataFetchingFinished = true;
    //   this.$router.push({ name: 'NotFound' });
    // }
  } else if (_error.request) {
    // The request was made but no response was received
    console.warn('[apiService] apiErrorReport() request:', _error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('[apiService] apiErrorReport() error message:', _error);
  }
};
