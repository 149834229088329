/* eslint-disable no-console */
// temporarily import store as well
// until I figure out how to better access store values from cascade method
import { getters, store } from '@/store/index.js';
import { getPriceFromSelection } from '@/service/priceCalculator/index.js';

export const formatAsCurrency = _value => {
  let formated =
    !_value || _value === 0
      ? `${0} €`
      : new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: 'currency',
          currency: 'EUR',
        }).format(_value);
  return formated;
};

export const formatAsMeasurementTerm = _value => {
  let formated;
  switch (_value) {
    case 'pc':
      formated = '';
      break;
    case 'sqm':
      formated = ' / m²';
      break;
    case 'm':
      formated = ' / m';
      break;
    default:
      formated = '';
      console.log('[utils] formatAsMeasurementTerm() - error _value is: ', _value);
      break;
  }

  return formated;
};

// eslint-disable-next-line no-unused-vars
export const getRoomSlugBySlotGroupSlug = _slotGroupSlug => {
  let roomSlug;
  const userSelectionProducts = getters.getUserSelectionProducts();

  for (const [key, value] of Object.entries(userSelectionProducts)) {
    for (let i = 0; i < value.length; i++) {
      const roomProduct = value[i];
      if (roomProduct.slotGroupSlug === _slotGroupSlug) {
        roomSlug = key;
        break;
      }
    }
  }

  return roomSlug;
};

export const detectMobileViewPort = _windowInnerWidth => {
  if (_windowInnerWidth < 1024) {
    return true;
  } else {
    return false;
  }
};

export function createIdFromSlug(_slug) {
  return _slug.replace(/(^[\d-]+)-([\w-]+$)/i, '$1');
}

// eslint-disable-next-line no-unused-vars
export function createLookups(structure, selections) {
  // console.log(structure, selections);
  let lookUps = {};

  // get flat version of the slots
  // get room slug by slot slug lookup
  function crawler(roomId, roomSlug, node) {
    const slotId = createIdFromSlug(node.slug);
    if (node.type !== 'room') {
      if (slotSlugLookup[roomSlug] === undefined) slotSlugLookup[roomSlug] = {};
      slotSlugLookup[roomSlug][slotId] = node.slug;
      if (node.referencedSlot !== undefined) {
        // console.log(node.referencedSlot, referenceLookup[node.referencedSlot]);
        if (referenceLookup[node.referencedSlot] === undefined)
          referenceLookup[node.referencedSlot] = [];
        referenceLookup[node.referencedSlot].push(node.slug);
      }
    }
    slotLookup[node.slug] = node;
    roomLookup[node.slug] = roomSlug;

    if (node.children !== undefined) {
      node.children.forEach(node => {
        crawler(roomId, roomSlug, node);
      });
    }
  }

  let slotSlugLookup = {};
  let slotLookup = {};
  let roomLookup = {};
  let referenceLookup = {};
  structure.forEach(node => {
    const roomSlug = node.slug;
    const roomId = roomSlug.replace(/(^\d+)(.+$)/i, '$1');
    crawler(roomId, roomSlug, node);
  });

  // warning: this is probably not reactive
  // but should be ok, since structure slots are static
  lookUps.slotBySlotSlug = slotLookup;
  // the rest is fine
  lookUps.roomSlugBySlotSlug = roomLookup;
  lookUps.slotSlugBySlotId = slotSlugLookup;
  lookUps.referencedSlots = referenceLookup;

  // get slug id lookup
  // Object.keys(slotLookup).forEach(slug => {
  //   const slotId = slug.replace(/(^\d+)(.+$)/i, '$1');
  //   slotSlugLookup[][slotId] = slug;
  // });

  // console.log(lookUps);

  return lookUps;
}

export function calculateAllCascades() {
  // console.log('[calculateAllCascades]');
  // calculate cascades for all rooms
  Object.keys(store.userSelectionData.products).forEach(roomSlug => {
    store.userSelectionData.cascades.forEach(cascade => {
      switch (cascade.type) {
        case 'tilingHeight':
          cascade.targets.forEach(targetId => {
            var totalArea = 0.0;

            // find target slot in selection to write quantity (tilingArea) to.
            var targetSlotSlug = store.lookUps.slotSlugBySlotId[roomSlug]?.[targetId];
            if (!targetSlotSlug) {
              console.error(
                `Property ${targetId} missing in lookup`,
                store.lookUps.slotSlugBySlotId?.[roomSlug]
              );
            }
            var targetSlot = store.userSelectionData.products[roomSlug].find(slot => {
              return slot.slotSlug == targetSlotSlug;
            });

            if (!targetSlot) {
              return;
            }

            // get all source slots where the individual quantities (tilingAreas) are stored
            cascade.triggers.forEach(id => {
              const slotSlug = store.lookUps.slotSlugBySlotId[roomSlug]?.[id];
              if (!slotSlug) {
                console.error(
                  `Property ${id} missing in lookup`,
                  store.lookUps.slotSlugBySlotId?.[roomSlug]
                );
              }

              const selection = store.userSelectionData.products[roomSlug].find(selection => {
                return selection.slotSlug == slotSlug;
              });

              if (selection !== undefined) {
                // clear price from these selections as the cascade is calculated by its target price
                // maybe this can be changed later, when prices for these products are correct
                selection.totalPrice = 0.0;
                // take other selections from store
                // console.log('[cascades]', 'source', roomSlug, slotSlug, selection.area);
                totalArea += selection.area;
              }
            });

            // total area needs to be the actual calculated value plus 1 sqm extra
            totalArea += 0.5;

            targetSlot.quantity = totalArea;
            targetSlot.totalPrice = getPriceFromSelection(targetSlot);
          });

          break;
      }
    });
  });
}

export const createAutoSelections = rooms => {
  const selections = {};
  let roomSlug = '';

  function scanSlots(children, page) {
    children.forEach(child => {
      const productSlotSlugs = [];
      const unselectedSlotSlugs = [];
      let selection;

      switch (child.type) {
        case 'room': {
          roomSlug = child.slug;
          selections[roomSlug] = [];
          break;
        }
        case 'slotPage': {
          page = child;
          break;
        }
        case 'slotGroups': {
          const selectedIndex = 0;
          const selectedGroup = child.children[selectedIndex];

          child.children.forEach((group, index) => {
            if (index === selectedIndex) {
              group.children.forEach(product => {
                //if (product.children && product.children.length > 0) {console.log("product: ",product)} //gets 251-kuechenebreich
                productSlotSlugs.push(product.slug);
              });
            } else {
              group.children.forEach(product => {
                unselectedSlotSlugs.push(product.slug);
              });
            }
          });

          selection = {
            type: child.type,
            slotGroupSlug: selectedGroup.slug,
            slotSlug: child.slug,
            totalPrice: 0.0,
          };

          if (productSlotSlugs.length > 0 || unselectedSlotSlugs.length > 0) {
            selection['productSlotSlugs'] = productSlotSlugs;
            selection['unselectedSlotSlugs'] = unselectedSlotSlugs;
          }

          if (selectedGroup.referencedProductGroup != undefined)
            selection['referencedProductGroup'] = selectedGroup.referencedProductGroup;

          selections[roomSlug].push(selection);
          break;
        }
        case 'slot': {
          selection = {
            type: child.type,
            patternIds: [],
            productIds: [],
            slotPageSlug: page.slug,
            slotSlug: child.slug,
            totalPrice: 0.0,
            quantity: child.quantity,
            referencedSlot: child.referencedSlot,
          };

          let productId, variantId, patternId;

          if (
            ['checkboxes', 'packages'].indexOf(child.style) === -1 &&
            child.productIds !== undefined &&
            child.productIds.length > 0
          ) {
            productId = child.productIds[0];
          }

          if (productId !== undefined) {
            selection.productIds.push(productId);

            const products = getters.getProducts();
            const product = products.find(product => product.id === productId);

            if (product.variantIds !== undefined && product.variantIds.length > 0) {
              variantId = product.variantIds[0];
              selection.productIds.push(variantId);

              const variant = products.find(product => product.id === variantId);

              if (variant.patternIds !== undefined && variant.patternIds.length > 0) {
                patternId = variant.patternIds[0];
                selection.patternIds.push(patternId);
              }
            }
          }

          selections[roomSlug].push(selection);
          break;
        }
      }

      if (child.children !== undefined) {
        scanSlots(child.children, page);
      }
    });
  }

  scanSlots(rooms);
  // rooms.forEach(room => {
  //   const roomSelections = [];

  //   room.children.forEach(child => {
  //     roomSelections.push({
  //       type: child.type,
  //       slotPageSlug: room.slug,
  //       slotSlug: child.slug,
  //     });
  //   });

  //   selections[room.slug] = roomSelections;
  // });

  return selections;
};
