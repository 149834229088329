import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from '@/store/index.js';
import VueGtag from 'vue-gtag';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: 'G-4W24QYQTRG' }, //code for  https://konfigurator.bpd-de.de
    bootstrap: false,
    appName: 'BPD Konfigurator',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
      return {
        page_title: to.name,
        page_path: to.path,
      };
    },
  },
  router
);

Vue.use(VueCookies);

Vue.$cookies.config('90d');

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/assets.css';
import 'sanitize.css/typography.css';
import '@/styles/layout.scss';

new Vue({
  provide() {
    return {
      store,
    };
  },
  router,
  render: h => h(App),
}).$mount('#app');
