<template>
  <div class="home">
    <CookieBanner v-if="cookieConsent === null" />

    <div class="welcome">
      <div class="welcome_logo">
        <div>
          <img class="welcome_logo_company" src="@/assets/images/bpd_logo.png" />
        </div>
        <div>
          <img class="welcome_logo_project" :src="project.logo.url" />
        </div>
      </div>
      <div class="welcome_copy">
        <h1 v-html="startScreenCopy.heading"></h1>
        <p v-html="startScreenCopy.introductionText"></p>
      </div>
      <div class="welcome_nextBtn" @click="onNext">
        <h2 class="welcome_nextBtn_copy">Weiter</h2>
        <div class="welcome_nextBtn_icon" v-html="icons.arrowRight"></div>
      </div>
    </div>

    <div class="imgSlider">
      <div class="imgSlider_logo">
        <div>
          <img class="imgSlider_logo_company" src="@/assets/images/bpd_logo.png" />
        </div>
        <div>
          <img class="imgSlider_logo_project" :src="project.logo.url" />
        </div>
      </div>
      <div class="imgSlider_img" :style="{ backgroundImage: `url(${activeImageUrl})` }"></div>
      <div v-if="imgAmount > 1" class="imgSlider_controls">
        <p class="imgSlider_controls_counter">{{ imgCounter }}<span>/</span>{{ imgAmount }}</p>
        <div class="seperator"></div>
        <div class="arrow prev" @click="onPrevImg" v-html="icons.arrowLeft"></div>
        <div class="arrow next" @click="onNextImg" v-html="icons.arrowRight"></div>
      </div>

      <div class="infoWrapper">
        <ProjectInfo :is-open="true" />
        <router-link
          tag="div"
          :to="{ name: 'Planner', params: { roomSlug: `${rooms[0].slug}` } }"
          class="infoWrapper_nextBtn"
        >
          <div class="infoWrapper_nextBtn_logo">
            <img :src="project.logo.url" />
          </div>

          <div class="infoWrapper_nextBtn_descr">
            <h2>Jetzt starten</h2>
            <div class="welcome_nextBtn_icon" v-html="icons.arrowRight"></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ProjectInfo from '@/components/ProjectInfo_comp.vue';
import CookieBanner from '@/components/CookieBanner_comp.vue';

export default {
  name: 'HomeView',
  inject: ['store'],
  components: {
    ProjectInfo,
    CookieBanner,
  },
  data() {
    return {
      startScreenCopy: this.store.apiData.apartment.pages.startScreen,
      rooms: this.store.apiData.rooms,
      project: this.store.apiData.project,
      projectImages: this.store.apiData.project.images,
      activeImageUrl: this.store.apiData.project.images.length
        ? this.store.apiData.project.images[0].url
        : this.store.apiData.apartment.floorplans[0].url,
      imgCounter: 1,
      icons: {
        arrowLeft: require('!html-loader!@/assets/svgs/arrowLeft.svg'),
        arrowRight: require('!html-loader!@/assets/svgs/arrowRight.svg'),
      },
      cookieConsent: this.$cookies.get('cookieConsent'),
    };
  },
  computed: {
    imgAmount() {
      return this.projectImages.length;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.$nextTick(() => {
      this.onResize();
    });
    if (this.store.apiData.apartment.configuration.isSoftConfigurator) {
      this.$router.push({ name: 'Planner', params: { roomSlug: `${this.rooms[0].slug}` } });
    }
  },
  methods: {
    onPrevImg() {
      let prevImgUrl = null;
      let currentImgIndex = this.projectImages.findIndex(_img => _img.url === this.activeImageUrl);
      const prevImg = this.projectImages[currentImgIndex - 1];

      if (!prevImg) {
        prevImgUrl = this.projectImages[this.imgAmount - 1].url;
        this.imgCounter = this.imgAmount;
      } else {
        prevImgUrl = this.projectImages[currentImgIndex - 1].url;
        this.imgCounter -= 1;
      }

      this.activeImageUrl = prevImgUrl;
    },

    onNextImg() {
      let nextImgUrl = null;
      let currentImgIndex = this.projectImages.findIndex(_img => _img.url === this.activeImageUrl);
      const nextImg = this.projectImages[currentImgIndex + 1];

      if (!nextImg) {
        nextImgUrl = this.projectImages[0].url;
        this.imgCounter = 1;
      } else {
        nextImgUrl = this.projectImages[currentImgIndex + 1].url;
        this.imgCounter += 1;
      }

      this.activeImageUrl = nextImgUrl;
    },

    onNext() {
      const welcomeScreen = document.querySelector('.welcome');
      gsap.to(welcomeScreen, {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          welcomeScreen.style.display = 'none';
        },
      });
    },

    setImgSliderControlsPosition() {
      const imgSlider_controls = document.querySelector('.imgSlider_controls');
      const imgSlider_infoWrapper = document.querySelector('.imgSlider_infoWrapper');
      imgSlider_controls.style.bottom = imgSlider_infoWrapper.offsetHeight + 'px';
    },

    onResize() {
      if (this.imgCounter > 1) {
        this.setImgSliderControlsPosition();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;

  .welcome {
    width: 41%;
    height: 100%;
    color: var(--White);
    background: var(--Charcoal);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    max-width: 480px;

    &_logo {
      display: flex;
      justify-content: space-between;
      padding: 30px 30px 0 30px;

      &_company {
        width: 100px;
      }
      &_project {
        width: 80px;
        display: none;
      }
    }

    &_copy {
      padding: 30px 30px 0 30px;
      margin: auto 0 auto 0;
      h1 {
        margin: 0;
      }
      p {
        color: var(--White);
        line-height: 1.78;
      }
    }

    &_nextBtn {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: var(--BPDgreen1);
      padding: 24px 34px 24px 34px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      &_copy {
        margin: 0;
      }
      &_icon {
        width: 10px;
        margin-left: 10px;
        color: var(--BPDgreen3);
      }
    }
  }

  .imgSlider {
    position: relative;
    width: 59%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
    &_logo {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      padding: 30px 30px 0 30px;
      display: none;
      &_company {
        width: 100px;
      }
      &_project {
        width: 80px;
      }
    }
    &_img {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
    }

    &_controls {
      position: absolute;
      width: 80px;
      height: 180px;
      top: 50%;
      transform: translateY(-50%);
      background: var(--White);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      z-index: 1;
      margin-top: auto;

      &_counter {
        display: flex;
        justify-content: center;
        margin: 0;
        color: var(--BPDgray2);
        span {
          margin: 0 4px 0 4px;
        }
      }

      .seperator {
        border: none;
        border-top: 1px dotted var(--BPDgray3);
        width: 100%;
        height: 0px;
        margin-top: -10px;
      }

      .arrow {
        width: 16px;
        color: var(--BPDgray2);
        cursor: pointer;
        &:last-child {
          color: var(--Asphalt);
        }
      }
    }

    .infoWrapper {
      z-index: 1;
      margin: auto 20px 20px auto;
      &_nextBtn {
        width: 100%;
        margin: 0;
        display: flex;
        &_logo {
          background-color: var(--Charcoal);
          flex-basis: 50%;
          padding: 18px 0 18px 30px;
          img {
            height: 44px;
          }
        }
        &_descr {
          background-color: var(--BPDgreen1);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-basis: 50%;
          padding-right: 34px;
          cursor: pointer;
        }
        h2 {
          margin-right: 20px;
        }
      }
    }
  }
}

@include tabletAndPhone() {
  .home {
    .welcome {
      position: absolute;
      width: 100%;
      max-width: none;
      z-index: 2;
      &_logo_project {
        display: block;
      }
      &_copy {
        max-width: 560px;
        margin: 0 auto;
        margin-top: auto;
      }
      &_nextBtn {
        max-width: 220px;
        display: flex;
        padding: 12px 16px 12px 16px;
        margin: 15px auto auto auto;
      }
    }
    .imgSlider {
      width: 100%;
      &_logo {
        display: flex;
      }
      &_img {
        position: relative;
      }
      &_controls {
        transform: none;
        top: auto;
        width: 120px;
        height: 60px;
        justify-content: center;
        .seperator {
          display: none;
        }
        .arrow {
          position: absolute;
          width: 10px;
          &.prev {
            left: 20px;
          }
          &.next {
            right: 20px;
          }
        }
      }
      .infoWrapper {
        width: 100%;
        .projectInfo {
          width: 100%;
          padding: 12px;
        }
        &_nextBtn {
          padding: 12px 16px 12px 16px;
        }
      }
    }
  }
}
@include tabletAndPhone() {
  .home .imgSlider .infoWrapper {
    margin: 0 auto;
    .projectInfo {
      padding: 0;
    }
    &_nextBtn {
      padding: 0;
      &_logo {
        display: none;
      }
      &_descr {
        flex-basis: 100%;
        justify-content: center;
      }
      h2 {
        margin-right: 0;
      }
    }
  }
}

@include phone() {
  .home .welcome {
    &_copy {
      max-width: 560px;
      margin: 0 auto;
    }
    &_nextBtn {
      max-width: none;
      margin: 0 auto;
    }
  }
}
</style>
