<template>
  <div class="layer">
    <div v-if="enabled">
      <div :class="[error ? 'layer_error' : '', 'layer_render']">
        <img 
          v-if="selectedLayer !== undefined"
          :src="getImage(selectedProduct, selectedLayer)"
          @error="imageLoadError"
        />
      </div>
      <div class="layer_list">
        <ul class="layer_list_table">
          <li v-for="(product, productKey) in store.renderView.fileMap" :key="productKey" class="layer_list_info" @click.stop="selectProduct(productKey)">
            <span class="layer_cell layer_list_info_render">{{ productKey }}</span>
            <span v-if="product.info !== undefined" class="layer_cell layer_list_info_catalogue">
              {{ product.info.id }}
            </span>
            <span v-if="product.info !== undefined" class="layer_cell layer_list_info_type">
              {{ product.info.type }}
            </span>
            <span v-if="product.info !== undefined" class="layer_cell layer_list_info_description">
              {{ product.info.manufacturer }} {{ product.info.name }}
            </span>
            <ul v-if="product.info !== undefined">
              <li v-for="(layer, layerKey) in product" :key="layerKey" class="layer_cell">
                <div
                  v-if="layerKey != 'info' && layerKey != 'influences'"
                  class="layer_list_render"
                  @click.stop="selectLayer(productKey, layerKey)"
                >
                  {{ layerKey }}:{{ getFilename(layer) }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="layer_product">
        <img v-if="product !== undefined && product.thumb !==  undefined" :src="product.thumb.url" />
        <div>RenderID: {{ selectedProduct }} ({{ selectedLayer }})</div>
        <ul v-if="product !== undefined">
          <li>CatalogID: {{ product.catalogId }}</li>
          <li>Title: {{ product.title }}</li>
          <li>Subtitle: {{ product.subtitle }}</li>
          <li>Size: {{ product.size }}</li>
        </ul>
        <ul v-else>
          <li>Not in current apartment</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// const fileMap = this.store.renderView.fileMap;
export default {
  name: 'LayerView',
  inject: ['store'],
  data() {
    return {
      enabled: true, // default state
      selectedProduct: undefined,
      selectedLayer: undefined,
      path: this.store.config.renderingsPath,
      products: this.store.apiData.products,
      product: undefined,
      error: false,
    };
  },
  methods: {
    selectProduct(selectedProduct) {
      console.log('selectProduct', selectedProduct);
      this.selectedProduct = selectedProduct;

      this.product = this.getProduct(selectedProduct);
    },
    selectLayer(selectedProduct, selectedLayer) {
      console.log('selectLayer', selectedProduct, selectedLayer);
      this.selectedProduct = selectedProduct;
      this.selectedLayer = selectedLayer;
      this.error = false;

      this.product = this.getProduct(selectedProduct);
    },
    getImage(selectedProduct, selectedLayer) {
      return this.path + this.store.renderView.fileMap[selectedProduct][selectedLayer] + '.webp';
    },
    imageLoadError(e) {
      console.log('error', e);
      this.error = true;
    },
    getFilename(uri) {
      if (uri)
        return uri.substr(uri.lastIndexOf('/') + 1);
      else
        return '--';
    },
    getProduct(key) {
      const product = this.products.find(_element => {
        return _element.renderingId == key;
      });

      if (product !== undefined) {
        if (product.type === 'variant') {
          const id = product.id;

          const varProduct = this.products.find(_element => {
            return _element.variantIds !== undefined && _element.variantIds.find(element => element == id);
          });

          console.log(product, varProduct);

          return { ...varProduct, size: product.title }
        } else {
          return product;
        }
      }
    }
  },
};
</script>

<style lang="scss">
.layer {
  position: absolute;
  top: 114px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  font-size: 10px;
  background-color: rgba(255, 255, 255, 1.0);

  &_error {
    background-color: #F00;
  }

  &_cell {
    display: table-cell;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 1em;
  }

  &_render {
    height: 1024px;
  }

  &_list {
    position: absolute;
    top: 0;
    left: 0;
    height: 320px;
    overflow-x: clip;
    overflow-y: auto;

    &_table {
      display: table;
    }

    &_info:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &_info {
      display: table-row;
    }
    
    &_render:hover {
      color: rgba(255, 255, 255, 1);
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &_product {
    position: absolute;
    top: 320px;
    left: 0;
    width: 100%;
    font-size: 2em;

    img {
      float: left;
      padding-right: 0.5em;
    }
  }

  &_button {
    border: none;
    background: transparent;
    appearance: none;
  }

  &_rooms {
    padding-left: 12px;
    padding-bottom: 30px;
  }

  &_rooms > ul > li > label {
    font-weight: bold;
  }

  .slot:before {
    content: '-';
    padding: 0 4px;
    font-weight: bold;
  }

  .slotGroups:before {
    content: 'G';
    padding: 0 4px;
    font-weight: bold;
  }

  .slotGroup:before {
    content: 'g';
    padding: 0 4px;
    font-weight: bold;
  }

  .reference:before {
    content: '→';
    padding: 0 4px;
    font-weight: bold;
  }
}
</style>
