<template>
  <div class="notFound">
    <img class="logo_company" src="@/assets/images/bpd_logo.png" />
    <div class="notFound_content">
      <h1 class="notFound_content_headline">404</h1>
      <p class="notFound_content_copy">Die von Ihnen aufgerufene Seite existiert nicht.</p>

      <div class="notFound_content_btn">
        <h4 @click="goBack">Zurück</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',
  methods: {
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('https://www.bpd-immobilienentwicklung.de/');
    },
  },
};
</script>

<style lang="scss" scoped>
.notFound {
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--Charcoal);
  display: flex;
  justify-content: center;
  align-items: center;
  .logo_company {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 100px;
  }
  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_headline {
      margin: 0;
    }
    &_copy {
      margin: 15px 0 0 0;
      color: var(--White);
      text-align: center;
    }
    &_btn {
      width: 100px;
      height: 60px;
      background: var(--BPDgreen1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      cursor: pointer;
      h4 {
        margin: 0;
        color: var(--White);
      }
    }
  }
}
</style>
