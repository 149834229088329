<template>
  <div>
    <div v-if="isActive" class="overlay" @click="onOverlayClick"></div>

    <transition name="fade" mode="out-in">
      <div v-if="isActive" class="modal">
        <div class="icon" @click="close" v-html="icons.cross"></div>

        <div class="content">
          <h3 class="content_title">
            Ihre Meinung ist gefragt
          </h3>
          <p class="content_copy">
            Wir würden uns freuen, wenn sie an unserer kurzen Umfrage teilnehmen würden.
          </p>
          <div class="content_buttons">
            <a :href="surveyUrl" target="_blank" class="button">
              <div class="button_icon" v-html="icons.feedback"></div>
              <h5 class="button_copy">Zur Umfrage</h5>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SurveyModal',
  inject: ['store'],
  data() {
    return {
      isActive: false,
      closeOnOverlayClick: false,
      surveyUrl: this.store.apiData.apartment.project.surveyUrl,
      icons: {
        feedback: require('!html-loader!@/assets/svgs/feedback.svg'),
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
      },
    };
  },
  watch: {
    isActive(to) {
      if (to) {
        setTimeout(() => (this.closeOnOverlayClick = true), 1500);
      } else {
        this.closeOnOverlayClick = false;
      }
    },
  },
  mounted() {
    if (this.surveyUrl) {
      setTimeout(() => (this.isActive = true), 3000);
    }
  },
  methods: {
    close() {
      this.isActive = false;
    },
    onOverlayClick() {
      if (this.closeOnOverlayClick) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(84, 84, 84, 0.6);
}

.modal {
  position: absolute;
  display: table;
  top: 50%;
  left: 0;
  right: 0;
  width: 500px;
  margin: 0 auto;
  transform: translateY(-50%);
  background: var(--BetonLight);
  border-radius: 5px;
  .icon {
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--BPDgray2);
    transition: color 0.25s;
    cursor: pointer;
    @include ishoverSupported {
      &:hover {
        color: var(--Charcoal);
      }
    }
  }
}

.content {
  padding: 30px 30px 30px 30px;
  &_title {
    margin: 0 0 10px 0;
    color: var(--Asphalt);
  }
  &_copy {
    margin: 0;
  }
  &_buttons {
    margin-top: 30px;
  }
}

.button {
  position: relative;
  max-height: 56px;
  background: var(--White);
  padding: 14px 10px 14px 10px;
  border-radius: 5px;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  margin-bottom: 10px;
  border: 2px solid transparent;
  transition: all 0.25s;
  cursor: pointer;
  &_icon {
    width: 24px;
    margin-right: 10px;
    object-fit: none;
  }
  &_copy {
    margin: 0;
  }
  @include ishoverSupported {
    &:hover {
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
