<template>
  <div class="debug">
    <div class="debug_rooms">
      <p>Changed {{ store.userSelectionData.changed }}</p>

      <ul>
        <li v-for="room in store.apiData.rooms" :key="room.slug">
          <label>{{ room.slug }}</label>
          <ul class="debug_pages">
            <li v-for="page in room.children" :key="page.slug">
              <label>{{ page.slug }}</label>
              <ul class="debug_items">
                <DebugTree
                  v-for="node in page.children"
                  :key="node.slug"
                  :node="node"
                  :room-slug="room.slug"
                ></DebugTree>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DebugTree from '@/components/DebugTree.vue';

export default {
  name: 'DebugView',
  inject: ['store'],
  components: {
    DebugTree,
  },
  data() {
    return {
      enabled: false, // default state
    };
  },
  methods: {
    csvDump() {
      const fileMap = this.store.renderView.fileMap;
      let csv = 'Render ID\tType\tManufacturer\tProduct\tSize\tManufacturer ID\tFiles\n';

      for (const [productKey, productValue] of Object.entries(fileMap)) {
        // console.log(productKey);

        csv += `${productKey}\t`;

        if (productValue === true) {
          csv += 'not rendered\t';
        } else {
          let info = '\t';
          let layers = '';

          for (const [slotKey, slotValue] of Object.entries(productValue)) {
            switch (slotKey) {
              case 'info':
                // console.log(slotValue.type, slotValue.manufacturer, slotValue.name, slotValue.id);
                // info = `${slotValue.type}\t${slotValue.manufacturer}\t${slotValue.name}\t${slotValue.size}\t${slotValue.id}\t`;

                info = '';
                info += slotValue.type !== undefined ? slotValue.type + '\t' : '\t';
                info += slotValue.manufacturer !== undefined ? slotValue.manufacturer + '\t' : '\t';
                info += slotValue.name !== undefined ? slotValue.name + '\t' : '\t';
                info += slotValue.size !== undefined ? slotValue.size + '\t' : '\t';
                info += slotValue.id !== undefined ? slotValue.id + '\t' : '\t';
                break;

              default:
                // console.log(slotValue);

                if (slotValue === null) {
                  layers += '\t';
                } else {
                  layers += slotKey + ': ' + slotValue.substring(slotValue.lastIndexOf('/') + 1) + '\t';
                }
                break;
            }
          }

          csv += info + layers;
        }

        csv += '\n';
      }

      console.log(csv);
    }
  }
};
</script>

<style lang="scss">
.debug {
  position: absolute;
  top: 114px;
  left: 0;
  max-width: 640px;
  max-height: calc(100% - 80px);
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: auto;

  &_button {
    border: none;
    background: transparent;
    appearance: none;
  }

  &_rooms {
    padding-left: 12px;
    padding-bottom: 30px;
  }

  &_rooms > ul > li > label {
    font-weight: bold;
  }

  .slot:before {
    content: '-';
    padding: 0 4px;
    font-weight: bold;
  }

  .slotGroups:before {
    content: 'G';
    padding: 0 4px;
    font-weight: bold;
  }

  .slotGroup:before {
    content: 'g';
    padding: 0 4px;
    font-weight: bold;
  }

  .reference:before {
    content: '→';
    padding: 0 4px;
    font-weight: bold;
  }
}
</style>
