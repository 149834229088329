<template>
  <div class="save">
    <div class="icon" v-html="icons.save"></div>
    <p class="copy" v-html="copy"></p>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'SaveComp',
  data() {
    return {
      copy: 'Konfiguration speichern',
      icons: {
        save: require('!html-loader!@/assets/svgs/save.svg'),
      },
    };
  },
  methods: {
    async onStartSaving() {
      return new Promise(_resolve => {
        this.copy = 'Konfiguration speichern';
        document.querySelector('.save .icon').style.width = '18px';
        gsap.to(document.querySelector('.save'), {
          width: '188px',
          duration: 0.5,
          onComplete: _resolve,
        });
      });
    },

    onSavingFinished() {
      this.copy = 'Konfiguration gespeichert';
      gsap.to(document.querySelector('.save .icon'), {
        width: '0',
        duration: 0.25,
        onComplete: () => {
          gsap.to(document.querySelector('.save'), {
            width: '0',
            duration: 0.25,
            delay: 3,
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.save {
  position: absolute;
  top: 110px;
  right: 450px;
  left: auto;
  height: 29px;
  background: var(--White);
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 2px none;
  pointer-events: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 0;
  z-index: 1;
  white-space: nowrap;
  pointer-events: none;
}

@include tabletAndPhone() {
  .save {
    top: 120px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

@media (min-width: 2000px) {
  .save {
    right: calc(20% + 50px);
  }
}

.icon {
  position: relative;
  width: 18px;
  height: 28px;
  left: 6px;
  color: var(--Charcoal);
  flex: 0 0 auto;
  transition: width 0.25s;
  animation: spin 3s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.copy {
  font-size: 0.875em;
  margin-left: 12px;
  width: 0px;
  color: var(--BPDgray2);
}
</style>
