<template>
  <transition name="fade" mode="out-in">
    <ProjectInfo v-if="isActive" :include-floorplan="true" />
  </transition>
</template>

<script>
import ProjectInfo from '@/components/ProjectInfo_comp';
import { mutations } from '@/store';

export default {
  name: 'ProjectInfoModal',
  components: { ProjectInfo },
  inject: ['store'],
  computed: {
    isActive() {
      return this.store.ui.projectInfoIsActive;
    },
  },
  methods: {
    close() {
      mutations.setProjectInfoIsActive(false);
    },
  },
};
</script>
