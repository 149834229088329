<template>
  <ul class="galleryComp">
    <li
      v-for="(image, index) in store.apiData.project.images"
      :key="index"
      :class="['galleryComp_item', galleryStyle]"
    >
      <div v-if="index != 0" class="galleryComp_item_border"></div>
      <img onload="this.style.opacity=1" :src="image.url" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'GalleryComp',
  inject: ['store'],
  data() {
    return {
      projectImages: this.store.apiData.project.images,
    };
  },
  computed: {
    galleryStyle() {
      let className;
      if (this.projectImages.length > 1) {
        className = 'mod-halfscreen';
      } else {
        className = 'mod-fullscreen';
      }

      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
.galleryComp {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  overflow: auto;
  &_item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.25s;
    }
    &_border {
      position: relative;
      height: 5px;
      width: 100%;
      background-color: var(--BetonLight);
    }
    &:first-child {
      .gallery_item_border {
        display: none;
      }
    }
  }
}

.mod-halfscreen {
  height: 50%;
}

.mod-fullscreen {
  width: 100%;
  height: 100%;
}
</style>
