<template>
  <nav class="roomMenu" :class="{ 'mod-fullHeight': mobileNavIsOpen }">
    <ul class="roomMenu_desktop">
      <li
        :class="['roomMenu_desktop_item logo', { hoverStyle: !isSoftConfigurator }]"
        @click="onLogo"
      >
        <img src="@/assets/images/bpd_logo.png" />
      </li>

      <router-link
        v-for="(room, index) in rooms"
        :key="index"
        tag="li"
        :to="{ name: 'Planner', params: { roomSlug: room.slug } }"
        :class="['roomMenu_desktop_item hoverStyle', { 'mod-selected': isRoomSelected(room.slug) }]"
      >
        <h6 class="roomMenu_desktop_item_number">{{ index + 1 }}</h6>
        <h6 class="roomMenu_desktop_item_copy">{{ room.title }}</h6>
      </router-link>
    </ul>

    <div class="roomMenu_desktop_extention"></div>

    <div class="roomMenu_mobile">
      <div class="roomMenu_mobile_header">
        <p class="menu" @click="onHamburger">
          <span class="menu_icon" v-html="icons.house"></span> Räume
        </p>
        <router-link :to="{ name: 'Home' }" class="company_logo" tag="div">
          <img src="@/assets/images/bpd_logo.png" />
        </router-link>
        <div v-if="mobileNavIsOpen" class="roomMenu_mobile_header_open">
          <div class="close_icon" @click="onCross" v-html="icons.cross"></div>
          <h5>Was möchten Sie konfigurieren?</h5>
        </div>
      </div>

      <div class="roomMenu_mobile_background"></div>

      <div v-if="mobileNavIsOpen" class="center">
        <ul class="roomMenu_mobile_list">
          <router-link
            v-for="(room, index) in rooms"
            :key="room.title"
            tag="li"
            :to="{
              name: 'Category',
              params: { roomSlug: room.slug, slotPageSlug: room.children[0].slug },
            }"
            :class="['roomMenu_mobile_list_item', { 'mod-selected': isRoomSelected(room.slug) }]"
          >
            <div v-if="index !== 0" class="seperator"></div>

            <h4 class="roomMenu_mobile_list_item_copy">{{ room.title }}</h4>
          </router-link>
        </ul>

        <div class="roomMenu_mobile_info">
          <div class="roomMenu_mobile_infoItem" @click="onProjectInfo">
            <div class="icon" v-html="icons.wohnflaeche"></div>
            <h6 class="copy">Wohnungsdetails</h6>
          </div>

          <div class="roomMenu_mobile_infoItem" @click="onProjectLegal">
            <div class="icon" v-html="icons.info"></div>
            <h6 class="copy">Musterwohnung</h6>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mutations } from '@/store';

export default {
  name: 'RoomMenu',
  inject: ['store'],
  props: {
    selectedRoomSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rooms: this.store.apiData.rooms,
      isSoftConfigurator: this.store.apiData.apartment.configuration.isSoftConfigurator,
      windowWidth: null,
      mobileNavIsOpen: false,
      icons: {
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
        arrowRight: require('!html-loader!@/assets/svgs/arrowRight.svg'),
        house: require('!html-loader!@/assets/svgs/house.svg'),
        wohnflaeche: require('!html-loader!@/assets/svgs/wohnflaeche.svg'),
        info: require('!html-loader!@/assets/svgs/info.svg'),
      },
    };
  },
  computed: {
    firstRoomSlug() {
      if (!this.rooms.length) return;
      return this.rooms[0].slug;
    },
  },
  watch: {
    selectedRoomSlug() {
      this.mobileNavIsOpen = false;
    },
  },
  methods: {
    isRoomSelected(_slug) {
      if (!this.rooms.length) return false;
      const roomSlug = this.selectedRoomSlug ? this.selectedRoomSlug : this.firstRoomSlug;
      return roomSlug === _slug;
    },

    onHamburger() {
      this.mobileNavIsOpen = true;
    },

    onCross() {
      this.mobileNavIsOpen = false;
    },

    onLogo() {
      if (this.isSoftConfigurator) return;
      this.$router.push({ name: 'Home' });
    },

    onProjectInfo() {
      mutations.setProjectInfoIsActive(true);
    },

    onProjectLegal() {
      mutations.setProjectLegalIsActive(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.roomMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 420px);
  height: 80px;
  // @include hideScrollBar();

  &_desktop_extention {
    position: absolute;
    top: 0;
    right: -420px;
    width: 20px;
    height: 80px;
    background-image: linear-gradient(to bottom, rgba(39, 35, 35, 0.5), rgba(39, 35, 35, 0.1));
  }
  &_desktop {
    display: flex;
    height: 80px;
    background-image: linear-gradient(to bottom, rgba(39, 35, 35, 0.5), rgba(39, 35, 35, 0.1));
    overflow: auto;
    // @include hideScrollBar();
    &_item:not(:first-of-type) {
      box-shadow: inset 2px 0px 0px 0px var(--WhiteTranslucent);
    }
    &_item {
      width: auto;
      min-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      color: var(--White);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 24px 0 24px;
      flex-wrap: wrap;
      transition: background-color 0.25s;
      flex: 1 1 auto;
      font-size: 0.875em;
      &.mod-selected {
        background-color: rgba(84, 84, 84, 0.7);
        pointer-events: none;
      }
      &.logo {
        flex: 0 1 auto;
        min-width: 100px;
        img {
          width: 65px;
          margin: 0 auto;
        }
      }
      &_number {
        @include helper__number;
        margin: 0 0 6px 0;
      }
      &_copy {
        margin: 0;
        letter-spacing: 0.5px;
        color: var(--White);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }

      &::before {
        display: none;
      }
    }
  }
}
.hoverStyle {
  @include ishoverSupported {
    cursor: pointer;
    &:hover {
      background-color: rgba(137, 130, 133, 0.5);
    }
  }
}
.roomMenu_mobile {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  &_header {
    position: absolute;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.4);
    z-index: 1;
    .menu {
      @include NunitoBold;
      display: flex;
      margin-left: 15px;
      font-size: 1em;
      color: var(--White);
      pointer-events: auto;
      cursor: pointer;
      &_icon {
        width: 20px;
        margin-right: 10px;
      }
    }

    .company_logo {
      width: 50px;
      margin-right: 15px;
    }
    &_open {
      background: var(--Asphalt);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;

      .close_icon {
        width: 20px;
        color: var(--BPDgray2);
        margin: auto 10px auto 15px;
        pointer-events: auto;
      }
      h5 {
        margin: 0;
        color: var(--White);
      }
    }
  }
  &_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(to bottom, rgba(39, 35, 35, 0.3), rgba(39, 35, 35, 0));
  }

  .center {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-left: 38px;
    flex-direction: column;
    background: var(--BetonLight);
  }
  &_list {
    position: relative;
    top: 25px; //half-height of roomMenu_mobile_header
    width: 100%;
    margin-top: auto;
    &_item {
      &_copy {
        color: var(--Asphalt);
        margin: 0;
        word-break: initial;
        display: inline-block;
        pointer-events: auto;
      }
      .seperator {
        width: 36px;
        border: none;
        border-top: 2px dotted var(--Asphalt);
        height: 0;
        margin-bottom: 20px;
      }
      &.mod-selected {
        pointer-events: none;
        .roomMenu_mobile_list_item_copy {
          color: var(--BPDgreen1);
        }
      }
    }
  }

  &_info {
    margin: auto 0 auto 0;
  }

  &_infoItem {
    height: 38px;
    background: var(--White);
    border-radius: 5px;
    pointer-events: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 186px;
    white-space: nowrap;
    cursor: pointer;

    .icon {
      position: relative;
      width: 28px;
      left: 6px;
      color: var(--Charcoal);
      flex: 0 0 auto;
    }

    .copy {
      margin-left: 12px;
      color: var(--BPDgray2);
    }
  }

  &_infoItem + &_infoItem {
    margin-top: 15px;
  }

  .projectInfo {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.mod-fullHeight {
  height: 100%;
}

@include tabletAndPhone {
  .roomMenu {
    z-index: 5;
    width: 100%;
    &_desktop {
      display: none;
    }
    &_desktop_extention {
      display: none;
    }
  }
  .roomMenu_mobile {
    display: block;
  }
}
</style>
