<template>
  <section v-if="products.length > 0" class="product">
    <div class="borderPadding">
      <div v-if="slotItem.showHeader" class="product_header">
        <h4 v-if="slotItem.title !== null" class="product_title">
          {{ slotItem.title }}
        </h4>
        <p v-if="slotItem.instructions !== null" class="product_instructions">
          {{ slotItem.instructions }}
        </p>
      </div>

      <div class="product_viewer">
        <div :class="['product_viewer_frame', getAspectRatioClass(slotItem)]">
          <div
            v-if="products[selectedProductIndex].image != null"
            class="product_viewer_image"
            :style="{ backgroundImage: 'url(' + products[selectedProductIndex].image.url + ')' }"
          />
          <div v-if="!hidePrices && !products[selectedProductIndex].noPriceCalc" class="priceTage">
            <p v-if="isVariableSlot">
              ab {{ formatAsCurrency(products[selectedProductIndex].minUnitPrice) }}
              {{ formatAsMeasurementTerm(slotItem.unitOfMeasure) }}
            </p>
            <p v-else>
              {{ formatAsCurrency(products[selectedProductIndex].unitPrice) }}
              {{ formatAsMeasurementTerm(slotItem.unitOfMeasure) }}
            </p>
          </div>
          <div v-if="selectedVariantOrProduct.noRendering" class="noRenderingTag">
            <span class="noRenderingTag_tag">Abweichende Produktvisualisierung</span>
          </div>
        </div>
        <div class="copy">
          <p class="copy_title mod-cutToOneLine">{{ products[selectedProductIndex].title }}</p>
          <p class="copy_subtitle mod-cutToTwoLine">
            {{ products[selectedProductIndex].subtitle }}
          </p>
          <p v-if="!hideCatalogId || isDev" class="copy_id">
            Bestell-Nr.:
            {{ selectedVariantOrProduct.catalogId }}
          </p>
          <p v-if="isDev" class="copy_id">
            RenderID..:
            {{ selectedVariantOrProduct.renderingId }}<br />
            Slug:
            {{ slotItem.slug }} ({{ selectedProductIndex }})<br />
            UUID:
            {{ selectedVariantOrProduct.id.replace(/^v_/, '') }}<br />
          </p>
        </div>
      </div>

      <div :class="['product_list', 'js-slotIndex-' + slotIndex + '']">
        <div
          v-for="(product, index) in products"
          :key="index"
          :class="['thumbnail', { thumbnail_active: selectedProductIndex == index }]"
          :data-slotIndex="slotIndex"
          @click="onProduct(index, product)"
        >
          <div class="thumbnailIcon" v-html="icons.checkCircle"></div>
          <img
            v-if="product.image != null"
            onload="this.style.opacity=1"
            class="thumbnailImg"
            :src="product.thumb.url"
          />
          <div class="thumbnailDropShadow"></div>
        </div>
      </div>
      <Hint v-if="includeHint && slotItem.tip" :content="slotItem.tip" />
    </div>
    <div v-if="hasSeperatorBar" class="separatorBar"></div>
  </section>
</template>

<script>
/* eslint-disable no-console */
import { formatAsCurrency, formatAsMeasurementTerm } from '../utils/index.js';
import { checkForConflictingProducts } from '../service/conflictChecker/index.js';
import { store, getters, mutations } from '@/store/index.js';
import { getPriceFromProduct } from '../service/priceCalculator/index.js';

import Hint from '@/components/Hint_comp.vue';

export default {
  name: 'ProductComp',
  components: {
    Hint,
  },
  props: {
    slotPage: {
      type: Object,
      default() {
        return { children: [] };
      },
    },
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    slotIndex: {
      type: Number,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    includeHint: {
      type: Boolean,
      default: true,
    },
    showSeparator: {
      type: Boolean,
      default: true,
    },
    selectedVariant: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDev: store.config.environment === 'dev',
      selectedProductIndex: 0,
      icons: {
        checkCircle: require('!html-loader!@/assets/svgs/checkCircle.svg'),
      },
    };
  },
  computed: {
    selectedVariantOrProduct() {
      return this.selectedVariant || this.products[this.selectedProductIndex];
    },
    isVariableSlot() {
      return this.products[this.selectedProductIndex].type === 'variable';
    },
    aspectRatioClass() {
      return 'dark-theme';
    },
    hasSeperatorBar() {
      return this.showSeparator && this.slotIndex < this.slotPage.children.length - 1;
    },
    hidePrices() {
      return store.apiData.project.hidePrices;
    },
    hideCatalogId() {
      return store.apiData.project.hideCatalogId;
    },
  },
  mounted() {
    const selection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);
    //If the seelction is empy(happens on switching comp. e.g. from aprjett to fliesen if parkett was predefined).
    //we use the first product as default/reset.
    if (selection) {
      this.products.forEach((_element, _index) => {
        if (_element.id == selection.productIds[0]) {
          this.selectedProductIndex = _index;
        }
      });
    } else {
      this.onProduct(0, this.products[0]);
    }
  },
  methods: {
    formatAsCurrency,
    formatAsMeasurementTerm,

    getAspectRatioClass(_slotItem) {
      let className;
      if (_slotItem.previewImageRatio === 0.5625) {
        className = 'landscapeImg';
      } else {
        className = 'squareImg';
      }
      return className;
    },

    highlightSelectedProduct(_event) {
      const productList = document.querySelector(`.product_list.js-slotIndex-${this.slotIndex}`);
      const selectedProduct = productList.querySelector('.product_list_item.selected');
      if (selectedProduct) {
        selectedProduct.classList.remove('selected');
      }
      _event.target.classList.add('selected');
    },

    onProduct(_index, _product) {
      let conflictingProducts;
      //if all products gor an atterinute key with at least one entry mo meed for chekcing length = talk to chrsitian
      if (Object.keys(_product.attributes).length !== 0) {
        conflictingProducts = checkForConflictingProducts(
          this.$route.params.roomSlug,
          this.slotSlug,
          _product.attributes
        );
        if (conflictingProducts && conflictingProducts.length) {
          this.$emit(
            'on-conflicting-products',
            conflictingProducts,
            () => {
              this.addProduct(
                _index,
                _product,
                this.slotSlug,
                this.slotPageSlug,
                this.isVariableSlot,
                this.slotItem,
                this.$route.params.roomSlug
              );
            },
            (_index, _product, _slotSlug, _slotPageSlug, _isVariableSlot, _slotItem, _roomSlug) => {
              this.addProduct(
                _index,
                _product,
                _slotSlug,
                _slotPageSlug,
                _isVariableSlot,
                _slotItem,
                _roomSlug
              );
            }
          );
        } else {
          this.addProduct(
            _index,
            _product,
            this.slotSlug,
            this.slotPageSlug,
            this.isVariableSlot,
            this.slotItem,
            this.$route.params.roomSlug
          );
        }
      } else {
        this.addProduct(
          _index,
          _product,
          this.slotSlug,
          this.slotPageSlug,
          this.isVariableSlot,
          this.slotItem,
          this.$route.params.roomSlug
        );
      }
    },

    addSelectedProduct(
      _index,
      _product,
      _slotSlug,
      _slotPageSlug,
      _isVariableSlot,
      _slotItem,
      _roomSlug
    ) {
      this.addProduct(
        _index,
        _product,
        _slotSlug,
        _slotPageSlug,
        _isVariableSlot,
        _slotItem,
        _roomSlug
      );
    },

    addProduct(_index, _product, _slotSlug, _slotPageSlug, _isVariableSlot, _slotItem, _roomSlug) {
      const hasVariants = _product.type == 'variable';
      const defaultVariant = hasVariants
        ? store.apiData.products.find(_element => {
            return _element.id == _product.variantIds[0];
          })
        : null;

      const hasPatterns = hasVariants && defaultVariant.patternIds.length;

      const defaultPattern = hasPatterns
        ? store.apiData.products.find(_element => {
            return _element.id == defaultVariant.patternIds[0];
          })
        : null;
      console.log(
        'addProduct() _product.attributes: ',
        _product.slotSlug + ' is: ',
        _product.attributes.wallMountedArmature
      );

      const selection = {
        attributes: _product.attributes,
        slotSlug: _slotSlug,
        slotPageSlug: _slotPageSlug,
        productIds: defaultVariant ? [_product.id, defaultVariant.id] : [_product.id],
        patternIds: defaultPattern ? [defaultPattern.id] : [],
        //totalPrice: this.isVariableSlot ? _product.minUnitPrice : _product.unitPrice,
        // totalPrice: getPriceByProduct(_product, _isVariableSlot, defaultVariant, _slotItem),
        totalPrice: getPriceFromProduct(
          defaultVariant !== null ? defaultVariant : _product, // not sure if this is correct
          _slotItem
        ),
      };

      mutations.addSelection(_roomSlug, _slotSlug, selection);
      //if else for testing right now
      if (_index === null || _index === undefined) {
        console.log(
          '[Products_comp] addSelectedProduct() index for selectedProductIndex is: ',
          _index
        );
      } else {
        this.selectedProductIndex = _index;
      }

      this.$emit(
        'on-product-selected',
        _product,
        defaultVariant,
        defaultPattern ? defaultPattern.id : null
      );

      const room = store.apiData.rooms.find(_room => _room.slug === _roomSlug);

      const gtagTrackingAttributes = {
        room: store.apiData.rooms.find(_room => _room.slug === _roomSlug).title,
        category: room.children.find(_child => _child.slug === _slotPageSlug).title,
        product: _product.title,
        productVariant: defaultVariant ? defaultVariant.title : 'N/A',
        productPattern: defaultPattern ? defaultPattern.title : 'N/A',
      };

      this.$gtag.event('productClick', {
        event_category: `Room: ${gtagTrackingAttributes.room}`,
        event_label: `Category: ${gtagTrackingAttributes.category} in Room: ${gtagTrackingAttributes.room}`,
        value: `Product: ${gtagTrackingAttributes.product} - Variant: ${gtagTrackingAttributes.productVariant} - Pattern:  ${gtagTrackingAttributes.productPattern}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  &_header {
    margin-bottom: 12px;
  }
  &_title {
    margin: 0;
  }
  &_instructions {
    color: var(--BPDgray1);
    margin-top: 8px;
    margin-bottom: 0;
  }

  &_viewer {
    .copy {
      //min-height: 60px;
      height: fit-content;
      &_title {
        color: var(--Asphalt);
        font-weight: 600;
        font-size: 0.875em;
        margin-bottom: 0;
      }
      &_subtitle {
        color: var(--Asphalt);
        font-size: 0.875em;
        margin: 0 auto 0 auto;
        font-weight: normal;
        overflow: hidden;
      }
      &_id {
        color: var(--BPDgray1);
        font-size: 0.875em;
        margin: 0 auto 0 auto;
        font-weight: normal;
        overflow: hidden;
      }
    }

    &_frame {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
    }

    &_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--White);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      transition: background-image 0.25s 0.25s; // TODO make img transiton  here or go js/gsap?
    }
  }
  &_list {
    display: flex;
    flex-flow: row wrap;
    padding: 12px 0 0 0;
    &_item {
      &:last-child {
        margin-right: 0;
      }
      &.selected {
        pointer-events: none;
        .thumbnailIcon {
          opacity: 1;
        }
      }
    }
  }
}

.priceTage {
  position: absolute;
  top: 6px;
  right: 9px;
}

.noRenderingTag {
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &_tag {
    padding: 1px 10px;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(39, 35, 35, 0.1);
    background-color: var(--White);
    margin: 0;
    font-size: 0.75em;
    color: var(--Asphalt);
  }
}
</style>
