<template>
  <div :class="['productList', 'productListRoomSlug_' + room.slug]">
    <h3 class="productList_name">{{ room.title }}</h3>

    <div v-for="slotPage in room.children" :key="slotPage.title">
      <div class="productList_header">
        <h4 class="copy">{{ slotPage.title }}</h4>
        <div v-if="!hidePrices" class="price">
          <div class="price_copy" @click="onEdit(slotPage.slug)">
            <div class="price_copy_editIcon " v-html="icons.pencil"></div>
            <p>Total</p>
          </div>
          <h4>{{ formatAsCurrency(getSlotPagePrice(room.slug, slotPage.slug)) }}</h4>
        </div>
      </div>

      <div v-for="slot in slotPage.children" :key="slot.title" class="productList_item">
        <SlotSwitchList
          v-if="slot.type === 'slotGroups'"
          :room-slug="room.slug"
          :slot-page-slug="slotPage.slug"
          :slot-slug="slot.slug"
          :slot-item="slot"
        />

        <SlotProductList
          v-if="slot.style === 'default' || slot.style === 'variable'"
          :room-slug="room.slug"
          :slot-page-slug="slotPage.slug"
          :slot-slug="slot.slug"
          :slot-style="slot.style"
          :slot-item="slot"
        />

        <SlotCheckboxList
          v-if="slot.style === 'checkboxes' || slot.style === 'packages'"
          :room-slug="room.slug"
          :slot-item="slot"
          :slot-slug="slot.slug"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { store, getters } from '@/store/index.js';
import { formatAsCurrency } from '../utils/index.js';
import SlotProductList from '@/components/SlotProductList_comp.vue';
import SlotCheckboxList from '@/components/SlotCheckboxList_comp.vue';
import SlotSwitchList from '@/components/SlotSwitchList_comp.vue';

export default {
  name: 'ProductList',
  components: {
    SlotProductList,
    SlotCheckboxList,
    SlotSwitchList,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        pencil: require('!html-loader!@/assets/svgs/pencil.svg'),
      },
    };
  },
  computed: {
    hidePrices() {
      return store.apiData.project.hidePrices;
    },
  },
  mounted() {
    this.hideCheckboxPackageIfEmpty();
  },
  methods: {
    formatAsCurrency,
    hideCheckboxPackageIfEmpty() {
      let roomProducts = getters.getUserSelectionProductsByRoom(
        '336-elektroausstattung-und-servicepakete-336'
      );
      if (!roomProducts) {
        return;
      }
      let hasProduct = false;
      for (let i = 0; i < roomProducts.length; i++) {
        const roomProduct = roomProducts[i];
        if (roomProduct.productIds.length > 0) {
          hasProduct = true;
          break;
        }
      }

      if (!hasProduct) {
        document.querySelector(
          '.productListRoomSlug_336-elektroausstattung-und-servicepakete-336'
        ).style.display = 'none';
      }
    },

    getSlotPagePrice(_roomSlug, _slotPageSlug) {
      let slotPagePrice = 0;
      const roomProducts = getters.getUserSelectionProductsByRoom(_roomSlug);

      //Get the unselected slotslugs within that room.
      //First Filter all producst in the room if they have an non empty unselectedSlotSlugs[]
      //Then just return that unselectedSlotSlugs[] into another array created by filter
      //Then flatten that array to get an array of strings with the unselectedSlotSlugIds
      const unselectedSlotSlugs = roomProducts
        .filter(_roomProduct => {
          return _roomProduct.unselectedSlotSlugs && _roomProduct.unselectedSlotSlugs.length > 0;
        })
        .map(_roomProduct => _roomProduct.unselectedSlotSlugs)
        .flat();

      //console.log('unselectedSlotSlugs: ', unselectedSlotSlugs);

      //Filter all products within that room that have that slotPageSlug and
      //are not included in the unselectedSlotSlugs[].
      //Than only return their price
      const slotPageProductsPrice = roomProducts
        .filter(_roomProduct => {
          return (
            _roomProduct.slotPageSlug === _slotPageSlug &&
            !unselectedSlotSlugs.includes(_roomProduct.slotSlug)
          );
        })
        .map(_roomProduct => _roomProduct.totalPrice - _roomProduct.basePrice);

      slotPageProductsPrice.forEach(_productPrice => {
        slotPagePrice += _productPrice;
      });

      return slotPagePrice;
    },

    onEdit(_slotPageSlug) {
      this.$router.push({
        name: 'Category',
        params: { roomSlug: this.room.slug, slotPageSlug: _slotPageSlug },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.productList {
  padding: 20px 30px 20px 30px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: var(--White);

  &_name {
    margin: 0;
    padding-bottom: 20px;
    border-bottom: 8px solid var(--BetonLight);
  }
  &_header {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    .copy {
      margin: 0;
      flex-grow: 1;
      color: var(--Asphalt);
      flex: 1 1 auto;
    }
    .price {
      display: flex;
      flex-direction: column;
      text-align: right;
      flex: 1 0 auto;

      &_copy {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        cursor: pointer;
        &_editIcon {
          width: 18px;
          height: 24px;
          margin-right: 10px;
        }
        p {
          margin: 0;
          color: var(--Asphalt);
          font-size: 0.875em;
          font-weight: 600;
        }
      }

      h4 {
        margin: 0;
        color: var(--Asphalt);
      }
    }
  }
  &_item {
    display: flex;
    align-items: center;
    &:last-child {
      padding-bottom: 20px;
      border-bottom: 8px solid var(--BetonLight);
    }
  }
}

.border {
  margin: 10px auto 10px auto;
}
</style>
