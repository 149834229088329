<template>
  <div class="success">
    <div class="success_content">
      <div class="center">
        <h1 v-html="heading"></h1>
        <p v-html="introductionText"></p>
        <div v-if="isSubmitted">
          <div class="center_seperator"></div>
          <div class="center_button" @click="onPDF">
            <div class="center_button_icon" v-html="icons.download"></div>
            <h5 class="center_button_copy">PDF herunterladen</h5>
            <div v-if="pdfIsLoading" class="center_button_loader">
              <div v-html="icons.spinner"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cta" @click="onProject">
        <h2>{{ ctaLabel }}</h2>
      </div>
    </div>
    <div class="success_imagery">
      <img onload="this.style.opacity=1" :src="image.url" />
    </div>
    <SurveyModal v-if="isSubmitted" />
  </div>
</template>

<script>
import { generateConfigurationPdf, apiErrorReport } from '@/service/apiService';
import SurveyModal from '@/components/SurveyModal_comp';

export default {
  name: 'SuccessView',
  inject: ['store'],
  components: {
    SurveyModal,
  },
  data() {
    return {
      rooms: this.store.apiData.rooms,
      heading: '',
      introductionText: '',
      isSubmitted: true,
      ctaUrl:
        this.store.apiData.apartment.project.projectWebsiteUrl ||
        'https://www.bpd-immobilienentwicklung.de/',
      ctaLabel: this.store.apiData.apartment.project.projectWebsiteUrl
        ? 'Zur Projektwebsite'
        : 'Zur BPD Website',
      pdfIsLoading: false,
      image: this.store.apiData.apartment.pages.endScreen.image,
      icons: {
        arrowLeft: require('!html-loader!@/assets/svgs/arrowLeft.svg'),
        download: require('!html-loader!@/assets/svgs/download.svg'),
        spinner: require('!html-loader!@/assets/svgs/spinner.svg'),
      },
    };
  },
  mounted() {
    const configuration = this.store.apiData.apartment.configuration;

    if (configuration.isSoftConfigurator) {
      this.$router.push({ name: 'Planner', params: { roomSlug: `${this.rooms[0].slug}` } });
    }

    if (configuration.isSubmitted) {
      this.heading = this.store.apiData.apartment.pages.errorScreen.alreadySubmittedHeading;
      this.introductionText = this.store.apiData.apartment.pages.errorScreen.alreadySubmittedIntroductionText;
    } else if (configuration.hasExpired) {
      this.heading = this.store.apiData.apartment.pages.errorScreen.expiredHeading;
      this.introductionText = this.store.apiData.apartment.pages.errorScreen.expiredIntroductionText;
      this.isSubmitted = false;
    } else {
      this.heading = this.store.apiData.apartment.pages.endScreen.heading;
      this.introductionText = this.store.apiData.apartment.pages.endScreen.introductionText;
    }
  },
  methods: {
    onProject() {
      window.location.href = this.ctaUrl;
    },

    async onPDF() {
      this.pdfIsLoading = true;
      await generateConfigurationPdf(this.$route.params.bpdId, this.store.userSelectionData, true)
        .then(_response => {
          window.open(_response.data.url, '_blank');
        })
        .catch(_error => {
          apiErrorReport(_error);
        })
        .finally(() => {
          this.pdfIsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 1;
  background: var(--Charcoal);

  &_content {
    width: 100%;
    min-width: 480px;
    max-width: 480px;
    background-color: var(--Charcoal);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    // @include hideScrollBar();

    .center {
      display: flex;
      flex-direction: column;
      padding: 30px 30px 0 30px;
      margin-top: auto;
      h1 {
        margin: 0;
        background-color: var(--Charcoal);
        span {
          color: var(--BPDgreen2);
        }
      }
      p {
        letter-spacing: normal;
        color: var(--White);
      }
      &_seperator {
        width: 100%;
        border-bottom: 1px dotted var(--BPDgray3);
        margin: 32px 0 40px 0;
      }
      &_button {
        position: relative;
        align-self: baseline;
        max-height: 56px;
        background: var(--White);
        padding: 14px 10px 14px 10px;
        border-radius: 5px;
        display: inline-block;
        margin-bottom: 10px;
        border: 2px solid transparent;
        transition: all 0.25s;
        cursor: pointer;
        &_icon {
          width: 24px;
          color: var(--BPDgreen1);
          margin-right: 10px;
          object-fit: none;
          float: left;
        }
        &_copy {
          margin: 4px 0 0 0;
          float: left;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        @include ishoverSupported {
          &:hover {
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
            transform: translateX(10px);
          }
        }
        &_loader {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding: 14px 10px 14px 10px;
          background: rgba(255, 255, 255, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          cursor: default;
        }
      }
    }

    .cta {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80px;
      background-color: var(--BPDgreen1);
      text-align: center;
      margin-top: auto;
      cursor: pointer;
    }
  }

  .success_imagery {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: 0.25s;
    }
  }
}

@include tabletAndPhone() {
  .success_content {
    max-width: none;
    min-width: auto;
  }
  .success_imagery {
    display: none;
  }
}

@include phone() {
  .success_content .center_button {
    align-self: auto;
    max-width: 360px;
  }
}
</style>
