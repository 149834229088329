<template>
  <section>
    <Products
      :slot-page="slotPage"
      :slot-page-slug="slotPageSlug"
      :slot-item="slotItem"
      :slot-index="slotIndex"
      :slot-slug="slotSlug"
      :products="products"
      :include-hint="false"
      :show-separator="false"
      :selected-variant="selectedVariant"
      @on-product-selected="onProductSelected"
      @on-conflicting-products="onConflictingProducts"
    />

    <ProductVariants
      v-if="selectedProduct && selectedProduct"
      :slot-page-slug="slotPageSlug"
      :slot-item="slotItem"
      :slot-slug="slotSlug"
      :selected-product="selectedProduct"
      @on-variant-selected="onVariantSelected"
    />

    <ProductPatterns
      v-if="selectedProduct && selectedVariant && selectedVariant.patternIds.length"
      :slot-item="slotItem"
      :slot-slug="slotSlug"
      :selected-product="selectedProduct"
      :selected-variant="selectedVariant"
      @on-pattern-selected="onPatternSelected"
    />

    <Hint v-if="slotItem.tip" :content="slotItem.tip" />

    <div v-if="slotIndex < slotPage.children.length - 1" class="separatorBar"></div>
  </section>
</template>

<script>
import { formatAsCurrency } from '../utils/index.js';
import { store, getters } from '@/store/index.js';
import Hint from '@/components/Hint_comp.vue';
import Products from '@/components/Products_comp.vue';
import ProductPatterns from '@/components/ProductPatterns_comp.vue';
import ProductVariants from '@/components/ProductVariants_comp.vue';

export default {
  name: 'ProductVariableComp',
  components: {
    Hint,
    Products,
    ProductPatterns,
    ProductVariants,
  },
  props: {
    slotPage: {
      type: Object,
      required: true,
    },
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    slotIndex: {
      type: Number,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedProduct: null,
      selectedVariant: null,
      selectedPattern: null,
      icons: {
        checkCircle: require('!html-loader!@/assets/svgs/checkCircle.svg'),
      },
    };
  },
  mounted() {
    //Right now due data import the ids of prodfucts doesn tmatch with the store, so need to wait
    //on mounted throws error
    const selection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);
    let selectedProductId = selection.productIds[0] ? selection.productIds[0] : null;

    let selectedVariantId = selection.productIds[1] ? selection.productIds[1] : null;
    let selectedPatternId = selection.patternIds.length ? selection.patternIds[0] : null;

    this.selectedProduct = this.products.find(_product => _product.id == selectedProductId);
    this.selectedVariant = getters.getProducts().find(_product => _product.id == selectedVariantId);
    this.selectedPattern = getters.getProducts().find(_product => _product.id == selectedPatternId);
  },
  methods: {
    formatAsCurrency,

    getVariant(_product) {
      const array = [];
      _product.variantIds.forEach(_variantId => {
        array.push(store.apiData.products.find(_product => _product.id === _variantId));
      });

      return array;
    },

    onProductSelected(_product, _variant, _pattern) {
      this.selectedProduct = _product;
      this.selectedVariant = _variant;
      this.selectedPattern = _pattern;
    },

    onVariantSelected(_variant) {
      this.selectedVariant = _variant;
    },

    onPatternSelected(_pattern) {
      this.selectedPattern = _pattern;
    },

    onConflictingProducts(_products, _addSelectedProductCB, _addProductCB) {
      this.$emit('on-conflicting-products', _products, _addSelectedProductCB, _addProductCB);
    },
  },
};
</script>

<style lang="scss" scoped></style>
