<template>
  <transition name="fade" mode="out-in">
    <div v-if="isActive" class="projectLegal">
      <div class="icon" @click="close" v-html="icons.cross"></div>

      <div class="content">
        <h6 class="content_title" v-html="infoTexts.visualizationNoteHeading"></h6>
        <p class="content_copy" v-html="infoTexts.visualizationNoteText"></p>
        <p class="content_link">
          <a href="https://www.bpd-immobilienentwicklung.de/allgemeines/impressum" target="_blank"
            >Impressum</a
          ><br />
          <a
            href="https://www.bpd-immobilienentwicklung.de/allgemeines/impressum/website-disclaimer"
            target="_blank"
            >Website Disclaimer</a
          ><br />
          <a href="https://www.bpd-immobilienentwicklung.de/allgemeines/datenschutz" target="_blank"
            >Datenschutz</a
          >
        </p>
      </div>

      <div class="triangle"></div>
    </div>
  </transition>
</template>

<script>
import { mutations } from '@/store/index.js';

export default {
  name: 'ProjectLegalModal',
  inject: ['store'],
  data() {
    return {
      infoTexts: this.store.apiData.apartment.pages.infoTexts,
      icons: {
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
      },
    };
  },
  computed: {
    isActive() {
      return this.store.ui.projectLegalIsActive;
    },
  },
  methods: {
    close() {
      mutations.setProjectLegalIsActive(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.projectLegal {
  position: relative;
  top: 0;
  right: 0;
  width: 500px;
  background: var(--White);
  pointer-events: auto;
  border-radius: 5px;
  .icon {
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--BPDgray2);
    transition: color 0.25s;
    pointer-events: auto;
    cursor: pointer;
    @include ishoverSupported {
      &:hover {
        color: var(--Charcoal);
      }
    }
  }
}
.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 30px 30px 30px;
  margin: 0;
  &_title {
    margin: 0 0 10px 0;
    color: var(--BPDgray2);
  }

  &_copy {
    margin: 0;
  }

  &_link {
    margin: 10px 0 0 0;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid var(--White);
  position: absolute;
  bottom: -12px;
  left: 68px;
}

@include tabletAndPhone {
  .triangle {
    display: none;
  }
}
</style>
