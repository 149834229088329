<template>
  <div class="roomMenuBtn">
    <div :class="['roomMenuBtn_prev', { 'mod-isHidden': !prevRoomExist }]" @click="onPrevRoom">
      <div class="icon" v-html="icons.arrowLeft"></div>
    </div>
    <div :class="['roomMenuBtn_divider', { 'mod-isHidden': !prevRoomExist }]"></div>
    <div class="roomMenuBtn_descr" @click="onNextRoom">
      <h5 v-if="isLastRoom" class="roomMenuBtn_descr_copy">{{ summaryButtonLabel }}</h5>
      <h5 v-else class="roomMenuBtn_descr_copy">
        {{ nextRoom.buttonLabel || `Zum ${nextRoom.title}` }}
      </h5>
    </div>
    <div class="roomMenuBtn_next" @click="onNextRoom">
      <div class="icon" v-html="icons.arrowRight"></div>
    </div>
  </div>
</template>

<script>
import { store, getters } from '@/store/index.js';
import { detectMobileViewPort } from '@/utils';

export default {
  name: 'RoomMenuBtn',
  inject: ['store'],
  props: {
    selectedRoomSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rooms: this.store.apiData.rooms,
      windowWidth: null,
      mobileNavIsOpen: false,
      summaryButtonLabel: this.store.apiData.apartment.pages.summaryScreen.buttonLabel,
      icons: {
        arrowRight: require('!html-loader!@/assets/svgs/arrowRight.svg'),
        arrowLeft: require('!html-loader!@/assets/svgs/arrowLeft.svg'),
      },
    };
  },
  computed: {
    room() {
      return getters.getRoomBySlug(this.selectedRoomSlug);
    },

    prevRoomExist() {
      let prevRoomExist = false;
      const roomindex = store.apiData.rooms.findIndex(
        _room => _room.slug === this.selectedRoomSlug
      );
      if (roomindex > 0) {
        prevRoomExist = true;
      }
      return prevRoomExist;
    },

    isLastRoom() {
      let isLastRoom = false;
      const roomindex = store.apiData.rooms.findIndex(
        _room => _room.slug === this.selectedRoomSlug
      );
      if (roomindex === store.apiData.rooms.length - 1) {
        isLastRoom = true;
      }
      return isLastRoom;
    },

    prevRoom() {
      let prevRoom = null;
      let currentRoomStoreIndex = store.apiData.rooms.findIndex(
        _room => _room.slug === this.selectedRoomSlug
      );
      const prevRoomStoreIndex = currentRoomStoreIndex - 1;
      if (!store.apiData.rooms[prevRoomStoreIndex]) {
        prevRoom = store.apiData.rooms[0];
      } else {
        prevRoom = store.apiData.rooms[prevRoomStoreIndex];
      }
      return prevRoom;
    },

    nextRoom() {
      let nextRoom = null;
      let currentRoomStoreIndex = store.apiData.rooms.findIndex(
        _room => _room.slug === this.selectedRoomSlug
      );
      const nextRoomStoreIndex = currentRoomStoreIndex + 1;
      if (!store.apiData.rooms[nextRoomStoreIndex]) {
        nextRoom = store.apiData.rooms[0];
      } else {
        nextRoom = store.apiData.rooms[nextRoomStoreIndex];
      }
      return nextRoom;
    },
  },

  methods: {
    onPrevRoom() {
      if (detectMobileViewPort(window.innerWidth)) {
        this.$router.push({
          name: 'Category',
          params: { roomSlug: this.prevRoom.slug, slotPageSlug: this.prevRoom.children[0].slug },
        });
      } else {
        this.$router.push({
          name: 'Planner',
          params: { roomSlug: this.prevRoom.slug },
        });
      }
    },
    onNextRoom() {
      if (this.isLastRoom) {
        this.$router.push({
          name: 'Summary',
          params: { roomSlug: this.selectedRoomSlug },
        });
      } else {
        if (detectMobileViewPort(window.innerWidth)) {
          this.$router.push({
            name: 'Category',
            params: { roomSlug: this.nextRoom.slug, slotPageSlug: this.nextRoom.children[0].slug },
          });
        } else {
          this.$router.push({
            name: 'Planner',
            params: { roomSlug: this.nextRoom.slug },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.roomMenuBtn {
  position: relative;
  width: 100%;
  height: 70px;
  background: var(--Asphalt);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 16px 0 16px 0;
  margin: auto auto 10px auto;
  border-radius: 5px;
  transition: all 0.25s;
  cursor: pointer;
  &_prev {
    position: relative;
    left: 0;
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //box-shadow: inset -2px 0px 0px 0px var(--WhiteTranslucent);
    @include ishoverSupported {
      &:hover {
        .icon {
          color: var(--White);
        }
      }
    }
  }
  &_divider {
    width: 2px;
    height: 70px;
    background: var(--WhiteTranslucent);
  }
  &_next {
    position: relative;
    right: 0;
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include ishoverSupported {
      &:hover {
        .icon {
          color: var(--White);
        }
      }
    }
  }
  &_descr {
    width: calc(100% - 90px);
    color: var(--BPDgreen2);
    position: relative;
    margin-left: 12px;
    flex: 1 0 auto;
    &_copy {
      margin: 0;
      color: var(--White);
      overflow: hidden;
    }
  }
  .icon {
    color: var(--BPDgray2);
    width: 14px;
    transition: color 0.25s;
  }
  @include ishoverSupported {
    &:hover {
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
.mod-isHidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}

@include tabletAndPhone() {
  .roomMenuBtn {
    height: 48px;
    margin: auto;
    &_descr {
      //margin: 0 44px 0 44px;
    }
  }
}
</style>
