<template>
  <div class="loadingComp">
    <img class="loadingComp_logo_company" src="@/assets/images/bpd_logo.png" />
    <div class="loadingio-spinner-spin-yyi27x1k86l">
      <div class="ldio-7mz9sxq6ns">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComp',
};
</script>

<style lang="scss" scoped>
.loadingComp {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Charcoal);

  &_logo_company {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 100px;
    height: 35px;
  }

  @keyframes ldio-7mz9sxq6ns {
    0% {
      opacity: 1;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.73, 1.73);
    }
    100% {
      opacity: 0;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
    }
  }
  .ldio-7mz9sxq6ns div > div {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
    animation: ldio-7mz9sxq6ns 1.1111111111111112s linear infinite;
  }
  .ldio-7mz9sxq6ns div:nth-child(1) > div {
    left: 128px;
    top: 98px;
    animation-delay: -0.9722222222222222s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 130px 100px;
  }
  .ldio-7mz9sxq6ns div:nth-child(2) > div {
    left: 119px;
    top: 119px;
    animation-delay: -0.8333333333333333s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 121px 121px;
  }
  .ldio-7mz9sxq6ns div:nth-child(3) > div {
    left: 98px;
    top: 128px;
    animation-delay: -0.6944444444444444s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 100px 130px;
  }
  .ldio-7mz9sxq6ns div:nth-child(4) > div {
    left: 77px;
    top: 119px;
    animation-delay: -0.5555555555555556s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 79px 121px;
  }
  .ldio-7mz9sxq6ns div:nth-child(5) > div {
    left: 68px;
    top: 98px;
    animation-delay: -0.41666666666666663s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 70px 100px;
  }
  .ldio-7mz9sxq6ns div:nth-child(6) > div {
    left: 77px;
    top: 77px;
    animation-delay: -0.2777777777777778s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 79px 79px;
  }
  .ldio-7mz9sxq6ns div:nth-child(7) > div {
    left: 98px;
    top: 68px;
    animation-delay: -0.1388888888888889s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 100px 70px;
  }
  .ldio-7mz9sxq6ns div:nth-child(8) > div {
    left: 119px;
    top: 77px;
    animation-delay: 0s;
  }
  .ldio-7mz9sxq6ns > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 121px 79px;
  }
  .loadingio-spinner-spin-yyi27x1k86l {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: var(--Charcoal);

    transform: scale(0.75);
  }
  .ldio-7mz9sxq6ns {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-7mz9sxq6ns div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
}
</style>
