<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <LoadingComp v-if="!dataIsReady" key="loadingComp" />
      <router-view v-if="dataIsReady" key="routerView" />
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { fetchData, fetchConfiguration, apiErrorReport } from '@/service/apiService';
import LoadingComp from '@/components/Loading_comp.vue';
import * as FeatureDetection from '@/service/featureDetection';
import { calcStoreTotalPrice, calculateAllProducts } from '@/service/priceCalculator/index.js';
import { createAutoSelections, createLookups, calculateAllCascades } from '@/utils/index.js';
import { transferAllPricesToBase } from './service/priceCalculator';
import { getEnableConfigurationSave, getEnvironment } from '@/utils/config';
import { getters } from './store';

export default {
  name: 'App',
  inject: ['store'],
  components: {
    LoadingComp,
  },
  data() {
    return {
      dataIsReady: false,
    };
  },
  async created() {
    this.store.config.environment = getEnvironment();
    this.store.config.enableConfigurationSave = getEnableConfigurationSave();
    this.store.featureDetection.isWebpSupported = await FeatureDetection.isWebpSupported();

    const urlBpdId = window.location.pathname.split('/')[1]; // fixme - go through router

    // Regex replacements for URL parts, can be used for individual properties
    // or complete projects.
    const redirects = [{ from: '405502040503-000', to: '405501840526-1' }];

    redirects.forEach(redirect => {
      if (window.location.pathname.includes(redirect.from)) {
        window.location.replace(
          window.location.pathname.replace(new RegExp(redirect.from, 'i'), redirect.to)
        );
      }
    });

    await fetchData(urlBpdId)
      .then(async _response => {
        await this.initStore(_response);
        await this.initStoreUserSelectionData(
          urlBpdId,
          this.store.apiData.apartment.configuration.isSoftConfigurator
        );
        this.dataIsReady = true;
        this.checkApartmentStatus();
      })
      .catch(_error => {
        apiErrorReport(_error);
        this.dataIsReady = true;
        this.$router.push({ name: 'NotFound' });
      });
  },
  methods: {
    async initStore(_response) {
      return new Promise(_resolve => {
        const removeProducts = window.location.pathname.split('/')[2] === 'filter'; // fixme - go through router

        this.store.apiData = {
          apartment: _response[0].data,
          project: _response[0].data.project,
          rooms: _response[1].data,
          products: removeProducts
            ? this.removeMissingProducts(_response[2].data)
            : _response[2].data,
        };

        // console.log(this.store);

        _resolve();
      });
    },

    async initStoreUserSelectionData(_urlBpdId, _isSoftConfigurator) {
      await fetchConfiguration(_urlBpdId)
        .then(_response => {
          // Auto selection always need to run because of base price calculations.
          this.store.userSelectionData.products = createAutoSelections(this.store.apiData.rooms);

          this.store.lookUps = createLookups(
            this.store.apiData.rooms,
            this.store.userSelectionData.products
          );

          calculateAllProducts();
          calculateAllCascades();
          calcStoreTotalPrice();

          transferAllPricesToBase();

          if (_response.data.products && !_isSoftConfigurator) {
            // User selection data from the backend.
            // Because products and prices might have changed, we can’t just use the data as is.
            // - use the base price from the standard product for each slot
            // TODO: - select standard product if user selected product is missing
            const userSelections = {};
            Object.entries(_response.data.products).forEach(([roomId, slots]) => {
              userSelections[roomId] = slots.map(slot => {
                const standardSelection = getters.getSelectionBySlotSlug(slot.slotSlug);
                if (standardSelection) {
                  return { ...slot, basePrice: standardSelection.basePrice };
                }

                console.error(`Can’t find standard selection for ${slot.slotSlug}`);
                return slot;
              });
            });

            this.store.userSelectionData.products = userSelections;

            // Run lookup creation and all price calculations again,
            // don‘t know if all of these are necessary though
            this.store.lookUps = createLookups(
              this.store.apiData.rooms,
              this.store.userSelectionData.products
            );

            calculateAllProducts();
            calculateAllCascades();
            calcStoreTotalPrice();
          }
        })
        .catch(_error => {
          apiErrorReport(_error);
        });
    },

    removeMissingProducts(_products) {
      const products = [];
      let output = '';

      _products.forEach(element => {
        const status = this.store.renderView.fileMap[element.catalogId] ? true : false;
        const description = element.subtitle !== undefined ? element.subtitle : '...';
        const available = status ? 'Ok' : '--';
        output += `${available}: ${element.type} ${element.catalogId} - ${element.title} - ${description}\n`;

        if (status) {
          products.push(element);
        }
      });

      console.log(output);

      return products;
    },

    checkApartmentStatus() {
      const configuration = this.store.apiData.apartment.configuration;

      if (configuration.hasExpired || configuration.isSubmitted) {
        this.$router.push({ name: 'Success' });
      }

      if (!configuration.hasStarted) {
        this.$router.push({ name: 'NotFound' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
