<template>
  <!-- CONFIRMATION -->
  <div>
    <div class="proposal" @click="onProposal">
      <h3 class="proposal_copy">Absenden</h3>
      <div class="proposal_icon" v-html="icons.arrowRight"></div>
    </div>

    <div v-if="confirmationIsActive" class="confirmation">
      <div class="confirmation" @click="onCloseConfirmation"></div>
      <div class="confirmation_card">
        <div
          class="confirmation_card_close"
          @click="onCloseConfirmation"
          v-html="icons.cross"
        ></div>
        <h6>Konfiguration übermitteln</h6>
        <h3>Sind Sie bereit?</h3>
        <p v-html="confirmationText"></p>
        <div class="confirmation_card_cta">
          <div class="abort" @click="onCloseConfirmation">
            <h3>Zurück</h3>
          </div>
          <div class="continue" @click="onConfirm">
            <h3>
              Absenden
            </h3>
            <div class="icon" v-html="icons.arrowRight"></div>

            <div v-if="isLoading" class="continue_loader">
              <div v-html="icons.spinner"></div>
            </div>
          </div>
        </div>
        <div class="confirmation_card_triangle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { saveConfiguration, apiErrorReport } from '@/service/apiService/index.js';

export default {
  name: 'Confirmation',
  inject: ['store'],
  data() {
    return {
      confirmationText: this.store.apiData.apartment.pages.summaryScreen.confirmationText,
      confirmationIsActive: false,
      isLoading: false,
      icons: {
        arrowRight: require('!html-loader!@/assets/svgs/arrowRight.svg'),
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
        spinner: require('!html-loader!@/assets/svgs/spinner.svg'),
      },
    };
  },
  methods: {
    onProposal() {
      this.confirmationIsActive = true;
      this.$nextTick(() => {
        gsap.to(document.querySelector('.confirmation'), { opacity: 1, duration: 0.5 });
      });
    },

    onCloseConfirmation() {
      return new Promise(_resolve => {
        gsap.to(document.querySelector('.confirmation'), {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            this.confirmationIsActive = false;
            _resolve();
          },
        });
      });
    },

    async onConfirm() {
      this.isLoading = true;
      await saveConfiguration(this.$route.params.bpdId, this.store.userSelectionData, true)
        .then(() => {
          console.log('[Confirmation_comp] saveConfiguration() succesfull');
          this.$router.push({ name: 'Success' });
        })
        .catch(_error => {
          apiErrorReport(_error);
          this.$router.push({ name: 'Success' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.proposal {
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--BPDgreen1);
  margin: 0;
  padding: 24px 20px 24px 20px;
  cursor: pointer;
  &_copy {
    color: var(--White);
  }
  &_icon {
    width: 14px;
    height: 24px;
    color: var(--BPDgreen3);
    margin-left: 10px;
  }
}

.confirmation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(84, 84, 84, 0.6);
  z-index: 1;
  opacity: 0;
  &_closeContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &_card {
    background: var(--White);
    position: absolute;
    right: 30px;
    bottom: 130px;
    width: 515px;
    padding: 60px 30px 30px 30px;
    border-radius: 5px;
    z-index: 2;
    h6 {
      letter-spacing: 0.5px;
      color: var(--BPDgray2);
      margin: 0;
    }
    h3 {
      color: var(--BPDgreen1);
      margin: 0;
    }
    p {
      color: var(--BPDgray1);
    }
    &_close {
      width: 20px;
      position: absolute;
      top: 15px;
      right: 15px;
      color: var(--BPDgray2);
      transition: color 0.25s;
      pointer-events: auto;
      cursor: pointer;
      @include ishoverSupported {
        &:hover {
          color: var(--Charcoal);
        }
      }
    }
    &_cta {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      border-radius: 5px;
      margin-top: 40px;
      cursor: pointer;
      .abort {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--Asphalt);
        border-radius: 5px 0 0 5px;

        h3 {
          color: var(--BPDgray2);
        }
      }
      .continue {
        position: relative;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--BPDgreen1);
        border-radius: 0 5px 5px 0;
        h3 {
          color: var(--White);
        }
        .icon {
          width: 14px;
          height: 24px;
          color: var(--BPDgreen3);
          margin: -4px 0 0 10px;
        }

        &_loader {
          padding: 14px 10px 14px 10px;
          width: 50%;
          height: 100%;
          position: absolute;
          background: rgba(255, 255, 255, 0.8);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          cursor: default;
        }
      }
    }
    &_triangle {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid var(--White);
      position: absolute;
      bottom: -12px;
      right: 20px;
    }
  }
}

@include tabletAndPhone() {
  .proposal {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }

  .confirmation_card {
    left: 0;
    right: 0;
    bottom: 110px;
    width: 90%;
    margin: 0 auto;
    padding: 60px 20px 20px 20px;
    &_cta {
      height: 60px;
    }
  }
}
</style>
