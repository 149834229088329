var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.room.id)?_c('div',{staticClass:"renderView",attrs:{"id":"renderView"}},[_c('canvas',{staticClass:"renderView_canvas",style:({
      left: _vm.x + 'px',
      top: _vm.y + 'px',
      width: _vm.backgroundWidth + 'px',
      height: _vm.backgroundHeight + 'px',
    }),attrs:{"id":"backgroundView"}}),_c('canvas',{staticClass:"renderView_canvas hidden",style:({
      left: _vm.x + 'px',
      top: _vm.y + 'px',
      width: _vm.renderWidth + 'px',
      height: _vm.renderHeight + 'px',
    }),attrs:{"id":"foregroundView"}}),_c('div',{staticClass:"renderView_hotspots",style:({
      left: _vm.x + 'px',
      top: _vm.y + 'px',
      width: _vm.renderWidth + 'px',
      height: _vm.renderHeight + 'px',
    }),attrs:{"id":"renderView_hotspots"}},_vm._l((_vm.hotspots),function(hotspot){return _c('a',{key:hotspot.label,staticClass:"renderView_hotspot",style:({
        left: hotspot.x + '%',
        top: hotspot.y + '%',
      }),attrs:{"title":hotspot.label},domProps:{"innerHTML":_vm._s(_vm.icons.hotspot)},on:{"click":function($event){return _vm.onHotspot(hotspot.slug)}}})}),0),_c('div',{class:{ renderView_progress: true, transition: _vm.loadingProgress > 0 },style:({ width: _vm.loadingProgress + '%' })})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }