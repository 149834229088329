<template>
  <section v-if="products.length > 0" class="checkbox">
    <div class="borderPadding">
      <div v-if="slotItem.showHeader" class="checkbox_header">
        <h4 v-if="slotItem.title !== null" class="checkbox_title">
          {{ slotItem.title }}
        </h4>
        <p v-if="slotItem.instructions !== null" class="checkbox_instructions">
          {{ slotItem.instructions }}
        </p>
      </div>

      <div
        v-for="(product, index) in products"
        :key="index"
        :class="['checkbox_item', { selected: selectedProductIndices[index] }]"
        @click="onProduct(index)"
      >
        <div class="icon_selected" v-html="icons.checkBox_selected"></div>
        <div class="icon_unselected" v-html="icons.checkBox_unselected"></div>
        <div class="content">
          <h5 class="title">
            {{ product.title }} <span v-if="slotItem.style !== 'checkboxes'">auswählen</span>
          </h5>
          <p v-if="product.subtitle !== null" class="subtitle">{{ product.subtitle }}</p>
          <div v-if="!hidePrices && !product.noPriceCalc" class="priceTage">
            <p>{{ formatAsCurrency(product.unitPrice) }}</p>
          </div>
        </div>
        <div class="dottedBorder"></div>
      </div>
    </div>

    <Hint v-if="slotItem.tip" :content="slotItem.tip" />

    <div v-if="hasSeperatorBar" class="separatorBar"></div>
  </section>
</template>

<script>
import { formatAsCurrency } from '../utils/index.js';
import { mutations, getters } from '@/store/index.js';

import Hint from '@/components/Hint_comp.vue';

export default {
  name: 'CheckboxComp',
  inject: ['store'],
  components: {
    Hint,
  },
  props: {
    slotPage: {
      type: Object,
      default() {
        return { children: [] };
      },
    },
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    slotIndex: {
      type: Number,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedProductIndices: {},
      icons: {
        checkBox_selected: require('!html-loader!@/assets/svgs/checkBox_selected.svg'),
        checkBox_unselected: require('!html-loader!@/assets/svgs/checkBox_unselected.svg'),
      },
    };
  },
  computed: {
    hasSeperatorBar() {
      return this.slotIndex < this.slotPage.children.length - 1;
    },
    hidePrices() {
      return this.store.apiData.project.hidePrices;
    },
  },
  watch: {
    selectedProductIndices(_newValue) {
      this.selectedProductIndices = _newValue;
    },
  },
  mounted() {
    const selection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);
    if (!selection) return;
    this.products.forEach((_product, _index) => {
      this.$set(this.selectedProductIndices, _index, selection.productIds.includes(_product.id));
    });
  },
  methods: {
    formatAsCurrency,

    onProduct(_index) {
      let selection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);
      const selectedProduct = this.products[_index];

      if (!selection) {
        alert('checkbox comp: selection is empty');
        selection = { productIds: [] };
      }

      const index = selection.productIds.indexOf(selectedProduct.id);

      if (index === -1) {
        selection.productIds.push(selectedProduct.id);
        selection.totalPrice = selection.totalPrice + selectedProduct.unitPrice;
        selection.slotPageSlug = this.slotPageSlug;

        this.selectedProductIndices[_index] = true;
      } else {
        selection.productIds.splice(index, 1);
        selection.totalPrice = selection.totalPrice - selectedProduct.unitPrice;
        selection.slotPageSlug = this.slotPageSlug;
        this.selectedProductIndices[_index] = false;
      }
      mutations.addSelection(this.$route.params.roomSlug, this.slotSlug, selection);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin-top: 10px;

  &_header {
    margin-bottom: 24px;
  }
  &_title {
    margin: 0;
  }
  &_instructions {
    color: var(--BPDgray1);
    margin-top: 8px;
    margin-bottom: 0;
  }

  &_item {
    position: relative;
    margin: 0 0 20px 0;
    .content {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      .title {
        font-size: 0.75em;
        font-weight: 600;
        color: var(--Asphalt);
        margin: 0;
        text-transform: uppercase;
      }

      .subtitle {
        font-size: 0.75em;
        font-weight: normal;
        color: var(--Asphalt);
        margin: 0;
      }

      .priceTage {
        margin-top: 10px;
        align-self: flex-start;
      }
    }

    .border {
      margin-top: 20px;
    }

    .icon_selected {
      display: none;
      width: 20px;
      height: 20px;
      float: left;
      margin-top: -6px;
      cursor: pointer;
    }

    .icon_unselected {
      display: block;
      width: 20px;
      height: 20px;
      float: left;
      margin-top: -6px;
      cursor: pointer;
    }

    &.selected {
      .icon_selected {
        display: block;
      }
      .icon_unselected {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: 0;
      .dottedBorder {
        display: none;
      }
    }
  }
}
</style>
