<template>
  <div class="slotProduct">
    <div class="border dottedBorder"></div>
    <div v-if="selectedProduct" class="slotProduct_inner">
      <img
        onload="this.style.opacity=1"
        class="slotProduct_img"
        :src="selectedProduct.general.thumb.url"
      />
      <div class="slotProduct_descr">
        <p>{{ selectedProduct.general.title }}</p>
        <p>{{ selectedProduct.general.subtitle }}</p>
        <p v-if="selectedProduct.variant">{{ selectedProduct.variant.title }}</p>
        <p v-if="selectedProduct.pattern">{{ selectedProduct.pattern.title }}</p>
      </div>
      <div class="slotProduct_price">
        <p v-if="!hidePrices" class="slotProduct_price_total">
          <span v-if="selectedProduct.general.noPriceCalc">—</span>
          <span v-else>{{ formatAsCurrency(selectedProduct.totalPrice) }}</span>
        </p>
        <p v-if="!hidePrices && slotPage.children[0].quantity > 1" class="slotProduct_price_unit">
          <span v-if="isVariableSlot">
            {{ formatAsCurrency(selectedProduct.general.minUnitPrice) }}
          </span>
          <span v-else>
            {{ formatAsCurrency(selectedProduct.general.unitPrice) }}
          </span>
          {{ formatAsMeasurementTerm(slotPage.children[0].unitOfMeasure) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatAsCurrency, formatAsMeasurementTerm } from '../utils/index.js';
import { store, getters } from '@/store/index.js';

export default {
  name: 'SlotProductList',
  props: {
    roomSlug: {
      type: String,
      required: true,
    },
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
    slotStyle: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedProduct: null,
    };
  },
  computed: {
    room() {
      return store.apiData.rooms.find(_room => _room.slug === this.roomSlug);
    },
    slotPage() {
      return this.room.children.find(_child => _child.slug === this.slotPageSlug);
    },
    isVariableSlot() {
      return this.slotItem.style === 'variable';
    },
    hidePrices() {
      return store.apiData.project.hidePrices;
    },
  },

  mounted() {
    this.selectedProduct = this.getSelectedProduct(this.roomSlug, this.slotPageSlug, this.slotSlug);

    // if (this.roomSlug === '228-wohnungsflur-abstellraum') {
    //   console.log(
    //     'here: ',
    //     this.getSelectedProduct(this.roomSlug, this.slotPageSlug, this.slotSlug)
    //   );
    // }
  },

  methods: {
    formatAsCurrency,
    formatAsMeasurementTerm,

    getProduct(_productId) {
      return store.apiData.products.find(_product => _product.id == _productId);
    },

    getSelectedProduct(_roomSlug, _slotPageSlug, _slotSlug) {
      let roomProducts = getters.getUserSelectionProductsByRoom(_roomSlug);

      let productFromStore = roomProducts.find(_roomProduct => _roomProduct.slotSlug === _slotSlug);

      let referencedProduct;

      //No product found in current room,
      //so just search in every room for that product
      // if (!productFromStore) {
      //   console.log('productFromStore not found with: ', _roomSlug, _slotPageSlug, _slotSlug);
      //   for (let i = 0; i < store.apiData.rooms.length; i++) {
      //     roomProducts = getters.getUserSelectionProductsByRoom(store.apiData.rooms[i].slug);
      //     referencedProduct = roomProducts.find(
      //       _roomProduct => _roomProduct.slotSlug === _slotSlug
      //     );
      //     if (referencedProduct) break;
      //   }
      // }

      if (productFromStore.referencedSlot) {
        for (let i = 0; i < store.apiData.rooms.length; i++) {
          roomProducts = getters.getUserSelectionProductsByRoom(store.apiData.rooms[i].slug);
          referencedProduct = roomProducts.find(
            _roomProduct => _roomProduct.slotSlug === productFromStore.referencedSlot
          );
          if (referencedProduct) break;
        }
      }

      // if (referencedProduct) {
      //   console.log(
      //     'its a referencedProduct:',
      //     productFromStore.productIds[0],
      //     referencedProduct.productIds[0]
      //   );
      // } else {
      //   console.log('its a productFromStore', _roomSlug, _slotPageSlug, _slotSlug);
      // }

      const selectedProduct = store.apiData.products
        .filter(_product => {
          if (referencedProduct) {
            return _product.id == referencedProduct.productIds[0];
          } else {
            return _product.id == productFromStore.productIds[0];
          }
        })
        .map(_product => {
          console.log(
            '[SlotProductList] getSelectedProduct() - productFromStore totalPrice:',
            productFromStore.totalPrice,
            ' basePrice:',
            productFromStore.basePrice
          );

          return {
            general: _product,
            variant: productFromStore.productIds[1]
              ? this.getProduct(productFromStore.productIds[1])
              : null,
            pattern: productFromStore.patternIds
              ? this.getProduct(productFromStore.patternIds[0])
              : null,
            totalPrice: productFromStore.totalPrice - productFromStore.basePrice,
          };
        });
      return selectedProduct[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.slotProduct {
  width: 100%;
  &_inner {
    display: flex;
    align-items: center;
  }
  &_img {
    width: 70px;
    height: 70px;
    background-color: var(--BPDgray3);
    flex: 0 0 auto;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.25s;
  }
  &_descr {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 20px 0 20px;
    flex: 1 1 auto;
    p {
      margin: 0;
      font-weight: 600;
      font-size: 0.875em;
      color: var(--Asphalt);
      &:last-child {
        font-weight: normal;
      }
    }
  }
  &_price {
    display: flex;
    flex-direction: column;
    text-align: right;
    flex: 1 0 auto;
    &_total {
      @include NunitoBlack;
      font-size: 1em;
      margin: 0;
      color: var(--BPDgray2);
    }
    &_unit {
      margin: 0;
      font-weight: 600;
      font-size: 0.875em;
      color: var(--BPDgray2);
    }
  }
}

.border {
  margin: 10px auto 10px auto;
}
</style>
