<template>
  <transition name="fade" mode="out-in">
    <div v-if="isActive" class="cookieBanner">
      <div class="cookieBanner_copy">
        <h3>Diese Website verwendet Cookies</h3>
        <p>
          Wir möchten neben technisch notwendigen Cookies auch solche verwenden, um unseren Traffic zu analysieren und um Ihnen eine bessere Benutzererfahrung zu bieten.<br />
          Weitere Hinweise finden Sie in unserer <a href="https://www.bpd-immobilienentwicklung.de/allgemeines/datenschutz/" target="_blank">Datenschutzerklärung</a>.
        </p>
      </div>
      <div class="cookieBanner_btns">
        <div class="cookieBanner_btn" @click="accept()">
          <p>akzeptieren</p>
        </div>
        <div class="cookieBanner_btn" @click="deny()">
          <p>ablehnen</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { bootstrap } from 'vue-gtag';

export default {
  name: 'CookieBannerComp',
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    accept() {
      this.isActive = !this.isActive;
      this.$cookies.set('cookieConsent', true);

      bootstrap().then(() => {
        this.$gtag.pageview({
          page_title: this.$route.name,
          page_path: this.$route.path,
        });
      });
    },
    deny() {
      this.isActive = !this.isActive;
      this.$cookies.set('cookieConsent', false);
    },
  },
};
</script>

<style lang="scss">
.cookieBanner {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: var(--White);
  z-index: 6;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &_copy {
    display: flex;
    flex-direction: column;
    h3 {
      margin: 0 0 15px 0;
    }
    p {
      margin: 0 0 15px 0;
    }
  }
  &_btns {
    display: flex;
  }
  &_btn {
    border-radius: 5px;
    padding: 0 15px 0 15px;
    margin-left: 15px;
    width: 10em;
    align-self: center;
    text-transform: uppercase;
    text-align: center;
    background: var(--Asphalt);
    p {
      color: var(--White);
      cursor: pointer;
      margin: 15px 0 15px 0;
      @include NunitoBold;
    }
  }
}
</style>
