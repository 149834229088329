import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:bpdId/:switches?',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home_view" */ '@/views/Home_view.vue'),
      props: true,
    },
    {
      path: '/:bpdId/planner/:roomSlug',
      name: 'Planner',
      component: () => import(/* webpackChunkName: "planner_view" */ '@/views/Planner_view.vue'),
      props: true,
      children: [
        {
          path: ':slotPageSlug',
          name: 'Category',
          component: () =>
            import(/* webpackChunkName: "category_view" */ '@/views/Category_view.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/:bpdId/summary/:roomSlug',
      name: 'Summary',
      component: () => import(/* webpackChunkName: "summary_view" */ '@/views/Summary_view.vue'),
      props: true,
      children: [
        {
          path: '/:bpdId/success/',
          name: 'Success',
          component: () =>
            import(/* webpackChunkName: "success_view" */ '@/views/Success_view.vue'),
        },
      ],
    },
    {
      path: '/notFound',
      alias: '*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "notFound_view" */ '@/views/NotFound_view.vue'),
    },
  ],
});

export default router;
