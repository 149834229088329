<template>
  <div class="category">
    <div class="category_back" @click="onBack">
      <div class="category_back_icon" v-html="icons.arrowLeft"></div>
      <div class="category_back_descr">
        <h5 class="category_back_descr_copy">zurück</h5>
      </div>
    </div>

    <div class="category_pullup" @click="onpointerdown">
      <div class="category_pullup_icon" v-html="icons.arrowLeft"></div>
    </div>

    <div class="category_title">
      <h3 class="category_title_copy">{{ slotPage.title }}</h3>
      <div class="dottedBorder"></div>
    </div>

    <div
      v-for="(slotItem, slotIndex) in slotPage.children"
      :key="slotItem.title"
      class="category_list"
    >
      <div class="category_list_item">
        <Products
          v-if="slotItem.style === 'default'"
          :slot-page="slotPage"
          :slot-page-slug="slotPageSlug"
          :slot-item="slotItem"
          :slot-index="slotIndex"
          :slot-slug="slotItem.slug"
          :products="getSlotProducts(slotIndex)"
          @on-conflicting-products="onConflictingProducts"
        />

        <ProductsVariable
          v-if="slotItem.style === 'variable'"
          :slot-page="slotPage"
          :slot-page-slug="slotPageSlug"
          :slot-item="slotItem"
          :slot-index="slotIndex"
          :slot-slug="slotItem.slug"
          :products="getSlotProducts(slotIndex)"
          @on-conflicting-products="onConflictingProducts"
        />

        <Checkbox
          v-if="slotItem.style === 'checkboxes'"
          :slot-page="slotPage"
          :slot-page-slug="slotPageSlug"
          :slot-item="slotItem"
          :slot-index="slotIndex"
          :slot-slug="slotItem.slug"
          :products="getSlotProducts(slotIndex)"
        />

        <Packages
          v-if="slotItem.style === 'packages'"
          :slot-page-slug="slotPageSlug"
          :slot-item="slotItem"
          :slot-index="slotIndex"
          :slot-slug="slotItem.slug"
          :packages="getSlotProducts(slotIndex)"
        />

        <ProductsSwitch
          v-if="slotItem.type === 'slotGroups'"
          :slot-groups="slotItem"
          :slot-page-slug="slotPageSlug"
          @on-conflicting-products="onConflictingProducts"
          @on-conflicting-room-flooring="onConflictingRoomFlooring"
        />
      </div>
    </div>
    <Hint v-if="slotPage.tip" :content="slotPage.tip" />
  </div>
</template>

<script>
/* eslint-disable no-console */
import Hint from '@/components/Hint_comp.vue';
import Checkbox from '@/components/Checkbox_comp.vue';
import Packages from '@/components/Packages_comp.vue';
import Products from '@/components/Products_comp.vue';
import ProductsSwitch from '@/components/ProductsSwitch_comp.vue';
import ProductsVariable from '@/components/ProductsVariable_comp.vue';
import { detectMobileViewPort } from '../utils/index.js';

export default {
  name: 'CategoryView',
  inject: ['store'],
  components: {
    Hint,
    Checkbox,
    Packages,
    Products,
    ProductsSwitch,
    ProductsVariable,
  },
  props: {
    slotPageSlug: {
      type: String,
      required: true,
      default: 'undefined',
    },
  },
  data() {
    return {
      products: null,
      isMobileCategoryOpen: false,
      isInMobileViewPort: false,
      icons: {
        arrowLeft: require('!html-loader!@/assets/svgs/arrowLeft.svg'),
      },
    };
  },

  computed: {
    room() {
      return this.store.apiData.rooms.find(_room => _room.slug === this.roomSlug);
    },

    roomSlug() {
      return this.$route.params.roomSlug;
    },

    slotPage() {
      return this.room.children.find(_child => _child.slug === this.slotPageSlug);
    },
  },
  beforeDestroy() {
    if (this.isInMobileViewPort) {
      this.closeMobileCategory();
      this.$emit('on-reset-scroll-bar');
    }
  },
  updated() {
    this.scrollBackToTop();
    if (this.isInMobileViewPort) {
      this.closeMobileCategory();
    }
  },
  mounted() {
    this.scrollBackToTop();
    this.isInMobileViewPort = detectMobileViewPort(window.innerWidth);
  },

  methods: {
    onWindowResize(_windowInnerWidth) {
      this.isInMobileViewPort = detectMobileViewPort(_windowInnerWidth);
      if (this.isInMobileViewPort) {
        this.closeMobileCategory();
      } else if (!this.isInMobileViewPort) {
        this.$emit('on-show-dekstopConfiStyle');
      }
    },

    onpointerdown(_event) {
      _event.preventDefault();
      if (this.isMobileCategoryOpen) {
        this.closeMobileCategory();
      } else {
        this.openMobileCategory();
      }
    },

    closeMobileCategory() {
      document.querySelector('.category_pullup_icon').classList.remove('mod-isOpenSVGStyle');
      this.isMobileCategoryOpen = false;
      this.$emit('on-pull-down');
    },

    openMobileCategory() {
      document.querySelector('.category_pullup_icon').classList.add('mod-isOpenSVGStyle');
      this.isMobileCategoryOpen = true;
      this.$emit('on-pull-up');
    },

    scrollBackToTop() {
      document.querySelector('.configurator').scrollTop = 0;
      document.querySelector('.category').scrollTop = 0;
    },

    getSlotProducts(_slotIndex) {
      const products = [];
      if (this.slotPage.children[_slotIndex].productIds === undefined) return products;

      this.slotPage.children[_slotIndex].productIds.forEach(_id => {
        const product = this.store.apiData.products.find(_product => _product.id == _id); // change to === as soon as backend sends all strings
        if (product !== undefined) products.push(product);
      });

      return products;
    },

    highlightActiveItem(_node) {
      const nodes = document.querySelectorAll('.subcategory_list');
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].classList.remove('active');
      }
      _node.classList.add('active');
    },

    onListItem(_event, _item) {
      if (_event.target.parentNode.classList.contains('active')) return;
      this.products = _item;
      this.highlightActiveItem(_event.target.parentNode);
    },

    onConflictingRoomFlooring(_conflictingRoomData, _confirmCB) {
      this.$emit('on-conflicting-room-flooring', _conflictingRoomData, _confirmCB);
    },

    onConflictingProducts(_conflictingProducts, _addSelectedProductCB, _addProductCB) {
      this.$emit(
        'on-conflicting-products',
        _conflictingProducts,
        _addSelectedProductCB,
        _addProductCB
      );
    },

    onBack() {
      this.$router.replace('./');
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ffffff 12%,
    var(--BetonLight) 26%,
    var(--BetonLight) 85%,
    #ffffff
  );
  border-radius: 0 0 5px 5px;
  padding-bottom: 40px;
  @include styleScrollBar();

  &_back {
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 3;
    background: var(--White);
    box-shadow: 0 2px 4px 0 rgba(84, 84, 84, 0.1);
    display: flex;
    align-items: center;
    padding: 20px 0 20px 20px;

    &_descr {
      color: var(--White);
      cursor: pointer;
      &_copy {
        font-size: 1.125em;
        color: var(--BPDgreen1);
        margin: 0 0 0 10px;
      }
    }
    &_icon {
      color: var(--BPDgreen1);
      width: 14px;
      cursor: pointer;
    }
  }

  &_pullup {
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 3;
    background: var(--White);
    box-shadow: 0 2px 4px 0 rgba(84, 84, 84, 0.1);
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
    touch-action: pan-x;
    display: none;
    &_icon {
      color: var(--BPDgreen1);
      width: 14px;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%) rotateZ(90deg);
    }
  }

  &_title {
    padding: 20px 30px 0px 30px;
    &_copy {
      margin: 0;
    }
    .dottedBorder {
      margin: 10px auto 0 auto;
    }
  }
}

.mod-isOpenSVGStyle {
  transform: translateY(-50%) rotateZ(270deg);
}

@include tabletAndPhone() {
  .category {
    top: 60px;
    bottom: 0;
    height: auto;
    min-height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px 5px 0 0;
    background-color: #ffffff;

    &_list {
      width: 100%;
      max-width: 480px;
      margin: 60px auto 0 auto;
    }

    &_list + &_list {
      margin-top: 0;
    }
  }

  .category_back {
    display: none;
  }
  .category_title {
    display: none;
  }

  .category_pullup {
    display: block;
  }
}
</style>
