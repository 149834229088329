<template>
  <div v-if="conflictingRoomData" :class="['conflictingRoomFlooring', { active: isActive }]">
    <div class="outsideArea" @click="close"></div>
    <div class="content">
      <h4 class="headline ">Änderungen übernehmen?</h4>
      <p class="instructions ">
        Sie haben im {{ slotTitle }} bereits einen Bodenbelag gewählt. Wenn Sie auf Übernehmen
        klicken, werden Ihre Änderungen hier auch für {{ slotTitle }} übernommen.
      </p>

      <div class="buttons">
        <div class="buttons_style" @click="onApplyChanges">
          <div class="buttons_style_icon" v-html="icons.checkmark"></div>
          <h5 class="buttons_style_copy">Übernehmen</h5>
        </div>
        <div class="buttons_style" @click="close">
          <div class="buttons_style_icon closeIcon" v-html="icons.cross"></div>
          <h5 class="buttons_style_copy">Abbrechen</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'ConflictingRoomFlooringComp',
  inject: ['store'],

  data() {
    return {
      rooms: this.store.apiData.rooms,
      conflictingRoomData: null,
      confirmCB: null,
      isActive: true,
      icons: {
        checkmark: require('!html-loader!@/assets/svgs/checkmark.svg'),
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
      },
    };
  },
  computed: {
    // roomTitle() {
    //   const room = this.rooms.find(_room => _room.slug === this.conflictingRoomData.roomSlug);
    //   return room.title;
    // },
    slotTitle() {
      console.log('[ConflictingRoomFlloring] slotTitle', this.conflictingRoomData.slotPageSlugs);
      let string = '';
      this.conflictingRoomData.slotPageSlugs.forEach((_slotPageSlug, _index) => {
        this.rooms.forEach(_room => {
          _room.children.find(_child => {
            console.log(_child.slug);
            if (_child.slug === _slotPageSlug) {
              if (_index > 0) {
                string += '/' + _child.title;
              } else {
                string += _child.title;
              }
            }
          });
        });
      });
      return string;
    },
  },
  methods: {
    onApplyChanges() {
      this.confirmCB();
      this.close();
    },

    open(_conflictingRoomData, _confirmCB) {
      this.conflictingRoomData = _conflictingRoomData;
      this.confirmCB = _confirmCB;
      this.isActive = true;
    },

    close() {
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.conflictingRoomFlooring {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  pointer-events: none;
  .outsideArea {
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }
  .content {
    position: absolute;
    flex-direction: column;
    padding: 0 30px 30px 30px;
    background: var(--White);
    top: 80px;
    right: 20px;
    width: calc(100% - 60px);
    width: 20%;
    min-width: 400px;
    height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 0 5px;
    pointer-events: none;
    // @include hideScrollBar();
    .headline {
      margin-bottom: 0;
    }
    .instructions {
      color: var(--BPDgray1);
      margin-bottom: 0;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      pointer-events: auto;
      margin-top: 20px;
      &_style {
        display: flex;
        align-items: center;
        height: 64px;
        background: var(--White);
        padding: 14px 10px 14px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border: 2px solid transparent;
        transition: all 0.25s;
        box-shadow: 0 2px 20px 0 rgba(84, 84, 84, 0.25);
        cursor: pointer;
        &_icon {
          width: 24px;
          color: var(--BPDgreen1);
          margin-right: 10px;
          object-fit: none;
        }
        .closeIcon {
          color: var(--BPDgray2);
        }
        &_copy {
          margin: 3px 0 0 0;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        @include ishoverSupported {
          &:hover {
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
            transform: translateX(10px);
          }
        }
      }
    }
  }
}

.active {
  display: flex;
  z-index: 6;
  pointer-events: auto;
}

@include tabletAndPhone() {
  .conflictingRoomFlooring {
    .outsideArea {
      background-color: rgba(84, 84, 84, 0.6);
    }
    .content {
      left: 0;
      right: 0;
      top: 50%;
      width: 90%;
      min-width: auto;
      max-width: 500px;
      height: auto;
      margin: 0 auto;
      transform: translateY(-50%);
      border-radius: 5px;
    }
  }
}
</style>
