<template>
  <div class="variant">
    <div class="borderPadding">
      <div class="dottedBorder"></div>
      <p class="variant_headline">Wählen Sie ein Fliesenformat</p>
      <ul class="variant_list">
        <li
          v-for="(variant, index) in getVariants()"
          :key="index"
          :class="['variant_list_item', { selected: selectedVariantIndex == index }]"
          @click="onVariant(index, variant)"
        >
          <div class="icon" v-html="icons.checkCircle"></div>
          <img v-if="variant.image" :src="variant.image.url" onload="this.style.opacity=1" />
          <p>{{ variant.title }}</p>
          <p v-if="!hidePrices">
            {{ formatAsCurrency(variant.unitPrice) }}
            {{ formatAsMeasurementTerm(slotItem.unitOfMeasure) }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { store, mutations, getters } from '@/store/index.js';
import { formatAsCurrency, formatAsMeasurementTerm } from '../utils/index.js';
import { getPriceFromProduct } from '../service/priceCalculator/index.js';

export default {
  name: 'ProductVariantsComp',
  props: {
    slotPageSlug: {
      type: String,
      required: true,
    },
    slotItem: {
      type: Object,
      required: true,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
    slotSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedVariantIndex: 0,
      icons: {
        checkCircle: require('!html-loader!@/assets/svgs/checkCircle.svg'),
      },
    };
  },
  computed: {
    hidePrices() {
      return store.apiData.project.hidePrices;
    },
  },
  watch: {
    selectedProduct() {
      this.highlightSelectedVariant();
    },

    selectedVariant() {
      this.highlightSelectedVariant();
    },
  },
  mounted() {
    this.highlightSelectedVariant();
  },
  methods: {
    formatAsCurrency,
    formatAsMeasurementTerm,

    highlightSelectedVariant() {
      const selectedProductVariants = this.getVariants();
      const selection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);
      const selectedVariantId = selection.productIds[1]
        ? selection.productIds[1]
        : selectedProductVariants[0].id;

      this.selectedVariantIndex = selectedProductVariants.findIndex(
        _variant => _variant.id == selectedVariantId
      );
    },

    getVariants() {
      const array = [];
      this.selectedProduct.variantIds.forEach(_variantId => {
        store.apiData.products.find(_product => {
          if (_product.id == _variantId) {
            array.push(_product);
          }
        });
      });
      return array;
    },

    onVariant(_index, _variant) {
      const currentSelection = getters.getSelection(this.$route.params.roomSlug, this.slotSlug);

      const defaultPattern = store.apiData.products.find(_element => {
        return _element.id == _variant.patternIds[0];
      });
      console.log('currentSelection: ', currentSelection);
      const newSelection = {
        slotSlug: currentSelection.slotSlug,
        slotPageSlug: this.slotPageSlug,
        productIds: [currentSelection.productIds[0], _variant.id],
        patternIds: defaultPattern ? [defaultPattern.id] : [],
        //totalPrice: _variant.unitPrice,
        //totalPrice: _variant.unitPrice * this.slotItem.quantity,
        totalPrice: getPriceFromProduct(_variant, this.slotItem),
      };
      mutations.addSelection(this.$route.params.roomSlug, this.slotSlug, newSelection);

      this.selectedVariantIndex = _index;
      this.$emit('on-variant-selected', _variant);
    },
  },
};
</script>

<style lang="scss" scoped>
.variant {
  .borderPadding {
    padding-top: 0;
  }
  &_headline {
    font-weight: normal;
    color: var(--BPDgray1);
    margin: 14px 0 0 0;
  }

  &_list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-radius: 5px;
    background: var(--White);
    margin-top: 14px;

    p {
      color: var(--Asphalt);
      font-weight: 600;
      font-size: 0.875em;
      margin-bottom: 0;

      &:last-child {
        font-weight: normal;
        margin-top: 0;
      }
    }

    &_item {
      background: var(--White);
      position: relative;
      box-shadow: 0 0 0 2px none;
      transition: box-shadow 0.25s;
      margin: 18px 20px;
      cursor: pointer;
      .icon {
        position: absolute;
        top: -10px;
        right: -4px;
        width: 18px;
        z-index: 1;
        display: none;
        pointer-events: none;
      }
      img {
        //width: 90px;
        height: 45px;
        pointer-events: none;
        display: flex;
        margin: 0 auto;
        opacity: 0;
        transition: opacity 0.25s;
      }
      &.selected {
        pointer-events: none;
        .icon {
          display: block;
        }
      }
    }
  }
}

.selected {
  .radioBtn_cirlce {
    background-color: var(--BPDgreen2);
    &_inner {
      display: block;
    }
  }
}
</style>
